import { ReactElement } from 'react'
import { TableInstance } from 'react-table'
import { ButtonType } from 'antd/lib/button'
import { Button } from 'antd'
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons'

import style from './TableToolbar.module.css'

interface TableToolbarButtonProps<D extends Record<string, unknown>> {
  instance: TableInstance<D>,
  onClick: (instance: TableInstance<D>) => void,
  icon?: React.ReactNode,
  danger?: boolean,
  type?: ButtonType,
  label: string,
  enabled?: (instance: TableInstance<D>) => boolean,
}
function TableToolbarButton<D extends Record<string, unknown>>({
  icon,
  type,
  danger,
  onClick,
  instance,
  label,
  enabled = () => true,
}: TableToolbarButtonProps<D>): ReactElement {
  return (
    <Button
      icon={icon}
      type={type}
      danger={danger}
      onClick={() => onClick(instance)}
      disabled={!enabled(instance)}
    >
      {label}
    </Button>
  )
}

export interface TableToolbarProps<D extends Record<string, unknown>> {
  instance: TableInstance<D>,
  onAdd?: (instance: TableInstance<D>) => void,
  onEditSelected?: (instance: TableInstance<D>) => void,
  onDeleteSelected?: (instance: TableInstance<D>) => void,
  onDownload?: (instance: TableInstance<D>) => void,
  downloadLabel?: string,
  addLabel?: string,
  editLabel?: string,
  deleteLabel?: string,
  extra?: (instance: TableInstance<D>) => ReactElement,
}
export function TableToolbar<D extends Record<string, unknown>>({
  instance,
  onAdd,
  onEditSelected,
  onDeleteSelected,
  onDownload,
  downloadLabel = 'Download Data',
  addLabel = 'Add',
  editLabel = 'Edit Selected',
  deleteLabel = 'Delete Selected',
  extra,
}: TableToolbarProps<D>): ReactElement | null {
  if(!(onDownload || onAdd || onEditSelected || onDeleteSelected || extra)) {
    return null
  }

  return (
    <div className={style.tableToolbar}>
      <div>
        {onDownload &&
          <TableToolbarButton<D>
            instance={instance}
            onClick={onDownload}
            icon={<DownloadOutlined />}
            label={downloadLabel}
          />
        }
      </div>
      <div>
        {onAdd && (
          <TableToolbarButton<D>
            instance={instance}
            onClick={onAdd}
            icon={<PlusOutlined />}
            type="primary"
            label={addLabel}
            enabled={({ state }) => !state.selectedRowIds || Object.keys(state.selectedRowIds).length === 0}
          />
        )}
        {onEditSelected && (
          <TableToolbarButton<D>
            instance={instance}
            onClick={onEditSelected}
            icon={<EditOutlined />}
            label={editLabel}
            enabled={({ state }) => state.selectedRowIds && Object.keys(state.selectedRowIds).length === 1}
          />
        )}
        {onDeleteSelected && (
          <TableToolbarButton<D>
            instance={instance}
            onClick={onDeleteSelected}
            icon={<DeleteOutlined />}
            type="ghost"
            danger
            label={deleteLabel}
            enabled={({ state }) => state.selectedRowIds && Object.keys(state.selectedRowIds).length > 0}
          />
        )}
        {extra && extra(instance)}
      </div>
    </div>
  )
}
