import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Card, Form, Spin } from 'antd'

import { Page } from '../../common/Page'

import { useAccount } from '../../../context'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { fetchUsers } from '../../../redux/actions'

export function AccountUserPage() {
  const { userId } = useParams<'userId'>()
  if(!userId) {
    throw new Error('AccountUserPage must be rendered within a route path containing ":userId"')
  }

  const user = useAppSelector(state => state.users.byId[userId])

  const { accountId } = useAccount()
  const dispatch = useAppDispatch()
  const usersLoadingStatus = useAppSelector(state => state.users.loadingStatus)
  useEffect(
    () => {
      if(usersLoadingStatus === 'NotLoaded') {
        dispatch(fetchUsers(accountId))
      }
    },
    [dispatch, usersLoadingStatus, accountId]
  )

  if(usersLoadingStatus !== 'Loaded') {
    return <Spin />
  }

  return (
    <Page title="User Information">
      <Card>
        <Form>
          <Form.Item label="Email">
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </Form.Item>
          <Form.Item label="Phone Number">
            {user.phone || '-'}
          </Form.Item>
          <Form.Item label="Short Name">
            {user.shortName || '-'}
          </Form.Item>
          <Form.Item label="Initials">
            {user.initials || '-'}
          </Form.Item>
          <h6 style={{ marginTop: '20px' }}>User ID:</h6>
          <h6>{user.id}</h6>
        </Form>
      </Card>
    </Page>
  )
}
