import { ReactNode, useEffect } from 'react'
import _keyBy from 'lodash/keyBy'
import { Spin } from 'antd'

import { ROOT_ACCOUNT_ID } from '@vms/vmspro3-core/dist/systemConsts'
import { Account, Product, UserAccountRecord_App } from '@vms/vmspro3-core/dist/types'

import Eula from '../components/App/Eula'

import config from '../config.json'
import Server from '../server/VMSProServerAdapter'
import pubSubService from '../services/pubSubService'
import { createTypedContext } from '../utils/createTypedContext'
import { useAppDispatch, useAppSelector } from '../redux'
import { useModalState } from '../hooks/useModalState'
import { resetAccountState, setAccount, setAccountUser } from '../redux/actions'
import { getQueueFromLocalStorage } from '../redux/middleware/isomorphicReduxMiddleware'
import { UnprocessedActionDispositionModal } from '../components/App/UnprocessedActionDispositionModal'
import { useUserAccounts } from './UserAccountsContext'

const eulasById = _keyBy(config.instance?.eulas, 'id')
const productsById = _keyBy(config.instance.products as Product[], 'id')

interface AccountContextValue {
  accountId: string,
  accountUserRecord: UserAccountRecord_App,
  account: Account,
  accountCommonId: string,
}
const [
  useAccount,
  AccountContextProvider,
] = createTypedContext<AccountContextValue>('AccountContext')

interface AccountProviderProps {
  accountCommonId: string,
  authUserId: string,
  children: ReactNode,
}
export function AccountProvider({ accountCommonId, authUserId, children }: AccountProviderProps) {
  const { userAccounts } = useUserAccounts()

  const dispatch = useAppDispatch()
  useEffect(
    () => {
      const userAccountData = userAccounts.find(data => data.account.commonId === accountCommonId)
      if(userAccountData) {
        Server.auth.configureAPIHeaders({
          accountId: userAccountData.account.id,
          userId: authUserId,
        })
        dispatch(setAccount(userAccountData.account))
        dispatch(setAccountUser(userAccountData.userRecord))
      }
    },
    [dispatch, userAccounts, accountCommonId, authUserId]
  )

  useEffect(
    () => {
      const cleanupAccountData = () => {
        dispatch(resetAccountState())
        Server.auth.configureAPIHeaders({
          accountId: ROOT_ACCOUNT_ID,
          userId: authUserId,
        })
      }
      return cleanupAccountData
    },
    [dispatch, accountCommonId, authUserId]
  )

  const accountUserRecord = useAppSelector(state => state.user.accountUser)
  const account = useAppSelector(state => state.account.account)

  const { modal, showModal, hideModal } = useModalState()
  useEffect(
    () => {
      // PubSub can only be configured after account headers are configured
      // (we know they are configred if the account is set)
      if(account) {
        pubSubService.configure()
          .then(() => {
            // check for any actions that might have been pending when the application was last closed & give
            // the user an opportunity to recover them
            const queuedActions = getQueueFromLocalStorage() || []
            if(queuedActions.length) {
              showModal(
                <UnprocessedActionDispositionModal
                  hideModal={hideModal}
                  queuedActions={queuedActions}
                />
              )
            }
          })
      }
    },
    [hideModal, showModal, account]
  )

  if(useAppSelector(state => state.account.accountLoadingStatus !== 'Loaded')) {
    return <Spin />
  }

  if(!accountUserRecord || !account) {
    // TODO: better 404 experience
    return <h2>Account data not found</h2>
  }

  if(!account.productId) {
    throw new Error('active account must contain productId')
  }

  const eula = eulasById[productsById[account.productId].eulaId]
  const authUserHasAgreedToLatestEula = accountUserRecord.eulas[eula.id] === eula.version

  if(!authUserHasAgreedToLatestEula) {
    return <Eula account={account} />
  }

  return (
    <AccountContextProvider
      value={{
        account,
        accountUserRecord,
        accountId: account.id,
        accountCommonId,
      }}
    >
      {modal}
      {children}
    </AccountContextProvider>
  )
}
export { useAccount }
