import { ReactElement, useCallback, useMemo } from 'react'
import { Button, Modal, Space } from 'antd'

import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { Criteria as CriteriaClass } from '@vms/vmspro3-core/dist/nextgen/criteria'
import { deleteCriterion } from '@vms/vmspro3-core/dist/actions/decision'

import { CriterionLabel } from './CriterionLabel'
import { CriterionDescription } from './CriterionDescription'
import { CriterionModal } from './CriterionModal'
import { RatingScaleEditor } from './RatingScaleEditor'
import { Tree } from '../../../common/Tree'

import style from './Criteria.module.css'
import { useAppDispatch } from '../../../../redux'
import { useCriteria as useCriteriaData } from '../../../../redux/hooks'
import { useQuerystringValue } from '../../../../hooks/useQuerystringValue'
import { useModalState } from '../../../../hooks/useModalState'

function renderCriterionLabel(criterion: Criterion) {
  return (
    <CriterionLabel
      type={criterion.type}
      abbrev={criterion.abbrev}
      color={criterion.color}
      name={criterion.name}
    />
  )
}

export function Criteria({ decisionId }: { decisionId: string }): ReactElement {
  const criteriaData = useCriteriaData(decisionId)
  const criteria = useMemo(
    () => new CriteriaClass(criteriaData),
    [criteriaData]
  )

  const [selectedCriterionId, setSelectedCriterionId] = useQuerystringValue('criterionId', criteria.perfRoot.id)
  const selectedCriterion = criteria.byId[selectedCriterionId]

  const { modal, showModal, hideModal } = useModalState()
  const onAddChild = useCallback(
    () => showModal(
      <CriterionModal
        decisionId={decisionId}
        parentId={selectedCriterionId}
        hideModal={hideModal}
      />
    ),
    [decisionId, selectedCriterionId, showModal, hideModal]
  )
  const onEditCriterion = useCallback(
    () => showModal(
      <CriterionModal
        decisionId={decisionId}
        criterion={selectedCriterion}
        hideModal={hideModal}
      />
    ),
    [decisionId, selectedCriterion, showModal, hideModal]
  )

  const dispatch = useAppDispatch()
  const onDeleteCriterion = useCallback(
    () => {
      const hasDescendants = selectedCriterion.descendants.length > 0

      Modal.confirm({
        title: `Deleting criterion "${selectedCriterion.name}"`,
        content: (
          <>
            <p>
              This action will delete criterion "{selectedCriterion.name}"
              {hasDescendants ? ' and all of its descendants:' : '.'}
            </p>
            {hasDescendants &&
              <>
                <Tree<Criterion>
                  rootNode={selectedCriterion}
                  renderLabel={renderCriterionLabel}
                />
                <br />
              </>
            }
            <p>Are you sure you want to proceed?</p>
          </>
        ),
        onOk: () => {
          const descendantIds = hasDescendants
            ? selectedCriterion.descendants.map(d => d.id)
            : undefined
          const deleteCriterionAction = deleteCriterion(decisionId, selectedCriterion.id, descendantIds)
          dispatch(deleteCriterionAction)
          setSelectedCriterionId()
        },
      })
    },
    [dispatch, decisionId, setSelectedCriterionId, selectedCriterion]
  )

  const disableAdd = !selectedCriterion ||
    (selectedCriterion.type !== 'Performance' && selectedCriterion.type !== 'Rated')
  const disableDelete = selectedCriterion?.type !== 'Rated'
  const disableEdit = selectedCriterion?.type === 'Intrinsic'
  const showRatingScale = selectedCriterion &&
    !['Value', 'IntrinsicRoot', 'Intrinsic'].includes(selectedCriterion.type)


  return (
    <div className={style.criteria}>
      {modal}
      <div>
        <Tree<Criterion>
          rootNode={criteria.root}
          renderLabel={renderCriterionLabel}
          onSelectItem={setSelectedCriterionId}
          selectedItemId={selectedCriterionId}
        />
        <Space className={style.criteriaControls}>
          <Button
            danger
            disabled={disableDelete}
            onClick={onDeleteCriterion}
          >
            Delete
          </Button>
          <Button
            type="primary"
            disabled={disableAdd}
            onClick={onAddChild}
          >
            Add Child
          </Button>
        </Space>
      </div>
      <div className={style.selectedCriterion}>
        {selectedCriterion && (
          <>
            <div className={style.selectedCriterionDescription}>
              <CriterionDescription
                name={selectedCriterion.name}
                description={selectedCriterion.description}
              />
              <div className={style.criteriaControls}>
                <Button onClick={onEditCriterion} disabled={disableEdit}>Edit</Button>
              </div>
            </div>
            {showRatingScale &&
              <RatingScaleEditor
                key={selectedCriterionId}
                decisionId={decisionId}
                criterion={selectedCriterion}
              />
            }
          </>
        )}
      </div>
    </div>
  )
}
