import { createSelector } from 'reselect'
import _sumBy from 'lodash/sumBy'
import _matches from 'lodash/matches'
import _keyBy from 'lodash/keyBy'

import { splitAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { validateRatings } from '@vms/vmspro3-core/dist/utils/ratings'
import { createTree, getNodeLabels, getLeafNodeData } from '@vms/vmspro3-core/dist/utils/createTree'
import {
  Criterion,
  Decision,
  DecisionFolder,
  ParticipationSessionType,
  Rating,
  RatingNotes,
} from '@vms/vmspro3-core/dist/types'

import { Loadable, SelectOption } from '../../types'
import { RootState } from '../store'
import { getLeafNodeSelectOptions } from '../../utils/treeSelectOptions'

function extractLoadable<T>(loadable: Loadable<T>): T | undefined {
  if(loadable.status === 'Success') {
    return loadable.data
  }
}

export const selectDecisionFoldersState = (state: RootState) => state.decisionFolders
export const selectDecisionsState = (state: RootState) => state.decisions

export const getSelectDecisionFolderLoading = (decisionFolderId: string) => createSelector(
  selectDecisionFoldersState,
  state => state[decisionFolderId]?.status !== 'Success'
)
export const getSelectLoadableDecisionFolder = (decisionFolderId: string) => createSelector(
  selectDecisionFoldersState,
  state => {
    const decisionFolder = state[decisionFolderId]
    if(decisionFolder?.status !== 'Success') {
      throw new Error('DecisionFolder must be loaded before using getSelectLoadableDecisionFolder')
    }
    return decisionFolder
  }
)
export const getSelectDecisionFolder = (decisionFolderId: string) => createSelector(
  getSelectLoadableDecisionFolder(decisionFolderId),
  decisionFolder => decisionFolder.data
)
export const getSelectDecisionFolderAncestry = (decisionFolderId: string) => createSelector(
  getSelectDecisionFolder(decisionFolderId),
  decisionFolder => decisionFolder.ancestry
)
export const getSelectDecisionFolderChildIds = (decisionFolderId: string) => createSelector(
  getSelectLoadableDecisionFolder(decisionFolderId),
  decisionFolder => decisionFolder.children
)
export const getSelectDecisionFolderChildren = (decisionFolderId: string) => createSelector(
  [
    getSelectDecisionFolderChildIds(decisionFolderId),
    selectDecisionFoldersState,
    selectDecisionsState,
  ],
  (decisionFolderChildIds, decisionFoldersState, decisionsState) => {
    if(!decisionFolderChildIds) return undefined
    const children: Array<Decision | DecisionFolder> = []

    decisionFolderChildIds.decisionFolders?.forEach(id => {
      const childFolder = extractLoadable(decisionFoldersState[id])
      if(childFolder) children.push(childFolder)
    })

    decisionFolderChildIds.decisions?.forEach(id => {
      const childDecision = extractLoadable(decisionsState[id])
      if(childDecision) children.push(childDecision)
    })

    return children
  }
)
export const getSelectDecisionFolderChildrenCount = (decisionFolderId: string) => createSelector(
  (state: RootState) => state.decisionFolders[decisionFolderId],
  loadableDecisionFolder => {
    if(loadableDecisionFolder?.status === 'Success' && loadableDecisionFolder.children) {
      return _sumBy(Object.values(loadableDecisionFolder.children), c => c.length)
    }
  }
)

export const getSelectDecisionLoading = (decisionId: string) => createSelector(
  selectDecisionsState,
  state => state[decisionId]?.status !== 'Success'
)
export const getSelectLoadableDecision = (decisionId: string) => createSelector(
  selectDecisionsState,
  state => {
    const decision = state[decisionId]
    if(decision?.status !== 'Success') {
      throw new Error('Decision must be loaded before using getSelectLoadableDecision')
    }
    return decision
  }
)
export const getSelectDecision = (decisionId: string) => createSelector(
  getSelectLoadableDecision(decisionId),
  decision => decision.data
)
export const getSelectDecisionChildIds = (decisionId: string) => createSelector(
  getSelectLoadableDecision(decisionId),
  decision => decision.children
)
export const getSelectDecisionAncestry = (decisionId: string) => createSelector(
  getSelectDecision(decisionId),
  decision => decision.ancestry
)
export const getSelectDecisionFolderAncestorFolders = (decisionFolderId: string) => createSelector(
  getSelectDecisionFolderAncestry(decisionFolderId),
  selectDecisionFoldersState,
  (ancestry, decisionFoldersState) => {
    const ancestorIds = splitAncestry(ancestry)
    const ancestors = ancestorIds.map(id => {
      const ancestor = extractLoadable(decisionFoldersState[id])
      if(!ancestor) {
        throw new Error('ancestor decision folders must be loaded to use getSelectDecisionFolderAncestorFolders')
      }
      return ancestor
    })
    return ancestors
  }
)
export const getSelectDecisionAncestorFolders = (decisionId: string) => createSelector(
  getSelectDecisionAncestry(decisionId),
  selectDecisionFoldersState,
  (ancestry, decisionFoldersState) => {
    const ancestorIds = splitAncestry(ancestry)
    const ancestors = ancestorIds.map(id => {
      const ancestor = extractLoadable(decisionFoldersState[id])
      if(!ancestor) {
        throw new Error('ancestor decision folders must be loaded to use getSelectDecisionAncestorFolders')
      }
      return ancestor
    })
    return ancestors
  }
)

export const getSelectChildDecisionFolderIds = (decisionFolderId: string) => createSelector(
  getSelectDecisionFolderChildIds(decisionFolderId),
  children => children?.decisionFolders
)
export const getSelectChildDecisionIds = (decisionFolderId: string) => createSelector(
  getSelectDecisionFolderChildIds(decisionFolderId),
  children => children?.decisions
)
export const getSelectLoadableChildDecisionFolders = (decisionFolderId: string) => createSelector(
  getSelectChildDecisionFolderIds(decisionFolderId),
  selectDecisionFoldersState,
  (decisionFolderIds = [], decisionFolders) => decisionFolderIds.map(id => {
    const decisionFolder = decisionFolders[id]
    if(decisionFolder?.status === 'Success') {
      return decisionFolder
    }

    throw new Error('decision folder child folders must be loaded before using getSelectChildDecisionFolders')
  })
)
export const getSelectLoadableChildDecisions = (decisionFolderId: string) => createSelector(
  getSelectChildDecisionIds(decisionFolderId),
  selectDecisionsState,
  (decisionIds = [], decisions) => decisionIds.map(id => {
    const decision = decisions[id]
    if(decision?.status === 'Success') {
      return decision
    }

    throw new Error('decision folder child decisions must be loaded before using getSelectChildDecisions')
  })
)

export const getSelectOptionIds = (decisionId: string) => createSelector(
  getSelectDecisionChildIds(decisionId),
  children => children?.options
)
export const getSelectOptionsCount = (decisionId: string) => createSelector(
  getSelectOptionIds(decisionId),
  optionIds => optionIds?.length ?? 0
)
export const getSelectParticipantIds = (decisionId: string) => createSelector(
  getSelectDecisionChildIds(decisionId),
  children => children?.participants
)
export const getSelectCriteriaIds = (decisionId: string) => createSelector(
  getSelectDecisionChildIds(decisionId),
  children => children?.criteria
)
export const getSelectRatingIds = (decisionId: string) => createSelector(
  getSelectDecisionChildIds(decisionId),
  children => children?.ratings
)
export const getSelectRatingNotesIds = (decisionId: string) => createSelector(
  getSelectDecisionChildIds(decisionId),
  children => children?.ratingNotes
)

export const getSelectParticipationSessionFromId = (
  decisionId: string,
  participationSessionId: string
) => createSelector(
  getSelectDecision(decisionId),
  decision => {
    const participationSession = decision.participationSessions.find(ps => ps.id === participationSessionId)
    if(!participationSession) {
      throw new Error(`could not find participation session with id ${participationSessionId}`)
    }
    return participationSession
  }
)

export const getSelectParticipationSessions = (decisionId: string) => createSelector(
  getSelectDecision(decisionId),
  decision => decision.participationSessions,
)

/**
 * TODO: right now, we are assuming there is only one participation session per type
 * (type: OptionRating | CriteriaPrioritization).  This won't always be true, so this
 * selector will probably eventually need to specify *which* participation session.
 */
export const getSelectParticipationSession = (
  decisionId: string,
  participationSessionType: ParticipationSessionType
) => createSelector(
  getSelectDecision(decisionId),
  decision => {
    const participationSession = decision.participationSessions?.find(ps => ps.type === participationSessionType)
    if(!participationSession) {
      throw new Error(`could not find participation session with type ${participationSessionType}`)
    }
    return participationSession
  }
)
export const getSelectParticipationSessionId = (
  decisionId: string,
  participationSessionType: ParticipationSessionType
) => createSelector(
  getSelectParticipationSession(decisionId, participationSessionType),
  participationSession => participationSession.id
)

// criteria selectors
export const getSelectCriteria = <K extends keyof Criterion>(
  decisionId: string,
  filterBy?: Pick<Criterion, K>
) => createSelector(
  getSelectCriteriaIds(decisionId),
  (state: RootState) => state.criteria.byId,
  (criteriaIds, criteriaById) => {
    const decisionCriteria = criteriaIds?.map(id => criteriaById[id])
    if(filterBy) {
      return decisionCriteria?.filter(_matches(filterBy))
    }
    return decisionCriteria
  }
)

export const getSelectCriteriaTree = (
  decisionId: string,
  rootCriterionId?: string
) => createSelector(
  getSelectCriteria(decisionId),
  criteria => {
    if(!criteria) return

    const criteriaTreeData = createTree(criteria)
    if(rootCriterionId) {
      return criteriaTreeData.byId[rootCriterionId]
    }

    return criteriaTreeData.all.find(c => c.parent === null)
  }
)

export const getSelectLeafCriteria = (
  decisionId: string,
  rootCriterionId?: string,
) => createSelector(
  getSelectCriteriaTree(decisionId, rootCriterionId),
  criteriaTree => criteriaTree ? getLeafNodeData(criteriaTree) : []
)

export const getSelectLeafCriteriaLabels = (
  decisionId: string,
  rootCriterionId?: string,
) => createSelector(
  getSelectCriteriaTree(decisionId, rootCriterionId),
  criteriaTree => criteriaTree && getNodeLabels(criteriaTree)
)
export const getSelectLeafCriterionLabel = (
  decisionId: string,
  criterionId: string,
  rootCriterionId?: string,
) => createSelector(
  getSelectLeafCriteriaLabels(decisionId, rootCriterionId),
  leafCriteriaLabels => leafCriteriaLabels?.[criterionId]
)
export const getSelectLeafCriteriaSelectOptions = (
  decisionId: string,
  rootCriterionId?: string
) => createSelector(
  getSelectCriteriaTree(decisionId, rootCriterionId),
  criteriaTree => criteriaTree ? getLeafNodeSelectOptions(criteriaTree) : []
)

// options selectors
export const getSelectOptions = (decisionId: string) => createSelector(
  getSelectOptionIds(decisionId),
  (state: RootState) => state.options.byId,
  (optionIds, optionsById) => optionIds?.map(id => optionsById[id]) ?? []
)

export const getSelectOptionSelectOptions = (decisionId: string) => createSelector(
  getSelectOptions(decisionId),
  options => options?.map((option): SelectOption => ({
    value: option.id,
    label: option.name,
  }))
)

// ratings selectors
export const getSelectRatings = (decisionId: string) => createSelector(
  getSelectRatingIds(decisionId),
  (state: RootState) => state.ratings.byId,
  (ratingIds = [], ratingsById) => ratingIds.map(ratingId => ratingsById[ratingId])
)

export type RatingFilterKeys = 'participantId' | 'contextId' | 'subjectId'
export const getSelectParticipationSessionRatings = <K extends RatingFilterKeys, F = Pick<Rating, K>>(
  decisionId: string,
  participationSessionType: ParticipationSessionType,
  filterBy?: F,
) => createSelector(
  getSelectRatings(decisionId),
  getSelectParticipationSession(decisionId, participationSessionType),
  getSelectParticipants(decisionId),
  getSelectCriteria(decisionId),
  getSelectOptions(decisionId),
  (ratings, participationSession, participants, criteria, options) => {
    const validated = validateRatings(
      ratings.filter(rating => rating.participationSessionId === participationSession.id),
      participationSession,
      _keyBy(participants, 'id'),
      _keyBy(criteria, 'id'),
      _keyBy(options, 'id'),
    )
    if(filterBy) {
      return validated.ratings.filter(_matches<F>(filterBy))
    }
    return validated.ratings
  }
)

// rating notes selectors
export const getSelectRatingNotes = <K extends keyof RatingNotes>(
  decisionId: string,
  filterBy?: Pick<RatingNotes, K>
) => createSelector(
  getSelectRatingNotesIds(decisionId),
  (state: RootState) => state.ratingNotes.byId,
  (ratingNotesIds, ratingNotesById) => {
    const ratingNotes = ratingNotesIds?.map(id => ratingNotesById[id])
    if(filterBy) {
      return ratingNotes?.filter(_matches(filterBy))
    }
    return ratingNotes
  }
)

// participants selectors
export const getSelectParticipants = (decisionId: string) => createSelector(
  getSelectParticipantIds(decisionId),
  (state: RootState) => state.participants.byId,
  (ids, byId) => ids?.map(id => byId[id])
)
