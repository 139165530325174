import style from './CriterionLabel.module.css'
import { match } from 'ts-pattern'
import { CriterionType } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { useMemo } from 'react'

interface CriterionLabelProps {
  type: CriterionType
  color?: string
  abbrev: string
  name: string
}

function createLabel(name: string, abbrev: string) {
  return name === abbrev ? name : `${name} (${abbrev})`
}

export function CriterionLabel({ type, abbrev, color, name }: CriterionLabelProps) {
  const label = useMemo(() =>
    match(type)
      .with('Value', () => 'All Criteria')
      .with('Performance', () => 'Performance')
      .with('Rated', () => createLabel(name, abbrev))
      .with('IntrinsicRoot', () => 'Quantitative Criteria')
      .with('Intrinsic', () => createLabel(name, abbrev))
      .exhaustive(),
    [type, name, abbrev]
  )
  return (
    <span>
      {color && (
        <div
          className={style.criterionColor}
          style={{ backgroundColor: color }}
        />
      )}
      {label}
    </span>
  )
}
