import { Cost, Duration as DurationType } from '@vms/vmspro3-core/dist/types/quantity'
import { Rating } from '@vms/vmspro3-core/dist/types'
import { Duration } from '@vms/vmspro3-core/dist/utils/qty'

import { naturalAlphanumericSort, numcomp } from '../../../utils/sort-utils'

type Row<T> = { values: T }

function getRowValuesByColumnId<T, U extends keyof T>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: U
): [T[U], T[U]] {
  return [rowA.values[columnId], rowB.values[columnId]]
}

export function alphanumeric<T, U extends keyof T>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: U
): number {
  const [a, b] = getRowValuesByColumnId(rowA, rowB, columnId)
  return naturalAlphanumericSort(a, b)
}

export function numeric<T, U extends keyof T>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: U
): number {
  const [a, b] = getRowValuesByColumnId(rowA, rowB, columnId)
  return numcomp(a, b)
}

export function cost<T extends { [key in U]: Cost }, U extends keyof T>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: U
): number {
  const [a, b] = getRowValuesByColumnId(rowA, rowB, columnId)
  return numcomp(a?.value, b?.value)
}

export function duration<T extends { [key in U]: DurationType }, U extends keyof T>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: U
): number {
  const [a, b] = getRowValuesByColumnId(rowA, rowB, columnId)
  // Duration.compare does not handle null or undefined
  if(!a && !b) return 0
  if(!a && b) return -1
  if(a && !b) return 1
  return Duration.compare(a, b)
}

function ratingComparison(a: Rating | undefined, b: Rating | undefined): number {
  // there's probably a more clever way to handle sorting, this is at least
  // easy enough to understand until we write a rating compare util function.
  if(!a) {
    if(!b) return 0
    return -1
  }
  if(!b) return 1

  if(a.ratingVector === null && b.ratingVector === null) return 0
  if(a.ratingVector === null) return -1
  if(b.ratingVector === null) return 1

  if(a.abstain && b.abstain) return 0
  if(a.abstain) return -1
  if(b.abstain) return 1

  return numcomp(a.ratingVector[0], b.ratingVector[0])
}

export function rating<T extends { [key in U]: Rating }, U extends keyof T>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: U,
): number {
  const [a, b] = getRowValuesByColumnId(rowA, rowB, columnId)

  return ratingComparison(a, b)
}

export function wrappedRating<T extends { [key in U]: { rating: Rating } }, U extends keyof T>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: U,
): number {
  const [a, b] = getRowValuesByColumnId(rowA, rowB, columnId)

  return ratingComparison(a?.rating, b?.rating)
}
