import { useCallback } from 'react'
import { Form, Input, Modal, Select } from 'antd'

import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'
import { createOption, updateOption } from '@vms/vmspro3-core/dist/actions/decision'

import { DurationInputWithUnits } from '../../../common/DurationInputWithUnits'
import { CostInput } from '../../../common/CostInput'

import { useOptions, useDecisionChildAncestry } from '../../../../redux/hooks'
import { colorSelectOptions } from '../../../../utils/appConsts'
import { useAppDispatch } from '../../../../redux'
import { getRandomColorFromLeastFrequent } from '../../../../utils/getRandomColorFromLeastFrequent'
import { useInitialFocusRef } from '../../../../hooks/useInitialFocusRef'
import { useLazyRef } from '../../../../hooks/useLazyRef'
import { OptionData } from '@vms/vmspro3-core/dist/nextgen/Option'

const requiredFieldRule = [
  { required: true, validateTrigger: 'onChange' },
  { whitespace: true, validateTrigger: 'onBlur' },
]

type OptionFields = Omit<OptionData, 'id'>

export type OptionModalProps = {
  decisionId: string,
  option?: OptionData,
  hideModal: VoidFunction,
}
export function OptionModal({
  decisionId,
  option,
  hideModal,
}: OptionModalProps) {
  const childAncestry = useDecisionChildAncestry(decisionId)
  const [form] = Form.useForm<OptionFields>()

  const dispatch = useAppDispatch()
  const onOk = useCallback(
    async () => {
      const { description, ...restFields } = await form.validateFields()

      const optionData = {
        ...restFields,
        description: createHtmlObject(description?.value ?? null),
      }

      if(option) {
        dispatch(updateOption(decisionId, option.id, optionData))
      } else {
        dispatch(createOption(childAncestry, optionData))
      }

      hideModal()
    },
    [form, dispatch, decisionId, childAncestry, option, hideModal]
  )

  const options = useOptions(decisionId)
  const defaultColorRef = useLazyRef(() =>
    getRandomColorFromLeastFrequent(colorSelectOptions.map(o => o.value), options)
  )

  const initialValues = option ?? { color: defaultColorRef.current, cost: null, time: null, commonId: null }

  return (
    <Modal visible onCancel={hideModal} onOk={onOk}>
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item label="Name" name="name" rules={requiredFieldRule}>
          <Input ref={useInitialFocusRef()} />
        </Form.Item>
        <Form.Item label="Abbreviation" name="abbrev" rules={requiredFieldRule}>
          <Input />
        </Form.Item>
        <Form.Item label="Option ID" name="commonId">
          <Input />
        </Form.Item>
        <Form.Item label="Color" name="color" rules={[{ required: true }]}>
          <Select>
            {colorSelectOptions.map(o => (
              <Select.Option key={o.value} value={o.value} style={{ display: 'flex' }}>
                <div style={{ width: '100%', height: '100%', padding: '4px 0', display: 'flex', gap: '12px' }}>
                  <div style={{ width: '50px', backgroundColor: o.value }} />
                  <span style={{ alignSelf: 'center' }}>{o.label}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Description"
          name={['description', 'value']}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Cost" name="cost">
          <CostInput unit="USD" />
        </Form.Item>
        <Form.Item label="Duration" name="time">
          <DurationInputWithUnits defaultUnit="Months" />
        </Form.Item>
      </Form>
    </Modal>
  )
}
