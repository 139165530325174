import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { EllipsisOutlined, WarningOutlined } from '@ant-design/icons'
import { blue } from '@ant-design/colors'
import { Dropdown, Menu, Modal } from 'antd'
import { Column, CellProps } from 'react-table'

import { AssumeAuthzType } from '@vms/vmspro3-core/dist/systemConsts'
import { UserAccountRecord_App } from '@vms/vmspro3-core/dist/types'
import { actions } from '@vms/vmspro3-core/dist'

import { UserRolesForTable } from './UserRolesForTable'
import { TableFilters } from '../../common/Table/TableFilters'
import { Table } from '../../common/Table'

import useEnforceUsersQuota from '../../../hooks/useEnforceUsersQuota'
import useAuthz from '../../../hooks/useAuthz'
import { useAppDispatch } from '../../../redux'
import { useAccount, useAuth } from '../../../context'
import { assumeAuthz } from '../../../utils/authzUtils'

const usersTableInitialState = { sortBy: [{ id: 'disabled', desc: false }] }

interface UsersTableProps {
  users: UserAccountRecord_App[]
}
export function UsersTable({ users }: UsersTableProps) {
  const authz = useAuthz()

  const { accountCommonId } = useAccount()
  const { authUserId } = useAuth()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [enforceUsersQuota, loadingEnforceUsersQuota] = useEnforceUsersQuota() as [any, boolean]

  const dispatch = useAppDispatch()
  const columns = useMemo<Column<UserAccountRecord_App>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'fullName',
        Cell: ({ row }: CellProps<UserAccountRecord_App, UserAccountRecord_App['fullName']>) => (
          <Link to={`/${accountCommonId}/user/${row.original.id}`}>{row.original.fullName}</Link>
        ),
        filter: 'text',
        Filter: TableFilters.Text,
        filterAriaLabel: 'Search Name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Role(s)',
        accessor: 'policyIds',
        disableSortBy: true,
        Cell: ({ value: policyIds }: CellProps<UserAccountRecord_App, UserAccountRecord_App['policyIds']>) => (
          <UserRolesForTable policyIds={policyIds} />
        ),
      },
      {
        Header: 'Status',
        accessor: 'disabled',
        sortType: 'basic',

        Cell: ({ value: disabled }: CellProps<UserAccountRecord_App, UserAccountRecord_App['disabled']>) => (
          disabled ? 'Deactivated' : 'Active'
        ),
      },
      {
        id: 'deactivate',
        align: 'center',
        Cell: ({ row }: CellProps<UserAccountRecord_App, UserAccountRecord_App['disabled']>) => {
          const menuItems = []

          if(row.original.disabled) {
            const activateUserAction = actions.user.enable(undefined, { userId: row.original.id })
            menuItems.push(
              <Menu.Item
                key="activate-user"
                disabled={loadingEnforceUsersQuota}
                onClick={enforceUsersQuota(
                  () => Modal.confirm({
                    title: 'Activate ' + row.original.fullName,
                    content: 'This user account will be reactivated. Are you sure you want to continue?',
                    onOk: () => { dispatch(activateUserAction) },
                  })
                )}
              >
                Activate
              </Menu.Item>
            )
          } else {
            const impersonateUserAction = actions.user.assumeAuthz({ userId: row.original.id }, {
              type: AssumeAuthzType.IMPERSONATE_USER,
              authUserId,
            })
            if(authz(impersonateUserAction)) {
              menuItems.push(
                <Menu.Item
                  key="impersonate-user"
                  onClick={() => assumeAuthz(authz, impersonateUserAction)}
                >
                  Impersonate User
                </Menu.Item>
              )
            }

            const deactivateUserAction = actions.user.disable(undefined, { userId: row.original.id, authUserId })
            if(authz(deactivateUserAction)) {
              menuItems.push(
                <Menu.Item
                  key="deactivate-user"
                  onClick={() => Modal.confirm({
                    icon: <WarningOutlined />,
                    title: 'Deactivate ' + row.original.fullName,
                    content: 'This user will be deactivated. They will no longer be able to sign into ' +
                      'this account. Are you sure you want to continue?',
                    okText: 'Deactivate ' + row.original.fullName,
                    onOk: () => { dispatch(deactivateUserAction) },
                  })}
                >
                  Deactivate
                </Menu.Item>
              )
            }
          }

          if(!menuItems.length) return null

          return (
            <Dropdown
              placement="bottomRight"
              overlay={<Menu>{menuItems}</Menu>}
            >
              <EllipsisOutlined style={{ fontSize: '20px', color: blue.primary, cursor: 'pointer' }} />
            </Dropdown>
          )
        },
      },
    ],
    [dispatch, enforceUsersQuota, loadingEnforceUsersQuota, accountCommonId, authUserId, authz]
  )

  return (
    <Table
      data={users}
      initialState={usersTableInitialState}
      columns={columns}
    />
  )
}
