import { ReactElement, useCallback, useMemo } from 'react'
import { gql, useQuery, makeReference, useMutation, ApolloError } from '@apollo/client'

import { Participant } from '@vms/vmspro3-core/dist/types'

import { QueryResult } from '../components/common/QueryResult'
import { AdHocParticipantSelection } from '../components/common/AdHocParticipation/AdHocParticipantSelection'

import { useAdHocAccount } from './AdHocAccountContext'
import { createTypedContext } from '../utils/createTypedContext'
import { useLocalStorage } from '../hooks/useLocalStorage'
import { ParticipantFieldsFragment } from '../graphql'

const sessionClosedErrorMessage = 'Participation for this decision is currently closed.\n' +
  'Please contact the decision facilitator for more information.'

function apolloError(error: ApolloError) {
  if(error.graphQLErrors.some(err => err.message === 'participation session is not active')) {
    return (
      <span>{sessionClosedErrorMessage}</span>
    )
  }

  return <span>Unknown Error: {error.graphQLErrors.map(err => err.message).join(', ')}</span>
}

interface GetDecisionParticipantsData {
  decisionMetadata: { name: string },
  decisionParticipants: Participant[],
}
interface GetDecisionParticipantsVariables {
  accountId: string | undefined,
  decisionId: string,
  participationSessionId: string,
}
const GET_DECISION_PARTICIPANTS = gql`
  query GetDecisionParticipants(
    $accountId: ID!,
    $decisionId: ID!,
    $participationSessionId: ID!
  ) {
    decisionMetadata(
      accountId: $accountId,
      decisionId: $decisionId
    ) {
      name
    }
    decisionParticipants(
      accountId: $accountId,
      decisionId: $decisionId,
      participationSessionId: $participationSessionId
    ) {
      ...ParticipantFields
    }
  }
  ${ParticipantFieldsFragment}
`

interface CreateAdHocParticipantData {
  createAdHocParticipant: Participant,
}
interface CreateAdHocParticipantVariables {
  accountId: string,
  decisionId: string,
  participationSessionId: string,
  fullName: string,
}
const ADD_PARTICIPANT = gql`
  mutation AddParticipant(
    $accountId: ID!,
    $decisionId: ID!,
    $participationSessionId: ID!,
    $fullName: String!,
  ) {
    createAdHocParticipant(
      accountId: $accountId,
      decisionId: $decisionId,
      participationSessionId: $participationSessionId,
      fullName: $fullName,
    ) {
      ...ParticipantFields
    }
  }
  ${ParticipantFieldsFragment}
`

interface AdHocParticipantContext {
  participant: Participant,
  participantId: string,
  unsetParticipant: VoidFunction,
}

const [
  useAdHocParticipant,
  ContextProvider,
] = createTypedContext<AdHocParticipantContext>('AdHocParticipantContext')

export { useAdHocParticipant }

interface AdHocParticipantProviderProps {
  decisionId: string,
  participationSessionId: string,
  children: ReactElement,
}
export function AdHocParticipantProvider({
  decisionId,
  participationSessionId,
  children,
}: AdHocParticipantProviderProps): ReactElement {
  const { accountId } = useAdHocAccount()

  const participantIdLsKey = `ad-hoc-participant-id/accountId:${accountId}/decisionId:${decisionId}`
  const [participantId, setParticipantId] = useLocalStorage<string | undefined>(participantIdLsKey)

  const unsetParticipant = useCallback(
    () => setParticipantId(undefined),
    [setParticipantId]
  )

  const getDecisionParticipantsQuery = useQuery<GetDecisionParticipantsData, GetDecisionParticipantsVariables>(
    GET_DECISION_PARTICIPANTS,
    {
      variables: {
        accountId,
        decisionId,
        participationSessionId,
      },
    }
  )

  const participant = useMemo(
    () => getDecisionParticipantsQuery.data?.decisionParticipants.find(p => p.id === participantId),
    [getDecisionParticipantsQuery.data?.decisionParticipants, participantId],
  )

  const [createAdHocParticipantMutation, createAdHocParticipantResults] = useMutation<
    CreateAdHocParticipantData,
    CreateAdHocParticipantVariables
  >(
    ADD_PARTICIPANT,
    {
      onCompleted: data => setParticipantId(data.createAdHocParticipant.id),
      onError: () => null,
      update(cache, { data }) {
        if(data?.createAdHocParticipant) {
          cache.modify({
            id: cache.identify(makeReference('ROOT_QUERY')),
            fields: {
              decisionParticipants(existingParticipants = []) {
                const newParticipantRef = cache.writeFragment({
                  data: data.createAdHocParticipant,
                  fragment: ParticipantFieldsFragment,
                  fragmentName: 'ParticipantFields',
                })

                return [...existingParticipants, newParticipantRef]
              },
            },
          })
        }
      },
    }
  )

  const createAdHocParticipant = useCallback(
    async (params: { fullName: string, phone?: string, email?: string }) => {
      await createAdHocParticipantMutation({
        variables: {
          accountId,
          decisionId,
          participationSessionId,
          ...params,
        },
      })
    },
    [createAdHocParticipantMutation, accountId, decisionId, participationSessionId]
  )

  if(getDecisionParticipantsQuery.error) {
    return apolloError(getDecisionParticipantsQuery.error)
  }
  if(createAdHocParticipantResults.error) {
    return apolloError(createAdHocParticipantResults.error)
  }

  if(!participantId || !participant) {
    return (
      <QueryResult
        data={getDecisionParticipantsQuery.data}
        error={getDecisionParticipantsQuery.error}
        loading={getDecisionParticipantsQuery.loading}
      >
        {({ decisionMetadata, decisionParticipants }) => (
          <AdHocParticipantSelection
            participants={decisionParticipants}
            setParticipantId={setParticipantId}
            createAdHocParticipant={createAdHocParticipant}
            decisionName={decisionMetadata.name}
          />
        )}
      </QueryResult>
    )
  }

  return (
    <ContextProvider value={{ participant, participantId, unsetParticipant }}>
      {children}
    </ContextProvider>
  )
}
