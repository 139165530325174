import React from 'react'

export function TextFilter(props) {
  const { column: { setFilter, filterValue, filterAriaLabel } } = props

  return (
    <input
      placeholder="Search"
      aria-label={filterAriaLabel}
      value={filterValue || ''}
      // empty string sets value to undefined to clear filter
      onChange={e => setFilter(e.target.value || undefined)}
    />
  )
}
