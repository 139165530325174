import { useEffect, useRef } from 'react'

export function useInitialFocusRef<T extends { focus: VoidFunction }>() {
  const ref = useRef<T>(null)
  useEffect(
    () => {
      ref.current?.focus()
    },
    []
  )
  return ref
}
