import React, { useMemo } from 'react'
import _keyBy from 'lodash/keyBy'

import Select from '../controls/Select'

/**
 * A select component to select risk enum fields (phase, type, etc.).  Note that this shouldn't be used
 * with risk scales since those use "key" instead of "value".

 * @param {string} value - Selected enum value (or null/undefined, which will render
 *   an empty string).
 * @param {object[]} enumValues - Enum values from effecive risk context
 *   (effectiveRiskContext.types.<field>.values).
 * @param {(value: string) => void} onChange - Callback when the user changes or
 *   clears the current value.
 */
const RiskEnumSelect = ({ value, enumValues, onChange, labelType = 'long', ...otherProps }) => {
  const byValue = useMemo(() => _keyBy(enumValues, 'value'), [enumValues])
  if(!byValue[value] || byValue[value].isDeleted) value = ''
  return (
    <Select onChange={onChange} value={value} {...otherProps}>
      {enumValues.filter(v => !v.isDeleted).map(v =>
        <Select.Option key={v.value}>{byValue[v.value]?.label?.[labelType]}</Select.Option>
      )}
    </Select>
  )
}

export default RiskEnumSelect
