import { Invitation, UserAccountRecord_App, UserGlobalRecord_App } from '@vms/vmspro3-core/dist/types'
import { AnyAction } from 'redux'

import Server from '../../server/VMSProServerAdapter'
import { getCurrentUser } from '../../services/userService'
import { getPendingInvitationsForUser } from '../../services/accountService'
import { AsyncAppThunk } from '../store'

export interface FetchCurrentUserRequestAction {
  type: 'FetchCurrentUserRequest',
  meta: { ephemeral: true },
}
export interface FetchCurrentUserSuccessAction {
  type: 'FetchCurrentUserSuccess',
  payload: UserGlobalRecord_App,
  meta: { ephemeral: true },
}
export function fetchCurrentUser(userId: string): AsyncAppThunk {
  return async dispatch => {
    try {
      dispatch({
        type: 'FetchCurrentUserRequest',
        meta: { ephemeral: true },
      })

      const user = await getCurrentUser(userId) as unknown as UserGlobalRecord_App
      dispatch({
        type: 'FetchCurrentUserSuccess',
        payload: user,
        meta: { ephemeral: true },
      })
    } catch(error) {
      console.error(error)
    }
  }
}

export interface SetAccountUserAction {
  type: 'SetAccountUser',
  payload: { accountUser: UserAccountRecord_App },
  meta: { ephemeral: true },
}
export function setAccountUser(accountUser: UserAccountRecord_App): SetAccountUserAction {
  return {
    type: 'SetAccountUser',
    payload: { accountUser },
    meta: { ephemeral: true },
  }
}

export interface FetchUserInvitationsRequestAction {
  type: 'FetchUserInvitationsRequest',
  meta: { ephemeral: true },
}
export interface FetchUserInvitationsSuccessAction {
  type: 'FetchUserInvitationsSuccess',
  payload: Invitation[],
  meta: { ephemeral: true },
}
export function fetchUserInvitations(email: string): AsyncAppThunk {
  return async dispatch => {
    try {
      dispatch({
        type: 'FetchUserInvitationsRequest',
        meta: { ephemeral: true },
      })

      const userInvitations = await getPendingInvitationsForUser(email) as Invitation[]
      dispatch({
        type: 'FetchUserInvitationsSuccess',
        payload: userInvitations,
        meta: { ephemeral: true },
      })
    } catch(error) {
      console.error(error)
    }
  }
}

export interface AcceptAccountInvitationAction {
  type: 'AcceptAccountInvitation',
  payload: { accountId: string, email: string },
  meta: { ephemeral: true },
}
export function acceptInvitation(augmentedAction: AnyAction): AsyncAppThunk {
  return async dispatch => {
    try {
      await Server.tryAction(augmentedAction)

      dispatch({
        type: 'AcceptAccountInvitation',
        payload: { accountId: augmentedAction.payload.accountId, email: augmentedAction.payload.email },
        meta: { ephemeral: true },
      })
    } catch(error) {
      console.error(error)
    }
  }
}
