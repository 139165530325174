import { useMemo, ReactElement } from 'react'
import { createSelector } from 'reselect'
import { Column } from 'react-table'
import _groupBy from 'lodash/groupBy'
import _keyBy from 'lodash/keyBy'
import _meanBy from 'lodash/meanBy'
import _mapValues from 'lodash/mapValues'

import { Rating, RatingVector } from '@vms/vmspro3-core/dist/types'
import { augmentWithStats, NumberWithStats } from '@vms/vmspro3-core/dist/stats/quartiles'

import { Table, TableCellRenderer } from '../../../common/Table'
import {
  getRatingCellRenderer,
  getRatingWithStatsCellRenderer,
} from '../../../common/Table/TableCellRenderer/RatingCellRenderer'

import { useAppSelector } from '../../../../redux'
import {
  getSelectOptions,
  getSelectParticipants,
  getSelectParticipationSessionRatings,
} from '../../../../redux/selectors'

const ratingFractionDigits = 1
const RatingCellRenderer = getRatingCellRenderer(ratingFractionDigits)
const RatingWithStatsCellRenderer = getRatingWithStatsCellRenderer(ratingFractionDigits)

type OptionRatingsForCriterionTableData = {
  name: string,
  color: string,
  ratingsByParticipantId: Record<string, { rating: Rating, stats: NumberWithStats } | undefined>,
  groupAverage: number | null,
}
type OptionRatingsForCriterionTableProps = {
  decisionId: string,
  criterionId: string,
}
export function OptionRatingsForCriterionTable({
  decisionId,
  criterionId,
}: OptionRatingsForCriterionTableProps): ReactElement {
  const columns = useAppSelector(useMemo(
    () => createSelector(
      getSelectParticipants(decisionId),
      (participants = []): Column<OptionRatingsForCriterionTableData>[] => [
        {
          Header: 'Option',
          accessor: 'name',
          Cell: TableCellRenderer.EntityName,
        },
        {
          Header: 'Participant Ratings',
          columns: participants.map(participant => ({
            id: participant.id,
            Header: participant.fullName,
            accessor: row => row.ratingsByParticipantId[participant.id],
            align: 'right',
            sortType: 'wrappedRating',
            Cell: RatingWithStatsCellRenderer,
          })),
        },
        {
          Header: 'Average Rating',
          accessor: 'groupAverage',
          align: 'right',
          sortType: 'numeric',
          Cell: RatingCellRenderer,
        },
      ]
    ),
    [decisionId]
  ))

  const data = useAppSelector(useMemo(
    () => createSelector(
      getSelectOptions(decisionId),
      getSelectParticipationSessionRatings<'contextId'>(decisionId, 'OptionRating', { contextId: criterionId }),
      (options, ratings): OptionRatingsForCriterionTableData[] => {
        const ratingsByOptionId = _groupBy(ratings, 'subjectId')
        return options.map(option => {
          const ratingsWithStats = augmentWithStats(
            ratingsByOptionId[option.id] ?? [],
            r => r.ratingVector?.[0] ?? undefined,
            ratingFractionDigits
          )
          const ratingsByParticipantId = _mapValues(
            _keyBy(ratingsWithStats, 'datum.participantId'),
            ({ datum: rating, numberWithStats: stats }) => ({ rating, stats }),
          )
          const groupRatings = (ratingsByOptionId[option.id] ?? [])
            .filter((r): r is Rating & { ratingVector: NonNullable<RatingVector> } => (
              r.ratingVector !== null && !r.abstain
            ))
          const groupAverage = groupRatings.length > 0
            ? _meanBy(groupRatings, rating => rating.ratingVector[0])
            : null

          return {
            name: option.name,
            color: option.color,
            ratingsByParticipantId,
            groupAverage,
          }
        })
      }
    ),
    [decisionId, criterionId]
  ))

  return (
    <Table<OptionRatingsForCriterionTableData>
      columns={columns}
      data={data}
    />
  )
}
