import moment from 'moment'
import { Descriptions } from 'antd'

import { Subscription } from '@vms/vmspro3-core/dist/types'

import { selectDateTimeFormat, selectAccount } from '../../../redux/selectors'
import { useAppSelector } from '../../../redux'

const getQuotaUsage = (quota: { allowed: number, used: number }) => quota.allowed < 10_000_000
  ? `${quota.used} of ${quota.allowed}`
  : `${quota.used} (unlimited)`

interface SubscriptionInfoProps {
  subscription: Subscription,
}
export function SubscriptionInfo({ subscription }: SubscriptionInfoProps) {
  const dateTimeFormat = useAppSelector(selectDateTimeFormat)
  const account = useAppSelector(selectAccount)

  if(!account) {
    throw new Error('Account must be loaded before rendering SubscriptionInfo')
  }

  const info = [
    ['Account name', account.name],
    ['Account created', moment(account.createdOn).format(dateTimeFormat)],
    ['Account status', account.status],
  ]
  if(typeof subscription.currentMonthlyBill === 'number') {
    info.push(['Current monthly bill', '$' + subscription.currentMonthlyBill.toFixed(2)])
  }
  if(typeof subscription.currentPeriodEnd === 'number') {
    info.push(['Next payment due', moment(subscription.currentPeriodEnd).format(dateTimeFormat)])
  }

  return (
    <>
      <Descriptions title="Account Info" column={1}>
        {info.map(item => <Descriptions.Item key={item[0]} label={item[0]}>{item[1]}</Descriptions.Item>)}
      </Descriptions>
      <Descriptions title="Plan Usage" column={1}>
        <Descriptions.Item label="Users">
          {getQuotaUsage(account.quotas.users)}
        </Descriptions.Item>
        <Descriptions.Item label="Projects">
          {getQuotaUsage(account.quotas.projects)}
        </Descriptions.Item>
        {account.quotas.decisions.allowed > 0 &&
          <Descriptions.Item label="Decisions">
            {getQuotaUsage(account.quotas.decisions)}
          </Descriptions.Item>
        }
      </Descriptions>
    </>
  )
}
