// this is designed to replace LinkButton, which is simply a styled <span>, which is
// not semantic...not that we have a big accessibility requirement with this app (yet)
// but no point in doing things bad from the start....  because LinkButton is used
// in so many places, I'm hestitent just yanking it out...we'll start by injecting
// LinkButton2 to see how that goes.  new developnet should use this.

import React from 'react'
import { css } from 'glamor'
import { Color } from '@vms/vmspro3-core/dist/systemConsts'

const buttonLinkStyle = bold => ({
  backgroundColor: 'transparent',
  border: 'none',
  color: Color.BLUE_LINK,
  cursor: 'pointer',
  font: 'inherit',
  fontWeight: bold ? 600 : 400,
  padding: '4px',
})

const LinkButton2 = ({ bold, style, ...props }) => (
  <button type="button" {...css(buttonLinkStyle(bold), style)} {...props} />
)

LinkButton2.defaultProps = { bold: false, style: {} }

export default LinkButton2
