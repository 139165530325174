import { useMemo } from 'react'
import { Column } from 'react-table'

import { Table } from '../../../common/Table'

import { CriteriaPriorityTableData, useCriteriaPriorityTableData } from './CriteriaPriorityTable.createTableRows'

import { useParticipants } from '../../../../redux/hooks'
import {
  getNumberCellRenderer,
  getNumberCellRendererWithStats,
} from '../../../common/Table/TableCellRenderer/getNumberCellRenderer'

const maximumFractionDigits = 1
const formatter = Intl.NumberFormat(undefined, {
  minimumFractionDigits: maximumFractionDigits,
  maximumFractionDigits,
  style: 'percent',
})

const PriorityRenderer = getNumberCellRenderer({ formatter })
const PriorityRendererWithStats = getNumberCellRendererWithStats({ formatter })

type CriteriaPriorityTableProps = {
  decisionId: string,
}
export function CriteriaPriorityTable({ decisionId }: CriteriaPriorityTableProps): React.ReactElement {
  const participants = useParticipants(decisionId)

  const columns = useMemo(
    () => {
      const columns: Column<CriteriaPriorityTableData>[] = [
        {
          Header: 'Criterion',
          accessor: 'name',
          excel: {
            width: 65,
          },
          Cell: ({ row }) => row.canExpand
            ? (
              <span
                {...row.getToggleRowExpandedProps({
                  style: {
                    paddingLeft: `${row.depth * 20}px`,
                  },
                })}
              >
                {row.original.name}{' '}
                <span style={{ userSelect: 'none' }}>{row.isExpanded ? '▼' : '▶︎'}</span>
              </span>
            )
            : <span style={{ paddingLeft: `${row.depth * 20}px` }}>{row.original.name}</span>,
        },
        {
          Header: 'Local Priorities',
          columns: participants.map<Column<CriteriaPriorityTableData>>(participant => ({
            id: participant.id,
            Header: participant.fullName,
            accessor: row => row.priorityByParticipantId[participant.id],
            Cell: PriorityRendererWithStats,
            sortType: 'numeric',
            align: 'right',
          })),
        },
        {
          Header: 'Group Avg.',
          columns: [
            {
              Header: 'Local',
              accessor: 'groupAvgLocal',
              Cell: PriorityRenderer,
              sortType: 'numeric',
              align: 'right',
            },
            {
              Header: 'Global',
              accessor: 'groupAvgGlobal',
              Cell: PriorityRenderer,
              sortType: 'numeric',
              align: 'right',
            },
          ],
        },
      ]

      return columns
    },
    [participants]
  )

  const data = useCriteriaPriorityTableData(decisionId, maximumFractionDigits)

  return (
    <Table<CriteriaPriorityTableData>
      columns={columns}
      data={data}
    />
  )
}
