import { useMemo, ReactElement } from 'react'
import { createSelector } from 'reselect'
import { Column } from 'react-table'
import _groupBy from 'lodash/groupBy'
import _keyBy from 'lodash/keyBy'
import _meanBy from 'lodash/meanBy'
import _mapValues from 'lodash/mapValues'

import { Rating, RatingVector } from '@vms/vmspro3-core/dist/types'
import { augmentWithStats, NumberWithStats } from '@vms/vmspro3-core/dist/stats/quartiles'

import { Table, TableCellRenderer } from '../../../common/Table'
import {
  getRatingCellRenderer,
  getRatingWithStatsCellRenderer,
} from '../../../common/Table/TableCellRenderer/RatingCellRenderer'

import { useAppSelector } from '../../../../redux'
import { useRootPerfCriterionId } from '../../../../redux/hooks'
import {
  getSelectLeafCriteria,
  getSelectParticipants,
  getSelectParticipationSessionRatings,
} from '../../../../redux/selectors'

const ratingFractionDigits = 1
const RatingCellRenderer = getRatingCellRenderer(ratingFractionDigits)
const RatingWithStatsCellRenderer = getRatingWithStatsCellRenderer(ratingFractionDigits)

type OptionRatingsForOptionTableData = {
  name: string,
  color: string,
  ratingsByParticipantId: Record<string, { rating: Rating, stats: NumberWithStats } | undefined>,
  groupAverage: number | null,
}
type OptionRatingsForOptionTableProps = {
  decisionId: string,
  optionId: string,
}
export function OptionRatingsForOptionTable({
  decisionId,
  optionId,
}: OptionRatingsForOptionTableProps): ReactElement {
  const columns = useAppSelector(useMemo(
    () => createSelector(
      getSelectParticipants(decisionId),
      (participants = []): Column<OptionRatingsForOptionTableData>[] => [
        {
          Header: 'Criterion',
          accessor: 'name',
          Cell: TableCellRenderer.EntityName,
        },
        {
          Header: 'Participant Ratings',
          columns: participants.map(participant => ({
            id: participant.id,
            Header: participant.fullName,
            accessor: row => row.ratingsByParticipantId[participant.id],
            align: 'right',
            sortType: 'wrappedRating',
            Cell: RatingWithStatsCellRenderer,
          })),
        },
        {
          Header: 'Average Rating',
          accessor: 'groupAverage',
          align: 'right',
          sortType: 'numeric',
          Cell: RatingCellRenderer,
        },
      ]
    ),
    [decisionId]
  ))

  const rootPerformanceCriterionId = useRootPerfCriterionId(decisionId)
  const data = useAppSelector(useMemo(
    () => createSelector(
      getSelectLeafCriteria(decisionId, rootPerformanceCriterionId),
      getSelectParticipationSessionRatings<'subjectId'>(decisionId, 'OptionRating', { subjectId: optionId }),
      (leafCriteria, ratings): OptionRatingsForOptionTableData[] => {
        const ratingsByCriterionId = _groupBy(ratings, 'contextId')
        return leafCriteria.map(criterion => {
          const ratingsWithStats = augmentWithStats(
            ratingsByCriterionId[criterion.id] ?? [],
            r => r.ratingVector?.[0] ?? undefined,
            ratingFractionDigits
          )
          const ratingsByParticipantId = _mapValues(
            _keyBy(ratingsWithStats, 'datum.participantId'),
            ({ datum: rating, numberWithStats: stats }) => ({ rating, stats }),
          )

          const groupRatings = (ratingsByCriterionId[criterion.id] ?? [])
            .filter((r): r is Rating & { ratingVector: NonNullable<RatingVector> } => (
              r.ratingVector !== null && !r.abstain
            ))
          const groupAverage = groupRatings.length > 0
            ? _meanBy(groupRatings, r => r.ratingVector[0])
            : null

          return {
            name: criterion.name,
            color: criterion.color,
            ratingsByParticipantId,
            groupAverage,
          }
        })
      }
    ),
    [decisionId, optionId, rootPerformanceCriterionId]
  ))

  return (
    <Table<OptionRatingsForOptionTableData>
      columns={columns}
      data={data}
    />
  )
}
