import React, { useEffect, useState } from 'react'
import { Cost, CostUnitMetadata } from '@vms/vmspro3-core/dist/utils/qty'
import _get from 'lodash/get'
import { Input } from 'antd'

import { formatNumValue, parseNumValue } from '../../../utils/inputUtils'
import { CostUnit } from '@vms/vmspro3-core/dist/types'

interface CostInputProps {
  allowNull?: boolean,
  unit: CostUnit,
  decimalPlaces?: number,
  onBlur?: Input['onBlur'],
  onChange?: (cost: Cost | null) => void,
  readOnly?: boolean,
  style?: React.CSSProperties,
  value?: Partial<Cost> | null,
}

/**
 * @deprecated CostInput_Risk is only for use in existing Risk module code, see CostInput
 */
const CostInput_Risk = React.forwardRef<Input, CostInputProps>(({
  allowNull = true,
  unit,
  decimalPlaces = 0,
  onBlur,
  onChange,
  readOnly,
  style = {},
  value,
  ...props
}, ref) => {
  if(!value) value = {}

  const [displayValue, setDisplayValue] = useState(formatNumValue(value.value, { allowNull, decimalPlaces }))
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    if(!editing) {
      setDisplayValue(formatNumValue(value?.value, { allowNull, decimalPlaces }))
    }
  }, [allowNull, decimalPlaces, editing, value.value])

  const valuePrefix = _get(CostUnitMetadata, [unit, 'symbol'], unit || '')

  if(readOnly) {
    return (
      <div ref={ref as React.LegacyRef<HTMLDivElement>} style={readOnlyStyle}>
        {(typeof value.value === 'undefined' || value.value === null)
          ? null
          : <span>{valuePrefix}{displayValue}</span>
        }
      </div>
    )
  }

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = event => {
    setEditing(false)
    if(onBlur) onBlur(event)
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const v = event.target.value
    setEditing(true)
    setDisplayValue(v)
    const parsedValue = parseNumValue(v, allowNull, 'financial') ?? undefined
    if(onChange) {
      onChange(Number.isFinite(parsedValue) ? new Cost(unit, parsedValue) : null)
    }
  }

  return (
    <Input
      ref={ref}
      {...props}
      addonBefore={valuePrefix}
      onBlur={handleBlur}
      onChange={handleChange}
      style={{ textAlign: 'right', ...style }}
      value={displayValue}
    />
  )
})

const readOnlyStyle: React.CSSProperties = {
  minHeight: '32px',
  padding: '5px 12px 6px',
  textAlign: 'right',
  width: '100%',
}

export default CostInput_Risk
