import { ReactElement, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { UnreachableCaseError } from 'ts-essentials'
import { List, Radio } from 'antd'

import { Html } from '@vms/vmspro3-core/dist/types'
import { OptionData } from '@vms/vmspro3-core/dist/nextgen/Option'
import { CriterionData } from '@vms/vmspro3-core/dist/nextgen/Criterion'

import { RenderHtmlObject } from '../RenderHtmlObject'
import style from './RatingContextDetails.module.css'

type RatingContextDetailsType = 'context' | 'subject'
export type RatingContextDetailsProps = {
  decisionObjective: Html,
  subjectTypeLabel: string,
  ratingContextLabel: string,
  ratingContextDescription?: Html,
  subjects: (CriterionData | OptionData)[],
}
export function RatingContextDetails({
  decisionObjective,
  subjectTypeLabel,
  ratingContextLabel,
  ratingContextDescription,
  subjects,
}: RatingContextDetailsProps): ReactElement {
  const [detailsType, setDetailsType] = useState<RatingContextDetailsType>('context')

  return (
    <>
      <h2>Decision Objective:</h2>
      <RenderHtmlObject htmlObject={decisionObjective} />
      <Radio.Group
        buttonStyle="solid"
        value={detailsType}
        onChange={e => setDetailsType(e.target.value)}
      >
        <Radio.Button value="context">Context Criterion</Radio.Button>
        <Radio.Button value="subject">{subjectTypeLabel}</Radio.Button>
      </Radio.Group>
      <div className={style.descriptions}>
        {(() => {
          switch(detailsType) {
            case 'context': {
              return (
                <List.Item>
                  <List.Item.Meta
                    title={ratingContextLabel}
                    description={
                      <div className={style.description}>
                        <RenderHtmlObject htmlObject={ratingContextDescription} />
                      </div>
                    }
                  />
                </List.Item>
              )
            }
            case 'subject': {
              return (
                <List
                  dataSource={subjects}
                  renderItem={subject => (
                    <List.Item key={subject.id}>
                      <List.Item.Meta
                        title={
                          <div className={style.subjectDescriptionTitle}>
                            <FontAwesomeIcon icon={faCircle} color={subject.color} />
                            <span>{subject.name} ({subject.abbrev})</span>
                          </div>
                        }
                        description={
                          <div className={style.description}>
                            <RenderHtmlObject htmlObject={subject.description} />
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              )
            }
            default: {
              throw new UnreachableCaseError(detailsType)
            }
          }
        })()}
      </div>
    </>
  )
}
