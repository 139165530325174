import { UserAccountRecord_App } from '@vms/vmspro3-core/dist/types'

import { getUsers } from '../../services/usersService'
import { AsyncAppThunk } from '../store'

export interface FetchUsersRequestAction {
  type: 'FetchUsersRequest',
  meta: { ephemeral: true },
}
export interface FetchUsersSuccessAction {
  type: 'FetchUsersSuccess',
  payload: UserAccountRecord_App[],
  meta: { ephemeral: true },
}
export function fetchUsers(accountId: string): AsyncAppThunk {
  return async dispatch => {
    try {
      dispatch({
        type: 'FetchUsersRequest',
        meta: { ephemeral: true },
      })

      const users = await getUsers(accountId)
      dispatch({
        type: 'FetchUsersSuccess',
        payload: users,
        meta: { ephemeral: true },
      })
    } catch(error) {
      console.error('fetchUsers error: ', error)
    }
  }
}
