import { Middleware, AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'

import { CreateDecisionAction, ReloadDecisionAction } from '@vms/vmspro3-core/dist/actions/decision'

import pubSub from '../../services/pubSubService'
import clientId from '../../utils/clientId'
import { stage } from '../../utils/env'
import { RootState } from '../reducers'
import {
  FetchUsersSuccessAction,
  FetchDecisionEntitySuccessAction,
  FetchDecisionFolderChildrenSuccessAction,
  ResetAccountStateAction,
  FetchRiskEntitySuccessAction,
  FetchRiskEntityChildrenSuccessAction,
} from '../actions'

type PubSubData = {
  value: {
    originClientId: string,
    action: AnyAction,
  }
}
function subscribeToTopic(
  subscribe: (topic: string, onNextData: (data: PubSubData) => void) => void,
  topic: string,
  dispatch: (action: AnyAction) => void,
) {
  subscribe(`${stage}/${topic}`, data => {
    const { originClientId, action } = data.value
    if(originClientId !== clientId) dispatch(action)
  })
}

const getPathFromAncestry = (ancestry: string) => ancestry && ancestry !== '/' ? ancestry : ''
type Actions =
  | ResetAccountStateAction
  | FetchDecisionEntitySuccessAction
  | FetchDecisionFolderChildrenSuccessAction
  | CreateDecisionAction
  | FetchUsersSuccessAction
  | ReloadDecisionAction
  | FetchRiskEntitySuccessAction
  | FetchRiskEntityChildrenSuccessAction

type PubSubMiddleware = Middleware<unknown, RootState, ThunkDispatch<RootState, undefined, AnyAction>>
const pubSubMiddleware: PubSubMiddleware = store => next => (action?: Actions) => {
  if(!action) return

  const { dispatch, getState } = store

  switch(action.type) {
    // TODO: there's no IoT/IAM policy to support this case yet
    // case userActionTypes.FetchCurrentUserSuccess: {
    //   if(payload.id) {
    //     subscribeToTopic(`${ROOT_ACCOUNT_ID}/user/${payload.id}`, dispatch)
    //   }
    //   break
    // }

    // TODO: move subscription to UserAccountsContext
    // case 'FetchAccountUserSuccess': {
    //   subscribeToTopic(
    //     pubSub.subscribeToAccountTopic,
    //     `${action.payload.accountId}/user/${action.payload.id}`,
    //     dispatch
    //   )
    //   break
    // }

    case 'FetchUsersSuccess': {
      const accountId = getState().account.account?.id
      subscribeToTopic(pubSub.subscribeToAccountTopic, `${accountId}/user/+`, dispatch)
      break
    }
    case 'FetchRiskEntitySuccess': {
      const accountId = getState().account.account?.id
      const { entity, ancestors } = action.payload
      if(entity) {
        // subscribe to risk entity
        const { entityType, ancestry } = entity
        subscribeToTopic(
          pubSub.subscribeToAccountTopic,
          `${accountId}/${entityType}${getPathFromAncestry(ancestry)}/${action.meta.entityId}`,
          dispatch
        )
      }
      if(ancestors) {
        ancestors.forEach(ancestor => {
          // subscribe to entity ancestors
          const { entityType, ancestry, id: ancestorId } = ancestor
          subscribeToTopic(
            pubSub.subscribeToAccountTopic,
            `${accountId}/${entityType}${getPathFromAncestry(ancestry)}/${ancestorId}`,
            dispatch
          )
        })
      }
      break
    }
    case 'FetchRiskEntityChildrenSuccess': {
      const accountId = getState().account.account?.id
      // subscribe to messages about risk entity children. if entityType not specified, + filter will
      // allow any value for entityType.
      subscribeToTopic(
        pubSub.subscribeToAccountTopic,
        `${accountId}/+${getPathFromAncestry(action.meta.childAncestry)}/+`,
        dispatch
      )
      break
    }

    // TODO: move subscription to UserAccountsContext, subscribe to all?
    // case 'FetchAccountSuccess': {
    //   if(!action.payload) return
    //   subscribeToTopic(
    //     pubSub.subscribeToAccountTopic,
    //     `${action.payload?.id}/${EntityType.ACCOUNT}`,
    //     action => {
    //       // if this action is being received here the app is logged in to this
    //       // account and is not the origin client who dispatched the action.
    //       if(action.type === actions.account.cancelSubscription.toString()) {
    //         Modal.info({
    //           onOk: async () => {
    //             // TODO: need to move this subscription to UserAccountsContext
    //             // refetchUserAccounts()
    //             window.location.href = '/'
    //           },
    //           closable: false,
    //           title: 'Account Subscription Canceled',
    //           content: 'An account administrator has canceled the subscription for this account. ' +
    //             'Please press OK to return to the account selection screen',
    //         })
    //       } else {
    //         dispatch(action)
    //       }
    //     }
    //   )
    //   break
    // }

    case 'ResetAccountState': {
      pubSub.unsubscribeFromAccountTopics()
      break
    }
    case 'FetchDecisionEntitySuccess': {
      const accountId = getState().account.account?.id
      const { decisionEntityId } = action.meta
      if(action.payload.decisions?.[decisionEntityId] || action.payload.decisionFolders?.[decisionEntityId]) {
        subscribeToTopic(pubSub.subscribeToAccountTopic, `${accountId}/decision/${decisionEntityId}`, dispatch)
      }
      break
    }
    case 'FetchDecisionFolderChildrenSuccess': {
      const accountId = getState().account.account?.id
      if(action.payload.decisions) {
        Object.keys(action.payload.decisions).forEach(decisionId => {
          subscribeToTopic(pubSub.subscribeToAccountTopic, `${accountId}/decision/${decisionId}`, dispatch)
        })
      }
      if(action.payload.decisionFolders) {
        Object.keys(action.payload.decisionFolders).forEach(decisionFolderId => {
          subscribeToTopic(pubSub.subscribeToAccountTopic, `${accountId}/decision/${decisionFolderId}`, dispatch)
        })
      }
      break
    }
    case 'Create Decision': {
      const accountId = getState().account.account?.id
      subscribeToTopic(pubSub.subscribeToAccountTopic, `${accountId}/decision/${action.payload.id}`, dispatch)
      break
    }
    case 'Reload Decision': {
      const accountId = getState().account.account?.id
      pubSub.unsubscribeFromAccountTopic(`${stage}/${accountId}/decision/${action.meta.decisionId}`)
      break
    }
    default:
      break
  }

  return next(action)
}

export default pubSubMiddleware
