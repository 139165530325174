import { useCallback, useState } from 'react'
import { DragStartEvent, useDndMonitor } from '@dnd-kit/core'

import { Html, RatingSubjectType } from '@vms/vmspro3-core/dist/types'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'

import { RenderHtmlObject } from '../RenderHtmlObject'

interface DetailMessage {
  title: string,
  description?: Html,
}

interface RatingDetailPanelProps {
  subjectType: RatingSubjectType,
  total: number,
  remaining: number
  participationComplete: boolean,
  contextName: string,
}

function getRemainingMessage({
  subjectType,
  total,
  remaining,
  participationComplete,
  contextName,
}: RatingDetailPanelProps): DetailMessage {
  const pluralBySubjectType: Record<RatingSubjectType, string> = {
    Criterion: 'criteria',
    Option: 'options',
  }
  const ratingRelationshipBySubjectType: Record<RatingSubjectType, string> = {
    Criterion: `relative preference`,
    Option: `performance with respect to ${contextName}`,
  }

  if(remaining === total) {
    return {
      title: contextName,
      description: createHtmlObject(
        `Drag the ${pluralBySubjectType[subjectType]} below onto the rating area to the left to indicate their ` +
        `${ratingRelationshipBySubjectType[subjectType]}. You\'ll see more information as you start dragging.`
      ),
    }
  } else if(remaining > 0) {
    return {
      title: `${remaining} more to go!`,
      description: createHtmlObject(
        `Keep dragging the ${pluralBySubjectType[subjectType]} onto the rating canvas.`
      ),
    }
  }
  return {
    title: 'All done!',
    description: createHtmlObject(
      `You may continue to adjust your ratings. When you're ready to move on, click ` +
      `"${participationComplete ? 'Done' : 'Next'}" below.`
    ),
  }
}

export function RatingDetailPanel({
  subjectType,
  total,
  remaining,
  participationComplete,
  contextName,
}: RatingDetailPanelProps) {
  const [subjectData, setSubjectData] = useState<{ title: string, description?: Html } | undefined>()

  useDndMonitor({
    onDragStart: useCallback(
      (event: DragStartEvent) => {
        const subject = event.active.data.current?.subject
        if(subject) {
          setSubjectData({
            title: `${subject.name} (${subject.abbrev})`,
            description: subject.description,
          })
        }
      },
      []
    ),
    onDragEnd: useCallback(() => setSubjectData(undefined), []),
  })

  const message = subjectData || getRemainingMessage({
    subjectType,
    total,
    remaining,
    participationComplete,
    contextName,
  })

  return (
    <>
      <h3>{message.title}</h3>
      <RenderHtmlObject htmlObject={message.description} />
    </>
  )
}
