import React from 'react'
import { useDispatch } from 'react-redux'
import { produce } from 'immer'
import { WarningOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Button, Row, Col, Empty, Space, Modal } from 'antd'

import { actions } from '@vms/vmspro3-core/dist'

import RiskPhaseModal from '../modals/RiskPhaseModal'
import { Table } from '../controls'
import useRiskEntity from '../hooks/useRiskEntity'
import { useAccount } from '../../../context'
import { useShowModal } from '../RiskModalContext'

const RiskContextPhaseEditor = ({ entityId }) => {
  const showModal = useShowModal()
  const dispatch = useDispatch()
  const { accountCommonId } = useAccount()

  const { entity, riskContext, effectiveRiskContext } = useRiskEntity(entityId)

  if(entity.ancestry !== '/') {
    // TODO: eventually, you will be able to "turn off" categories or phases you don't want,
    // and define custom fields at the project level.  For now, we're just directing them to
    // the portfolio level.
    const path = `/${accountCommonId}/port/config?tab=phases`
    return (
      <Empty
        description={
          <span>
            Please edit phases at the <Link to={path}>portfolio level</Link>.
          </span>
        }
      />
    )
  }

  const phaseType = effectiveRiskContext.types.phase
  if(!phaseType) throw new Error('missing "phase" type in risk context')

  const values = phaseType.values.filter(v => !v.isDeleted)

  const modalPayload = {
    entityId,
    entityType: entity.entityType,
    riskContext,
    entityAncestry: entity.ancestry,  // needed for pubsub
    phases: values,
  }

  const onAdd = () => showModal(RiskPhaseModal.id, modalPayload)

  const onEdit = phase => showModal(RiskPhaseModal.id, { ...modalPayload, phase })

  const onDelete = phaseToDelete => {
    Modal.confirm({
      title: `Are you sure you want to delete "${phaseToDelete.label.long}"?`,
      icon: <WarningOutlined />,
      onOk() {
        // note that we have to send the entire risk context to update
        const payload = {
          ...riskContext,
          types: produce(riskContext.types, types => {
            const val = types.phase.values.find(v => v.value === phaseToDelete.value)
            if(val) val.isDeleted = true
          }),
        }
        const meta = {
          entityId,
          entityType: entity.entityType,
          ancestry: entity.ancestry,
        }
        dispatch(actions.riskContext.update(payload, meta))
      },
    })
  }

  const editableTableConfig = {
    columns: [
      {
        title: 'Name',
        dataIndex: ['label', 'long'],
        width: '25%',
      },
      {
        title: 'Abbrev.',
        dataIndex: ['label', 'short'],
        width: '15%',
      },
      {
        title: 'Description',
        dataIndex: 'desc',
        width: '40%',
      },
      {
        title: '',
        dataIndex: 'desc',
        width: '40%',
        render: (text, cat) => (
          <Space>
            <Button onClick={() => onEdit(cat)}>Edit</Button>
            <Button onClick={() => onDelete(cat)}>Delete</Button>
          </Space>
        ),
      },
    ],
    dataSource: values,
    rowKey: 'value',
    pagination: false,
  }

  return (
    <Row>
      <Col span={24}>
        <Button onClick={onAdd} type="primary" style={{ marginBottom: '8px' }}>New</Button>
        <Table {...editableTableConfig} />
      </Col>
    </Row>
  )
}

export default RiskContextPhaseEditor
