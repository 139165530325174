import { ReactElement, useCallback, useMemo, useState } from 'react'
import _partition from 'lodash/partition'
import {
  DndContext,
  DragStartEvent,
  UniqueIdentifier,
  DragEndEvent,
} from '@dnd-kit/core'

import { OptionData } from '@vms/vmspro3-core/dist/nextgen/Option'
import { CriterionData, RatingScaleConfig } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { Rating as IRating, RatingVector } from '@vms/vmspro3-core/dist/types'

import { RatingNode } from './RatingNode'
import { RatingNodeLayout } from './RatingNodeLayout'
import { RatingScaleOverlay } from './RatingScaleOverlay'
import { RatingSubject } from './RatingSubject'

import { positionToRatingVector, useRatingDndContextConfig } from './utils'
import style from './Rating.module.css'

export type RatingProps = {
  subjects: (CriterionData | OptionData)[],
  ratingBySubjectId: Record<string, IRating>,
  ratingScaleConfig: RatingScaleConfig,
  hideRating: boolean,
  onUpdateRating: (subjectId: string, ratingVector: RatingVector, abstain?: boolean) => void,
  ratingDetailPanel?: ReactElement,
  extra?: ReactElement,
}
export const Rating = ({
  subjects,
  ratingBySubjectId,
  ratingScaleConfig,
  hideRating,
  onUpdateRating,
  ratingDetailPanel,
  extra,
}: RatingProps): ReactElement => {
  const subjectFontSize = useMemo(
    () => {
      const abbrevs = subjects.map(subject => subject.abbrev.length)
      const maxLength = Math.max(...abbrevs)

      if(maxLength >= 12) return '11px'
      if(maxLength >= 10) return '14px'
      return '16px'
    },
    [subjects]
  )

  const [zIndexOrder, setZIndexOrder] = useState<UniqueIdentifier[]>(() => [])
  const renderRatingSubject = useCallback(
    (subject: CriterionData | OptionData) => (
      <RatingSubject
        key={subject.id}
        zIndex={zIndexOrder.indexOf(subject.id) + 1}
        subject={subject}
        rating={ratingBySubjectId[subject.id]}
        fontSize={subjectFontSize}
        maxRating={ratingScaleConfig.maxRating}
        minRating={ratingScaleConfig.minRating}
        hideRating={hideRating}
      />
    ),
    [
      zIndexOrder,
      ratingBySubjectId,
      ratingScaleConfig.maxRating,
      ratingScaleConfig.minRating,
      hideRating,
      subjectFontSize,
    ]
  )

  const ratables = useMemo(
    () => {
      const [withRating, unrated] = _partition(subjects, subject => ratingBySubjectId[subject.id]?.ratingVector)
      const [abstained, rated] = _partition(withRating, subject => ratingBySubjectId[subject.id].abstain)

      return {
        unrated,
        abstained,
        rated,
      }
    },
    [subjects, ratingBySubjectId]
  )

  const ratingDndConfig = useRatingDndContextConfig({
    onDragStart: useCallback(
      (event: DragStartEvent) => {
        setZIndexOrder(state => {
          const oldIdx = state.indexOf(event.active.id)
          if(oldIdx > -1) state.splice(oldIdx, 1)
          return state.concat(event.active.id)
        })
      },
      []
    ),
    onDragEnd: useCallback(
      (event: DragEndEvent) => {
        if(typeof event.active.id === 'string') {
          const range = { min: ratingScaleConfig.minRating, max: ratingScaleConfig.maxRating }

          const activeRect = event.active.rect.current.translated
          const overRect = event.over?.rect
          const ratingVector = positionToRatingVector(range, activeRect, overRect)

          const abstain = event.over?.id === 'abstainCanvas'
          onUpdateRating(event.active.id, ratingVector, abstain)
        }
      },
      [
        onUpdateRating,
        ratingScaleConfig.minRating,
        ratingScaleConfig.maxRating,
      ]
    ),
  })

  return (
    <div className={style.rating}>
      <DndContext {...ratingDndConfig}>
        <div className={style.ratingCanvasColumn}>
          <RatingNodeLayout
            abstainLabel={ratingScaleConfig.abstainLabel}
            maxRatingLabel={ratingScaleConfig.maxRatingLabel}
            minRatingLabel={ratingScaleConfig.minRatingLabel}
            abstainNode={(
              <RatingNode nodeId="abstainCanvas">
                {ratables.abstained.map(renderRatingSubject)}
              </RatingNode>
            )}
            ratingNode={(
              <RatingNode nodeId="ratingCanvas">
                {ratables.rated.map(renderRatingSubject)}
                <RatingScaleOverlay
                  maxRating={ratingScaleConfig.maxRating}
                  minRating={ratingScaleConfig.minRating}
                  ratingScale={ratingScaleConfig.ratingScale}
                />
              </RatingNode>
            )}
          />
        </div>
        <div className={style.unratedPoolColumn}>
          <div className={style.ratingDetailPanelWrapper}>
            <div className={style.ratingDetailPanelInner}>
              {ratingDetailPanel}
            </div>
          </div>
          <div className={style.unratedPool}>
            {ratables.unrated.map(renderRatingSubject)}
          </div>
          <div className={style.extra}>
            {extra}
          </div>
        </div>
      </DndContext>
    </div>
  )
}
