import { CSSProperties, useCallback, useState } from 'react'
import { Button, Card, Form, Input } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Link, URLSearchParamsInit, useNavigate, useSearchParams } from 'react-router-dom'

import PasswordField from '../../common/PasswordField'

import Server from '../../../server/VMSProServerAdapter'
import { createId } from '@vms/vmspro3-core/dist/idUtils'
import { getEmailFieldProps } from '../../../utils/getEmailFieldProps'
import { useInitialFocusRef } from '../../../hooks/useInitialFocusRef'

const unknownErrorMessage = 'An unknown error occurred. Please contact support'

type RegistrationStep = 'sign-up' | 'confirm' | 'resend-code'
interface RegistrationFormProps {
  onNextStep: (registrationStep: RegistrationStep, email?: string) => void,
  email?: string,
}

interface SignUpFormFields {
  email: string,
  newPassword: string,
}
function SignUpForm({ email, onNextStep }: RegistrationFormProps) {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm<SignUpFormFields>()

  const handleSignUp = useCallback(
    async () => {
      setLoading(true)
      try {
        const fieldsValues = await form.validateFields()
        await Server.auth.signUp({
          username: fieldsValues.email,
          password: fieldsValues.newPassword,
          attributes: {
            preferred_username: createId(),
          },
        })
        onNextStep('confirm', fieldsValues.email)
      } catch(error: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
        switch(error.code) {
          case 'UsernameExistsException': {
            form.setFields([{ name: 'email', errors: [error.message] }])
            break
          }
          default: {
            console.error(error)
            form.setFields([{ name: 'email', errors: [unknownErrorMessage] }])
          }
        }
        setLoading(false)
      }
    },
    [form, onNextStep]
  )

  return (
    <div style={style.formLayout}>
      <div style={style.formHeading}>
        <h2>Sign Up</h2>
      </div>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
      >
        <Form.Item {...getEmailFieldProps()} initialValue={email}>
          <Input
            ref={useInitialFocusRef()}
            type="email"
            placeholder="email@domain.com"
            prefix={<UserOutlined style={style.inputPrefix} />}
          />
        </Form.Item>
        <PasswordField.SetNewPassword showSuggestion />
        <div style={style.formControls}>
          <Button
            type="primary"
            loading={loading}
            onClick={handleSignUp}
          >
            Sign Up
          </Button>
          <Button
            type="link"
            onClick={() => onNextStep('confirm', email)}
          >
            <i>Already have a code?</i>
          </Button>
        </div>
      </Form>
      <i>Already an OptionLab user? <Link to="/signin">Sign in.</Link></i>
    </div>
  )
}

interface ConfirmSignUpFormFields {
  email: string,
  confirmationCode: string,
  user: {
    fullName: string,
    shortName: string,
    initials: string,
    phone?: string,
  }
}
function ConfirmSignUpForm({ email, onNextStep }: RegistrationFormProps) {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm<ConfirmSignUpFormFields>()

  const navigate = useNavigate()
  const handleConfirmSignUp = useCallback(
    async () => {
      setLoading(true)
      try {
        const fieldsValues = await form.validateFields()
        await Server.auth.confirmSignUp(fieldsValues.email, fieldsValues.confirmationCode, {
          clientMetadata: {
            user: JSON.stringify({
              email: fieldsValues.email,
              fullName: fieldsValues.user.fullName,
              shortName: fieldsValues.user.shortName,
              initials: fieldsValues.user.initials,
              phone: fieldsValues.user.phone,
            }),
          },
        })
        navigate(`/signin?email=${encodeURIComponent(fieldsValues.email)}`)
      } catch(error: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
        switch(error.code) {
          case 'CodeMismatchException': {
            form.setFields([{
              name: 'confirmationCode',
              errors: ['The confirmation code you provided does not match'],
            }])
            break
          }
          case 'ExpiredCodeException': {
            form.setFields([{
              name: 'confirmationCode',
              errors: ['The confirmation code you entered has expired. Please request a new code.'],
            }])
            break
          }
          case 'UserNotFoundException': {
            form.setFields([{
              name: 'email',
              errors: ['An account with this email address was not found.'],
            }])
            break
          }
          case 'NotAuthorizedException': {
            if(error.message.includes('CONFIRMED')) {
              form.setFields([{
                name: 'email',
                errors: ['This user is already confirmed, please sign in.'],
              }])
            } else {
              console.error(error)
              form.setFields([{ name: 'confirmationCode', errors: [unknownErrorMessage] }])
            }
            break
          }
          default: {
            console.error(error)
            form.setFields([{ name: 'confirmationCode', errors: [unknownErrorMessage] }])
          }
        }
        setLoading(false)
      }
    },
    [form, navigate]
  )

  return (
    <div style={style.formLayout}>
      <div style={style.formHeading}>
        <h2>Complete Registration</h2>
      </div>
      <Form
        form={form}
        layout="vertical"
        requiredMark="optional"
      >
        <h3>Check your email for a confirmation code:</h3>
        <Form.Item {...getEmailFieldProps()} initialValue={email}>
          <Input
            ref={useInitialFocusRef()}
            type="email"
            placeholder="email@domain.com"
            prefix={<UserOutlined style={style.inputPrefix} />}
          />
        </Form.Item>
        <Form.Item
          extra={
            <a onClick={() => onNextStep('resend-code', email)}>
              <i>Request new confirmation code</i>
            </a>
          }
          label="Confirmation Code"
          name="confirmationCode"
          normalize={code => code.trim()}
          rules={[
            {
              required: true,
              message: 'Please enter the verification code',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined style={style.inputPrefix} />}
            inputMode="numeric"
            placeholder="confirmation code"
            autoComplete="one-time-code"
          />
        </Form.Item>
        <h3>User Details:</h3>
        <Form.Item
          label="Full Name"
          name={['user', 'fullName']}
          rules={[{ required: true, whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Short Name"
          name={['user', 'shortName']}
          rules={[{ required: true, whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Initials"
          name={['user', 'initials']}
          rules={[{ required: true, whitespace: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Phone"
          name={['user', 'phone']}
        >
          <Input />
        </Form.Item>
        <div style={style.formControls}>
          <Button
            type="primary"
            onClick={handleConfirmSignUp}
            loading={loading}
          >
            Complete Registration
          </Button>
          <Button
            onClick={() => onNextStep('sign-up', email)}
          >Cancel</Button>
        </div>
      </Form>
    </div>
  )
}

interface ResendConfirmationCodeFormFields {
  email: string,
}
function ResendConfirmationCodeForm({ email, onNextStep }: RegistrationFormProps) {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm<ResendConfirmationCodeFormFields>()

  const resendConfirmationCode = useCallback(
    async () => {
      setLoading(true)
      try {
        const fieldsValues = await form.validateFields()
        await Server.auth.resendSignUp(fieldsValues.email)
        onNextStep('confirm', fieldsValues.email)
      } catch(error: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
        if(error.code === 'InvalidParameterException' && error.message.includes('confirmed')) {
          form.setFields([{
            name: 'email',
            errors: ['This user is already confirmed, please sign in.'],
          }])
        } else if(error.code === 'UserNotFoundException') {
          form.setFields([{
            name: 'email',
            errors: ['An account with this email address was not found.'],
          }])
        } else {
          form.setFields([{ name: 'email', errors: [unknownErrorMessage] }])
        }

        setLoading(false)
      }
    },
    [form, onNextStep]
  )

  return (
    <div style={style.formLayout}>
      <h2>Resend confirmation code</h2>
      <Form layout="vertical" requiredMark="optional" form={form}>
        <Form.Item
          label="Email"
          name="email"
          initialValue={email}
          rules={[{ required: true, whitespace: true }]}
        >
          <Input
            ref={useInitialFocusRef()}
            type="email"
            placeholder="email@domain.com"
            prefix={<UserOutlined style={style.inputPrefix} />}
          />
        </Form.Item>
      </Form>
      <div style={style.formControls}>
        <Button
          type="primary"
          onClick={resendConfirmationCode}
          loading={loading}
        >
          Resend
        </Button>
        <Button onClick={() => onNextStep('confirm', email)}>Cancel</Button>
      </div>
    </div>
  )
}

const registrationFormByStep: Record<RegistrationStep, React.VoidFunctionComponent<RegistrationFormProps>> = {
  'sign-up': SignUpForm,
  'confirm': ConfirmSignUpForm,
  'resend-code': ResendConfirmationCodeForm,
}

export function SignUpPage() {
  const [searchParams, setSearchParams] = useSearchParams({ step: 'sign-up' })
  const step = searchParams.get('step') as RegistrationStep
  const email = searchParams.get('email') ?? undefined

  const onNextStep = useCallback(
    (step: RegistrationStep, email?: string) => {
      const params: URLSearchParamsInit = {
        step,
      }
      if(email) params.email = email

      setSearchParams(params)
    },
    [setSearchParams]
  )

  const RegistrationForm = registrationFormByStep[step] ?? null

  return (
    <div style={style.container}>
      <Card style={style.card}>
        {RegistrationForm && <RegistrationForm email={email} onNextStep={onNextStep} />}
      </Card>
    </div>
  )
}

const style: Record<string, CSSProperties> = {
  container: {
    padding: '60px 24px',
  },
  card: {
    maxWidth: '400px',
    margin: '0 auto',
  },
  inputPrefix: {
    color: 'rgba(0,0,0,.25)',
  },
  formLayout: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  formHeading: {
    textAlign: 'center',
  },
  formControls: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
}
