import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { joinAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'

import { LoadingStatus } from '../../../utils/appConsts'
import useEffectiveRiskContext from './useEffectiveRiskContext'
import { fetchRiskEntity, fetchRiskEntityChildren } from '../../../redux/actions'
import { useAccount } from '../../../context'

/**
 * Custom hook that returns the specified risk entity.
 *
 * @param {string} entityId
 * @returns {Object} - risk entity
 */
function _useRiskEntity(entityId) {
  const dispatch = useDispatch()

  const entity = useSelector(state => state.riskEntities.byId[entityId])
  const entityLoadingStatus = entity?.loadingStatus ?? LoadingStatus.NotLoaded

  useEffect(
    () => {
      if(entityLoadingStatus === LoadingStatus.NotLoaded) {
        dispatch(fetchRiskEntity(entityId))
      }
    },
    [dispatch, entityLoadingStatus, entityId]
  )

  return entity
}

function useRiskEntityChildren(entityId, { loadChildren }) {
  const dispatch = useDispatch()

  const entity = _useRiskEntity(entityId)
  const childAncestry = entity?.loadingStatus === LoadingStatus.Loaded &&
    joinAncestry(entity.ancestry, entityId)

  const children = useSelector(
    useMemo(
      () => createSelector(
        _state => _state.riskEntities.byAncestry[childAncestry],
        children => children?.slice().sort((a, b) => b.created - a.created)
      ),
      [childAncestry]
    ),
  )

  useEffect(
    () => {
      if(!children && loadChildren && childAncestry) {
        dispatch(fetchRiskEntityChildren(childAncestry))
      }
    },
    [dispatch, loadChildren, children, childAncestry]
  )

  return children
}

/**
 * Custom hook that Handles the loading of a risk entity, and optionally its children.  Also
 * provides the risk context and effective risk context.
 */
function useRiskEntity(entityId, { loadChildren } = {}) {
  const { accountId } = useAccount()

  const entity = _useRiskEntity(entityId)
  const entityLoadingStatus = entity?.loadingStatus ?? LoadingStatus.NotLoaded
  const riskContext = entity?.riskContext

  const effectiveRiskContext = useEffectiveRiskContext(entityId)
  const children = useRiskEntityChildren(entityId, { loadChildren })

  // TODO: should also consider including resource policies?

  return {
    accountId,
    entity,
    entityLoadingStatus,
    riskContext,
    effectiveRiskContext,
    children,
  }
}

export { useEffectiveRiskContext, useRiskEntityChildren }
export default useRiskEntity
