import { CellProps } from 'react-table'
import { CheckOutlined } from '@ant-design/icons'

import { Color } from '@vms/vmspro3-core/dist/systemConsts'

export function CheckmarkCellRenderer<
  T extends Record<string, unknown>,
  P extends CellProps<T, boolean>
>({ value }: P) {
  if(!value) return null

  return <CheckOutlined style={{ color: Color.SUCCESS }} />
}
