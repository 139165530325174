import React from 'react'
import { Link } from 'react-router-dom'
import SearchOutlined from '@ant-design/icons/SearchOutlined'
import systemConsts from '@vms/vmspro3-core/dist/systemConsts'
import { DurationUnit } from '@vms/vmspro3-core/dist/utils/qty'
import { Button, Input } from 'antd'

import { formatCost, formatDuration } from '../../../utils/formatUtils'
import ProjectDateDisplay from '../common/ProjectDateDisplay'

const { EntityType } = systemConsts

/* eslint-disable class-methods-use-this */
/**
 * Risk Portfolio Entity Columns Class is designed to take in the risk project configuration and set the
 * necessary filters and columns for the riskPortfolioEntityTable.
 */
class RiskPortfolioEntityColumns {

  /**
   * Constructor to instantiate the columns and filters
   *
   * @param {Object} props
   *    @param {Object} props.filters - saved filter settings to be dispersed to the columns
   */
  constructor({ filters, accountCommonId }) {
    this.filters = filters || {}
    this.searchInput = {}
    this.searchText = {}
    this.accountCommonId = accountCommonId
    this.setProjectColumns()
  }

  /**
   * Handles a user keyed search input
   *
   * @param {Object} selectedKeys - selectedKeys for searching
   * @param {Function} confirm - confirm function to trigger the column filter
   */
  handleSearch(selectedKeys, confirm) {
    confirm()
    this.searchText = selectedKeys[0]
  }

  /**
   * Get the properties necessary for a custom search dropdown for a specified column in the table
   *
   * @param {String} dataIndex - the column dataIndex to filter on
   *
   * @returns {Object} the properties for the custom search dropdown for the column
   */
  getColumnSearchProps(dataIndex) {
    return ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => this.searchInput = node}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined alt="Search" style={{ color: filtered ? '#1890ff' : undefined }} />,
      filteredValue: this.filters.name || '',
      onFilter: (value, record) =>
        record[dataIndex] && record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if(visible) {
          setTimeout(() => this.searchInput.select())
        }
      },
      render: (text, { id, entityType }) => {
        switch(entityType) {
          case 'PORTFOLIO': return <Link to={`/${this.accountCommonId}/port/${id}`}>{text}</Link>
          case 'PROGRAM': return <Link to={`/${this.accountCommonId}/prog/${id}`}>{text}</Link>
          case 'PROJECT': return <Link to={`/${this.accountCommonId}/proj/${id}`}>{text}</Link>
          default: return <Link to={`/${this.accountCommonId}/entity/${id}`}>{text}</Link>
        }
      },
    })
  }

  /**
   * Construct an object placeholder for the basic project columns and append their respective
   * filters (where applicable)
   */
  setProjectColumns() {
    this.projectColumns = {
      commonId: {
        disabled: true, // disabled is used in the column configuration model to prevent unchecking the column
        title: 'ID',
        dataIndex: 'commonId',
        key: 'commonId',
        sortType: 'string',
        align: 'left',
        fixed: 'left',
        width: 80,
      },
      type: {
        disabled: true,
        title: 'Type',
        dataIndex: 'entityType',
        defaultSortOrder: 'ascend',
        key: 'entityType',
        sortType: 'string',
        align: 'left',
        fixed: 'left',
        minWidth: 130,
        render: text => {
          switch(text) {
            case EntityType.PORTFOLIO: return 'Subportfolio'
            case EntityType.PROGRAM: return 'Program'
            case EntityType.PROJECT: return 'Project'
            default: return text
          }
        },
      },
      createdDate: {
        title: 'Created',
        dataIndex: 'created',
        defaultSortOrder: 'ascend',
        key: 'created',
        sortType: 'number',
        align: 'right',
        minWidth: 130,
        render: t => <ProjectDateDisplay time={t} />,
      },
      name: {
        dataIndex: 'name',
        disabled: true, // disabled is used in the column configuration model to prevent unchecking the column
        fixed: 'left',
        key: 'name',
        sortType: 'string',
        title: 'Name',
        width: 250,
        ...this.getColumnSearchProps('name'),
      },
      projectCost: {
        title: 'Cost',
        dataIndex: 'cost',
        key: 'cost',
        sortType: 'cost',
        align: 'right',
        minWidth: 130,
        render: cost => formatCost(cost, false, 0),
      },
      projectDuration: {
        title: 'Duration',
        dataIndex: 'time',
        key: 'time',
        sortType: 'time',
        align: 'right',
        minWidth: 130,
        render: time => formatDuration(time, DurationUnit.Months, ''),
      },
    }
  }

  /**
   * Get the superset of columns
   *
   * @returns {Object} the Risk Inventory columns
   */
  getColumns() {
    return [
      this.projectColumns.type,
      this.projectColumns.commonId,
      this.projectColumns.name,
      this.projectColumns.projectCost,
      this.projectColumns.projectDuration,
      this.projectColumns.createdDate,
    ]
  }
}

export default RiskPortfolioEntityColumns
