import React from 'react'
import { Empty } from 'antd'

import { Html } from '@vms/vmspro3-core/dist/types'

import { RenderHtmlObject } from '../../../common/RenderHtmlObject'

export type CriterionDescriptionProps = {
  name: string,
  description?: Html,
}

const emptyDescription = (
  <p>This criterion doesn't have a description.  You can click "Edit" to provide
  a description, or rename this criterion, which will help users understand how
  to use this criterion to make better decisions.</p>
)

export const CriterionDescription: React.VFC<CriterionDescriptionProps> = ({ name, description }) => {
  const hasDescription = /\S/.test(description?.value ?? '')

  return (
    <>
      <h3>{name}</h3>
      {hasDescription
        ? <RenderHtmlObject htmlObject={description} />
        : <Empty description={emptyDescription} />
      }
    </>
  )
}
