import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import _keyBy from 'lodash/keyBy'
import { produce } from 'immer'
import { WarningOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Button, Tree, Row, Col, Empty, Space, Modal } from 'antd'

import { joinAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { actions } from '@vms/vmspro3-core/dist'

import RiskCategoryModal from '../modals/RiskCategoryModal'
import useRiskEntity from '../hooks/useRiskEntity'
import { useShowModal } from '../RiskModalContext'
import { Table } from '../controls'
import { useAccount } from '../../../context'

/**
 * Convert flat enumerated category values into a tree
 * suitable for use with Ant Design's "Tree" component.
 *
 * @example
 *
 * const catTypes = [
 *   { title: 'Top-Level Category', key: 'a', ancestry: '/' },
 *   { title: 'Subcategory', key: 'b', ancestry: '/a' },
 *   { title: 'Sub-subcategory', key: 'c', ancestry: '/a/b' }
 * ]
 * const cats = [
 *   { title: 'Categories', key: '', ancestry: '/' },
 *   ...catType.values,
 * ]
 * categoriesToTree(cats) // returns:
 * // [
 * //   {
 *
 * @param {object[]} cats - The "values" property of the
 *  "category" type in a risk context, along with an
 *  artifical root category (key = '', ancestry = '/').
 * @returns {object[]} - Returns an array with a single
 *   element, the artificial root, with a children array
 *   wih all top-level categories.  Each of these children
 *   in turn contain children arrays of their children.
 */
const categoriesToTree = cats => {
  const { elts, byKey } = cats.reduce((a, x) => {
    if(x.isDeleted) return a
    const elt = { ...x, key: x.value, children: [] }
    a.elts.push(elt)
    a.byKey[elt.value] = elt
    return a
  }, { elts: [], byKey: {} })
  return (elts
    .map(elt => {
      elt.title = elt.label.long
      if(elt.key === '') return elt  // root category
      const parent = byKey[elt.ancestry.split('/').pop()]
      if(parent) parent.children.push(elt)
      return elt
    })
    .filter(elt => elt.key === '')
  )
}

const RiskContextCategoryEditor = ({ entityId }) => {
  const dispatch = useDispatch()
  const { accountCommonId } = useAccount()

  const { entity, riskContext, effectiveRiskContext } = useRiskEntity(entityId)

  const [selectedValue, setSelectedValue] = useState('')
  const showModal = useShowModal()

  if(entity.ancestry !== '/') {
    // TODO: eventually, you will be able to "turn off" categories or phases you don't want,
    // and define custom fields at the project level.  For now, we're just directing them to
    // the portfolio level.
    const path = `/${accountCommonId}/port/config?tab=categories`
    return (
      <Empty
        description={
          <span>
            Please edit categories at the <Link to={path}>portfolio level</Link>.
          </span>
        }
      />
    )
  }

  const categoryType = effectiveRiskContext.types.category
  if(!categoryType) throw new Error('missing "category" type in risk context')

  const impliedRootCat = { label: { long: 'Categories', short: 'Cats' }, value: '', ancestry: '/' }
  const { values } = categoryType
  const cats = [...values, impliedRootCat]
  const catByValue = _keyBy(cats, 'value')
  const selectedCat = catByValue[selectedValue]
  const childAncestry = joinAncestry(selectedCat.ancestry, selectedCat.value)
  const childrenOfSelected = cats.filter(v => v.value !== '' && v.ancestry === childAncestry && !v.isDeleted)
  const catTreeConfig = {
    treeData: categoriesToTree(cats),
    defaultExpandAll: true,
    multiple: false,  // this is the default, just want to be explicit
    selectedKeys: [selectedValue],
    onSelect: keys => setSelectedValue(keys[0] || ''),
  }

  const modalPayload = {
    entityId,
    entityType: entity.entityType,
    riskContext,
    categoryAncestry: childAncestry,
    entityAncestry: entity.ancestry,  // needed for pubsub
    categories: cats,
  }

  const onAdd = () => showModal(RiskCategoryModal.id, modalPayload)

  const onEdit = cat => showModal(RiskCategoryModal.id, { ...modalPayload, cat })

  const onDelete = categoryToDelete => {
    Modal.confirm({
      title: `Are you sure you want to delete "${categoryToDelete.label.long}"?`,
      icon: <WarningOutlined />,
      content: 'This will also delete all descendent cateogries.',
      onOk() {
        // note that we have to send the entire risk context to update
        const payload = {
          ...riskContext,
          types: produce(riskContext.types, types => {
            const { values } = types.category
            const val = values.find(v => v.value === categoryToDelete.value)
            if(!val) return
            if(val) val.isDeleted = true
            // delete any descendents
            const childAncestry = joinAncestry(val.ancestry, val.value)
            values.filter(v => v.ancestry.startsWith(childAncestry)).forEach(v => v.isDeleted = true)
          }),
        }
        const meta = {
          entityId,
          entityType: entity.entityType,
          ancestry: entity.ancestry,
        }
        dispatch(actions.riskContext.update(payload, meta))
      },
    })
  }

  const tableConfig = {
    columns: [
      {
        title: 'Name',
        dataIndex: ['label', 'long'],
        width: '25%',
      },
      {
        title: 'Abbrev.',
        dataIndex: ['label', 'short'],
        width: '15%',
      },
      {
        title: 'Description',
        dataIndex: 'desc',
        width: '40%',
      },
      {
        title: '',
        dataIndex: 'desc',
        width: '40%',
        render: (text, cat) => (
          <Space>
            <Button onClick={() => onEdit(cat)}>Edit</Button>
            <Button onClick={() => onDelete(cat)}>Delete</Button>
          </Space>
        ),
      },
    ],
    dataSource: childrenOfSelected,
    rowKey: 'value',
    pagination: false,
  }

  return (
    <Row>
      <Col span={6}>
        <Tree {...catTreeConfig} />
      </Col>
      <Col span={12}>
        <Button onClick={onAdd} type="primary" style={{ marginBottom: '8px' }}>New</Button>
        <Table {...tableConfig} />
      </Col>
    </Row>
  )
}

export default RiskContextCategoryEditor
