/**
 * formatCostObject formats a cost object for display.
 *
 * @param {Object} costObject - The cost object to format.
 * @param {number} costObject.value - The value of the cost object.
 * @param {string} costObject.currency - The currency of the cost object.
 * @param {boolean} [allowNull = false] - If `true` formatCostObject will return an empty string for a `null`
 *    value. If `false` formatCostObject will return '0' for a `null` value.
 * @param {number} decimalPlaces - The number of decimal places to show.
 * @param {string} abbrev - The amount to abbreviate the formatted value.
 * @returns {string} - A formatted cost string.
 */
export const formatCostObject = (
  costObject,
  allowNull = false,
  decimalPlaces = 2,
  abbrev = '',
) => {
  const getAbbrDivisor = abbrev => {
    switch(abbrev) {
      case 'T': return 1000000000000
      case 'B': return 1000000000
      case 'M': return 1000000
      case 'K': return 1000
      default: return 1
    }
  }
  const { unit, value } = costObject || {}
  if(typeof value !== 'number' && !value) return ''
  const abbrNum = value && (value / getAbbrDivisor(abbrev.toUpperCase()))
  const formattedNumber = formatNumValue(abbrNum, allowNull, decimalPlaces)

  if(!unit || !formattedNumber) return ''
  const { symbol } = CostUnitMetadata[unit]
  const prefix = symbol || unit
  return prefix + formattedNumber + abbrev
}
