import { ReactElement, useCallback, useMemo } from 'react'

import { updateRatingNotes } from '@vms/vmspro3-core/dist/actions/decision'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'
import { identifyRatingNotes } from '@vms/vmspro3-core/dist/utils/ratingNotes'
import { RatingSubjectType } from '@vms/vmspro3-core/dist/types'

import { DebounceTextArea } from '../../../common/RatingInterface/DebounceTextArea'

import { useDecisionChildAncestry } from '../../../../redux/hooks'
import { useAppDispatch, useAppSelector } from '../../../../redux'

interface ContextRatingNotesProps {
  decisionId: string,
  participationSessionId: string,
  participantId: string,
  contextId: string,
  contextLabel: string,
  subjectType: RatingSubjectType,
}
export function ContextRatingNotes({
  decisionId,
  participationSessionId,
  participantId,
  contextId,
  contextLabel,
  subjectType,
}: ContextRatingNotesProps): ReactElement {
  const ancestry = useDecisionChildAncestry(decisionId)
  const dispatch = useAppDispatch()
  const onChangeRatingNotesValue = useCallback(
    (notes: string) => dispatch(updateRatingNotes(ancestry, {
      participationSessionId,
      participantId,
      contextType: 'Criterion',
      contextId,
      subjectType,
      notes: createHtmlObject(notes || null),
    })),
    [dispatch, ancestry, participationSessionId, participantId, contextId, subjectType]
  )

  const ratingNotesId = useMemo(
    () => identifyRatingNotes({
      participationSessionId,
      participantId,
      contextId,
      subjectType,
    }),
    [participationSessionId, participantId, contextId, subjectType]
  )
  const ratingNotesValue = useAppSelector(
    state => ratingNotesId && state.ratingNotes.byId[ratingNotesId]?.notes?.value
  )

  return (
    <DebounceTextArea
      label={contextLabel}
      value={ratingNotesValue}
      onChange={onChangeRatingNotesValue}
    />
  )
}
