import { useCallback, ReactElement } from 'react'
import { Modal, Spin } from 'antd'

import { deleteDecisionFolder } from '@vms/vmspro3-core/dist/actions/decision'

import { useAppDispatch } from '../../../redux'
import { useAccount } from '../../../context'
import {
  useDecisionFolder,
  useDecisionFolderHasChildren,
  useLoadDecisionFolderChildren,
} from '../../../redux/hooks'

export type DecisionFolderDeleteModalProps = {
  decisionFolderId: string,
  hide: VoidFunction,
}
export function DecisionFolderDeleteModal({
  decisionFolderId,
  hide,
}: DecisionFolderDeleteModalProps): ReactElement {
  const { accountId } = useAccount()
  const decisionFolder = useDecisionFolder(decisionFolderId, true)
  const childrenLoading = useLoadDecisionFolderChildren(accountId, decisionFolderId)
  const decisionFolderHasChildren = useDecisionFolderHasChildren(decisionFolderId)

  const dispatch = useAppDispatch()
  const onOk = useCallback(
    () => {
      if(decisionFolderHasChildren) return
      dispatch(deleteDecisionFolder(decisionFolderId))
      hide()
    },
    [decisionFolderHasChildren, dispatch, decisionFolderId, hide]
  )

  return (
    <Modal
      visible
      onOk={onOk}
      onCancel={hide}
      okButtonProps={{
        disabled: childrenLoading || decisionFolderHasChildren,
      }}
    >
      {decisionFolder && !childrenLoading
        ? (
          <>
            <h3>Deleting {decisionFolder.name}</h3>
            <p>
              {decisionFolderHasChildren
                ? 'This folder has children and cannot be deleted. You must delete the contents ' +
                  'of this folder before you can delete it.'
                : `Are you sure you want to delete ${decisionFolder.name}?`
              }
            </p>
          </>
        )
        : <Spin />
      }
    </Modal>
  )
}
