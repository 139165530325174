import { combineReducers } from 'redux'

import { accountReducer } from './accountReducer'
import { authReducer } from './authReducer'
import { criteriaReducer } from './criteriaReducer'
import { decisionFoldersReducer } from './decisionFoldersReducer'
import { decisionsReducer } from './decisionsReducer'
import { optionsReducer } from './optionsReducer'
import { participantsReducer } from './participantsReducer'
import { policiesReducer } from './policiesReducer'
import { ratingNotesReducer } from './ratingNotesReducer'
import { ratingsReducer } from './ratingsReducer'
import { riskEntitiesReducer } from './riskEntitiesReducer'
import { userReducer } from './userReducer'
import { usersReducer } from './usersReducer'


export const rootReducer = combineReducers({
  account: accountReducer,
  auth: authReducer,
  criteria: criteriaReducer,
  decisions: decisionsReducer,
  decisionFolders: decisionFoldersReducer,
  options: optionsReducer,
  participants: participantsReducer,
  policies: policiesReducer,
  ratings: ratingsReducer,
  ratingNotes: ratingNotesReducer,
  riskEntities: riskEntitiesReducer,
  user: userReducer,
  users: usersReducer,
})

export type RootState = ReturnType<typeof rootReducer>
