import { useCallback, useMemo, useState } from 'react'
import { ExperimentOutlined, BarChartOutlined } from '@ant-design/icons'
import { Button, Drawer, Form, Select, Switch } from 'antd'

import { updateDecision as updateDecisionAC, UpdateDecisionAction } from '@vms/vmspro3-core/dist/actions/decision'
import { valueExpressionToString } from '@vms/vmspro3-core/dist/valuemetrics/valuemetrics'
import { Expression as ValueFormulaExpression } from '@vms/vmspro3-core/dist/valuemetrics/valueFormula'
import { RatingToPrioritizationAlgorithm } from '@vms/vmspro3-core/dist/nextgen/prioritization/quickPrioritization'
import { Option } from '@vms/vmspro3-core/dist/nextgen/Option'

import { SensitivityAnalysis } from './SensitivityAnalysis'
import { PerformanceChart } from './PerformanceChart'
import { ValuemetricsChart } from './ValuemetricsChart'
import { ValuemetricsTable } from './ValuemetricsTable'
import { ValueFormulaConfigModal } from './ValueFormulaConfigModal'
import { ValueGraphContainer } from './ValueGraphContainer'

import style from './Valuemetrics.module.css'
import { useAppDispatch } from '../../../../redux'
import { SelectOption } from '../../../../types'
import { useModalState } from '../../../../hooks/useModalState'
import { useDecision } from '../../../../redux/hooks'

const ratingsToPrioritizationAlgorithmSelectOptions: SelectOption[] = [
  { label: 'Recenter And Normalize (default)', value: 'RecenterAndNormalize' },
  { label: 'Normalize', value: 'Normalize' },
]

export function Valuemetrics({ decisionId }: { decisionId: string }) {
  const decision = useDecision(decisionId)

  const dispatch = useAppDispatch()
  const updateDecision = useCallback(
    (payload: UpdateDecisionAction['payload']) => dispatch(updateDecisionAC(decisionId, payload)),
    [decisionId, dispatch]
  )
  const updateRatingsToPrioritizationAlgorithm = useCallback(
    (ratingsToPrioritizationAlgorithm: RatingToPrioritizationAlgorithm) => (
      updateDecision({ ratingsToPrioritizationAlgorithm })
    ),
    [updateDecision]
  )
  const updateBaselineOptionId = useCallback(
    (optionId: string | undefined) => updateDecision({ baselineOptionId: optionId ?? null }),
    [updateDecision]
  )

  const { modal, showModal, hideModal } = useModalState()
  const onConfigureValueFormula = useCallback(
    () => showModal(
      <ValueFormulaConfigModal
        initialValueExpr={decision.valueFunctionExpr}
        // TODO: it's pretty fragile to get the cost/time criteria by their name...what if the user
        // changes it?  but right now there's no other way to distinguish them...but the whole approach
        // to the value formula and intrinsic criteria needs to be revamped, so I'm not going to sweat it
        // right now
        groupCostPri={decision.criteria.all.find(c => c.name === 'Cost')?.pri.local ?? null}
        groupTimePri={decision.criteria.all.find(c => c.name === 'Time')?.pri.local ?? null}
        onOk={(expr: ValueFormulaExpression) => updateDecision({ valueFunctionJson: JSON.stringify(expr) })}
        hideModal={hideModal}
      />
    ),
    [showModal, decision.valueFunctionExpr, decision.criteria.all, hideModal, updateDecision]
  )

  const [optionsSortOrder, setOptionsSortOrder] = useState(() => decision.options.all.map(option => option.id))
  const sortedOptions = useMemo(
    () => optionsSortOrder
      .map(optionId => decision.options.byId(optionId))
      .filter((option): option is Option => Boolean(option)),
    [optionsSortOrder, decision.options]
  )

  const [hasBaseline, setHasBaseline] = useState<boolean>(!!decision.data.baselineOptionId)
  const toggleHasBaseline = useCallback(
    () => {
      // when toggling hasBaseline to false we set the baselineOptionId to
      // undefined. if the value of hasBaseline is being set to false, this is
      // clearing the baselineOptionId. if the value of hasBaseline is being
      // set to true, there is no initial or default baseline so this is harmless.
      updateBaselineOptionId(undefined)
      setHasBaseline(state => !state)
    },
    [updateBaselineOptionId]
  )

  const [valueGraphOptionId, setValueGraphOptionId] = useState<string>()
  const [showSensitivityAnalysis, setShowSensitivityAnalysis] = useState<boolean>(false)

  return (
    <>
      {modal}
      <div className={style.controls}>
        <div>
          <label>Has Baseline:&nbsp;
            <Switch checked={hasBaseline} onChange={toggleHasBaseline} />
          </label>
          <Button
            type="primary"
            icon={<BarChartOutlined />}
            onClick={() => setShowSensitivityAnalysis(true)}
          >
            Sensitivity Analysis
          </Button>
        </div>
        <div>
          <span>
            <b>Current value formula:</b>
          </span>
          <span>
            {valueExpressionToString(decision.valueFunctionExpr)}
          </span>
          <Button
            icon={<ExperimentOutlined />}
            onClick={onConfigureValueFormula}
            type="primary"
          >
            Configure Value Formula
          </Button>
        </div>
      </div>
      <ValuemetricsTable
        decisionId={decisionId}
        hasBaseline={hasBaseline}
        options={decision.options}
        setOptionsSortOrder={setOptionsSortOrder}
        setValueGraphOptionId={setValueGraphOptionId}
        updateBaselineOptionId={updateBaselineOptionId}
      />
      <div className={style.chartRow}>
        <div className={style.chartGroup}>
          <ValuemetricsChart
            options={sortedOptions}
            valueFunctionExpr={decision.valueFunctionExpr}
          />
          <Form.Item label="Ratings-to-prioritization algorithm (advanced)">
            <Select
              onChange={updateRatingsToPrioritizationAlgorithm}
              options={ratingsToPrioritizationAlgorithmSelectOptions}
              value={decision.data.ratingsToPrioritizationAlgorithm ?? 'Normalize'}
            />
          </Form.Item>
        </div>
        <div className={style.chartGroup}>
          <PerformanceChart
            criteria={decision.criteria.all}
            options={sortedOptions}
          />
        </div>
      </div>
      <Drawer
        onClose={() => setValueGraphOptionId(undefined)}
        visible={!!valueGraphOptionId}
        width="100%"
      >
        {valueGraphOptionId &&
          <ValueGraphContainer decisionId={decisionId} optionId={valueGraphOptionId} />
        }
      </Drawer>
      <Drawer
        onClose={() => setShowSensitivityAnalysis(false)}
        visible={showSensitivityAnalysis}
        width="100%"
      >
        <SensitivityAnalysis decisionId={decisionId} />
      </Drawer>
    </>
  )
}
