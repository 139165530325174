import { useEffect, useMemo } from 'react'

import { updateAccountSubscription } from '@vms/vmspro3-core/dist/actions/account/updateSubscription'
import { Invitation, Product } from '@vms/vmspro3-core/dist/types'
import { actions } from '@vms/vmspro3-core/dist'

import config from '../../config.json'
import useAuthz from '../../hooks/useAuthz'
import { fetchPendingInvitationsForAccount } from '../actions'
import { useAppDispatch, useAppSelector } from '../store'
import { useAccount } from '../../context'

const products = config.instance.products as Product[]

export function usePendingInvitationsForAccount(): [
  pendingAccountInvitations: Invitation[] | undefined,
  loading: boolean,
  authorizedToRead: boolean,
] {
  const authz = useAuthz()
  const authorized = useMemo(
    () => authz({ module: 'System', type: actions.invitation.read.toString() }),
    [authz]
  )

  const pendingInvitations = useAppSelector(state => state.account.pendingInvitations)
  const loading = useAppSelector(state => state.account.pendingInvitationsLoadingStatus !== 'Loaded')

  return [pendingInvitations, loading, authorized]
}

export function useFetchPendingInvitationsForAccount() {
  const authz = useAuthz()
  const { accountId } = useAccount()
  const authorized = useMemo(
    () => authz({ module: 'System', type: actions.invitation.read.toString() }),
    [authz]
  )

  const dispatch = useAppDispatch()
  const loadingStatus = useAppSelector(state => state.account.pendingInvitationsLoadingStatus)
  useEffect(
    () => {
      if(authorized && loadingStatus === 'NotLoaded') {
        dispatch(fetchPendingInvitationsForAccount(accountId))
      }
    },
    [dispatch, authorized, loadingStatus, accountId]
  )
}

/**
 * Determines if the current user can update the subscription for the current account.  It does this by seeing if
 * there are any products that the user could update the account subscription with (including the current product).
 * It may seem odd that the current product is included, but there is the case where they are already at the
 * biggest account that will accomodate their current usage, and they have "no where to go", so to speak.  They
 * should still be able to access the subscription controls, and it's up to the UI to explain to them why they
 * don't have anything to choose from.
 */
export function useCanUpdateSubscription() {
  const authz = useAuthz()
  const account = useAppSelector(state => state.account.account)
  return useMemo(
    () => account
      ? products.some(p => authz(updateAccountSubscription(account, p, null)))
      : false,
    [authz, account]
  )
}
