import { ReactElement } from 'react'

import { RatingScaleConfig } from '@vms/vmspro3-core/dist/nextgen/Criterion'

import style from './RatingNodeLayout.module.css'

type RatingNodeLayoutProps = {
  maxRatingLabel: RatingScaleConfig['maxRatingLabel'],
  minRatingLabel: RatingScaleConfig['minRatingLabel'],
  abstainLabel?: RatingScaleConfig['abstainLabel'],
  abstainNode?: ReactElement,
  ratingNode?: ReactElement,
}
export function RatingNodeLayout({
  abstainLabel,
  maxRatingLabel,
  minRatingLabel,
  abstainNode,
  ratingNode,
}: RatingNodeLayoutProps) {
  return (
    <div className={style.ratingNodeLayout}>
      <div className={style.ratingNode}>
        <div className={style.nodeLabels}>
          <span>&lt; {minRatingLabel}</span>
          <span>{maxRatingLabel} &gt;</span>
        </div>
        <div className={style.nodeBorder}>
          {ratingNode}
        </div>
      </div>
      {typeof abstainLabel === 'string' && (
        <div className={style.abstainNode}>
          <div className={style.nodeLabels}>
            <span>{abstainLabel}</span>
          </div>
          <div className={style.nodeBorder}>
            {abstainNode}
          </div>
        </div>
      )}
    </div>
  )
}
