import { useMemo } from 'react'
import _mapValues from 'lodash/mapValues'
import _keyBy from 'lodash/keyBy'

import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { Criteria } from '@vms/vmspro3-core/dist/nextgen/criteria'
import { ParticipationSession } from '@vms/vmspro3-core/dist/nextgen/participationSession'
import { augmentWithStats, NumberWithStats } from '@vms/vmspro3-core/dist/stats/quartiles'

import {
  useCriteria,
  useParticipants,
  useParticipationSession,
  useParticipationSessionRatings,
} from '../../../../redux/hooks'

export type CriteriaPriorityTableData = {
  id: string,
  name: string,
  color: string,
  priorityByParticipantId: {
    [participantId: string]: NumberWithStats,
  },
  groupAvgLocal: number,
  groupAvgGlobal: number,
  subRows?: Array<CriteriaPriorityTableData>
}

function getTableData(
  criteria: Criterion[],
  maximumFractionalDigits: number,
): CriteriaPriorityTableData[] {
  return criteria.map(c => {
    const withStats = augmentWithStats<{ participantId: string, localPri: number }>(
      Object.entries(c.localPriByParticipantId)
        .map(([participantId, localPri]) => ({ participantId, localPri })),
      (datum: { localPri: number }) => datum.localPri,
      maximumFractionalDigits,
      'percent',
    )
    const priWithStats = _keyBy(withStats, 'datum.participantId')
    const priorityByParticipantId = _mapValues(priWithStats, ({ numberWithStats }) => numberWithStats)
    return {
      id: c.id,
      name: c.name,
      color: c.color,
      priorityByParticipantId,
      groupAvgGlobal: c.pri.global ?? 0,
      groupAvgLocal: c.pri.local ?? 0,
      subRows: getTableData(c.children, maximumFractionalDigits),
    }
  })
}

export function useCriteriaPriorityTableData(decisionId: string, maximumFractionDigits: number) {
  const criteriaData = useCriteria(decisionId)
  const psData = useParticipationSession(decisionId, 'CriteriaPrioritization')
  const participantData = useParticipants(decisionId)
  const ratings = useParticipationSessionRatings(decisionId, 'CriteriaPrioritization')
  return useMemo<CriteriaPriorityTableData[]>(() => {
    const criteria = new Criteria(criteriaData)
    // note we don't care about rating notes here, and just pass in an empty array
    const ps = new ParticipationSession(psData, participantData, ratings, [])
    criteria.useParticipationSession(ps)
    return getTableData(criteria.perfRoot.children, maximumFractionDigits)
  }, [criteriaData, psData, participantData, ratings, maximumFractionDigits])
}
