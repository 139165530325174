import { useCallback } from 'react'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getEffectiveRiskContext } from '@vms/vmspro3-core/dist/utils/risk'
import { splitAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'

import { LoadingStatus } from '../../../utils/appConsts'

/**
 * Custom hook that returns an effective risk context for the speficied risk
 * entity.
 *
 * @param {string} entityId
 * @returns {Object} - effective risk context
 */
export default function useEffectiveRiskContext(entityId) {
  const getEffectiveRiskContextSelector = useCallback(
    _state => createSelector(
      state => state.riskEntities.byId,
      byId => {
        const entity = byId[entityId]
        if(entity?.loadingStatus !== LoadingStatus.Loaded) return undefined
        const ancestors = splitAncestry(entity.ancestry).map(entityId => byId[entityId])
        const riskContexts = [...ancestors, entity]
          .map(entity => entity.riskContext)
          .filter(Boolean)  // risks will not have a risk context
        return getEffectiveRiskContext(riskContexts)
      }
    )(_state),
    [entityId]
  )
  return useSelector(getEffectiveRiskContextSelector)
}
