import { useMemo } from 'react'
import { createSelector } from 'reselect'
import { Popover, Tag } from 'antd'

import { RootState, useAppSelector } from '../../../redux'
import { naturalAlphanumericSort } from '../../../utils/sort-utils'

export function UserRolesForTable({ policyIds }: { policyIds: string[] }) {
  const selectRolePolicyNames = useMemo(
    () => createSelector(
      (state: RootState) => state.policies.byId,
      policiesById => policyIds
        .map(policyId => policiesById[policyId]?.name)
        .filter(Boolean)
        .sort(naturalAlphanumericSort)
        .map(label => <div key={label}>{label}</div>)
    ),
    [policyIds]
  )

  const roleNames = useAppSelector(selectRolePolicyNames)

  if(roleNames.length === 0) return null
  if(roleNames.length === 1) return roleNames[0]

  return (
    <Popover title="User Roles" content={roleNames}>
      <Tag color="blue">Multiple...</Tag>
    </Popover>
  )
}
