import { ReactElement, useCallback } from 'react'
import classnames from 'classnames'

import style from './TreeNode.module.css'
import { useTreeContext } from './treeContext'

export interface TreeNodeData<T extends TreeNodeData<T>> {
  id: string,
  name: string,
  parent: T | null,
  children: T[],
}
interface TreeNodeProps<T extends TreeNodeData<T>> {
  node: T,
  renderLabel: (item: T) => string | ReactElement,
  isNodeDisabled?: (item: T) => boolean,
}
export const TreeNode = <T extends TreeNodeData<T>>({
  node,
  renderLabel,
  isNodeDisabled,
}: TreeNodeProps<T>): JSX.Element => {
  const { readOnly, selectedItemId, onSelectItem } = useTreeContext()

  const nodeDisabled = isNodeDisabled instanceof Function && isNodeDisabled(node)

  const itemId = node.id
  const onClick = useCallback(
    () => {
      if(nodeDisabled || !onSelectItem) return
      onSelectItem(itemId)
    },
    [nodeDisabled, onSelectItem, itemId]
  )

  return (
    <div>
      <span
        className={classnames(style.node, {
          [style.selected]: itemId === selectedItemId,
          [style.readOnly]: readOnly || !onClick,
          [style.disabled]: nodeDisabled,
          [style.hasClickHandler]: !!onSelectItem,
        })}
        onClick={onClick}
      >
        {renderLabel(node)}
      </span>
      <div className={style.treeNodeChildren}>
        {node.children.map(childNode =>
          <TreeNode<T>
            key={childNode.id}
            node={childNode}
            renderLabel={renderLabel}
            isNodeDisabled={isNodeDisabled}
          />
        )}
      </div>
    </div>
  )
}
