import { PubSub } from 'aws-amplify'
import { AWSIoTProvider } from '@aws-amplify/pubsub'
import _throttle from 'lodash/throttle'

import config from '../config.json'
import { Auth, API } from '../server/AmplifyProxy'
import { API_NAME } from '../utils/appConsts'

PubSub.addPluggable(new AWSIoTProvider(config.pubsub))

type Observable = ReturnType<typeof PubSub.subscribe>
type Subscription = ReturnType<Observable['subscribe']>

interface CustomErrorMessage {
  header: string,
  body: string,
  details: string,
}
interface ErrorHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (error: any, customMessage?: CustomErrorMessage): void,
}

let errorHandler: ErrorHandler | undefined = undefined
const accountSubscriptions: { [topic: string]: Subscription } = {}

// configure IoT provider pluggable in PubSub class

/**
 * Posts a request to the API to attach the required IoT PubSub IAM
 * policy to the Cognito identity.
 */
async function configure() {
  const { identityId } = await Auth.currentUserCredentials()
  await API.post(API_NAME, `/api/register-pubsub`, { body: { identityId } })
}

/**
 * Adds handler for caught subscription errors.
 *
 * @param {Function} handler - (error: Error, message: string) => void
 */
function registerErrorHandler(handler: ErrorHandler) {
  errorHandler = handler
}

const handleError = _throttle((error, message) => {
  if(typeof errorHandler !== 'function') {
    console.error('error: pubsub error handler not configured properly')
    console.error(error)
  } else {
    errorHandler(error, message)
  }
}, 1000)

function unsubscribe(topic: string, subscriptions: { [topic: string]: Subscription }) {
  if(!subscriptions[topic]) return
  subscriptions[topic].unsubscribe()
  delete subscriptions[topic]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function subscribeToAccountTopic(topic: string, onNextData: (value: any) => void) {
  if(typeof topic !== 'string') {
    throw new Error('only single topic subscriptions are supported at this time')
  }

  accountSubscriptions[topic] = PubSub.subscribe(topic).subscribe({
    next: onNextData,
    error: error => {
      error.topic = topic
      console.log('pub/sub subscription error:', error)
      // Most error types here come back as an error object, but the undefined socket error
      // comes back as a nested error object - so we call error.error
      if(error.error && error.error.errorMessage === 'AMQJS0007E Socket error:undefined.') {
        // TODO: there is a breaking change in amplify since 1.1.40 that appears to have eliminated a way
        // to reconnect after the Mqtt socket is destroyed.
        // There are open tickets for this, with little to no response from AWS development team.
        // https://github.com/aws-amplify/amplify-js/issues/3039 (opened AUG 2019, active JAN 2020)
        // https://github.com/aws-amplify/amplify-js/issues/4459 (opened NOV 2019, no response from AWS dev)
        // Trigger our server disconnect here.
        handleError(error.error, {
          header: 'Session Expired',
          body: 'Your session has expired or you have lost your network connection.',
          details: 'Please verify your device is connected to the internet then reload the page to continue.',
        })
      } else {
        // TODO: there are still issues with PubSub subscription stability
        unsubscribe(topic, accountSubscriptions)
        // TODO:
        // the following is from @jakefeldmann and likely needs investigation. The first
        // point should be a non-issue as unsubscribing is a synchronous process.

        // use a timesout here for a couple reasons:
        //    1) We want to make sure the unsubscribe has had plenty of time to do its thing
        //    2) Without a timeout, if there is no network connection, this may be triggered many times per second
        //       and can crash the app.
        //    3) We do not want to throttle the calls, because we may be re-subscribing to many different
        //       connections and we do not want any of these swallowed.
        setTimeout(() => subscribeToAccountTopic(topic, onNextData), 15000)
      }
    },
    complete: () => {
      console.log(`pub/sub: AWSAmplify subscription closed for topic: `, topic)
    },
  })
}

function unsubscribeFromAccountTopics() {
  Object.keys(accountSubscriptions)
    .forEach(topic => {
      accountSubscriptions[topic].unsubscribe()
      delete accountSubscriptions[topic]
    })
}

function unsubscribeFromAccountTopic(topic: string) {
  unsubscribe(topic, accountSubscriptions)
}

export default {
  configure,
  registerErrorHandler,
  subscribeToAccountTopic,
  unsubscribeFromAccountTopics,
  unsubscribeFromAccountTopic,
}
