import { useCallback } from 'react'
import { Form, Modal, Select } from 'antd'

import { actions } from '@vms/vmspro3-core/dist'

import useAuthz from '../../../hooks/useAuthz'
import { selectActiveUsers } from '../../../redux/selectors'
import { useAuth } from '../../../context'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { useInitialFocusRef } from '../../../hooks/useInitialFocusRef'

interface UsersAddRoleFormFields {
  userIds: string[]
}
interface UsersAddRoleModalProps {
  policyId: string,
  hideModal: () => void,
}
export function UsersAddRoleModal({
  policyId,
  hideModal,
}: UsersAddRoleModalProps) {
  const { authUserId } = useAuth()
  const getUserAddPolicyAction = useCallback(
    (userId: string) => actions.user.addPolicy(undefined, {
      policyId,
      userId,
      authUserId,
    }),
    [policyId, authUserId]
  )

  const authz = useAuthz()
  const activeUsers = useAppSelector(selectActiveUsers)
  const usersSelectOptions = activeUsers
    .filter(user =>
      !user.policyIds.includes(policyId) &&
      authz(getUserAddPolicyAction(user.id))
    )
    .map(({ id, fullName }) => ({ value: id, label: fullName }))


  const [form] = Form.useForm<UsersAddRoleFormFields>()

  const dispatch = useAppDispatch()
  const onOk = useCallback(
    () => form.validateFields()
      .then(({ userIds }) => {
        form.resetFields()
        userIds.forEach(userId => {
          dispatch(getUserAddPolicyAction(userId))
        })
        hideModal()
      }),
    [form, dispatch, getUserAddPolicyAction, hideModal]
  )

  const loadingUsers = useAppSelector(state => state.users.loadingStatus !== 'Loaded')

  return (
    <Modal
      visible
      onCancel={hideModal}
      onOk={onOk}
      destroyOnClose
    >
      <h3>Add Users to Role</h3>
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item
          label="Users"
          name="userIds"
          rules={[{ required: true }]}
        >
          <Select
            ref={useInitialFocusRef()}
            mode="multiple"
            loading={loadingUsers}
            disabled={loadingUsers}
            options={usersSelectOptions}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}
