import { ReactElement } from 'react'
import { gql, useQuery } from '@apollo/client'

import { Account } from '@vms/vmspro3-core/dist/types'
import { QueryResult } from '../components/common/QueryResult'

import { createTypedContext } from '../utils/createTypedContext'
import { AccountFieldsFragment } from '../graphql'

interface GetAccountIdByCommonIdData {
  accountByCommonId: Account,
}
interface GetAccountIdByCommonIdVariables {
  accountCommonId: string,
}
const GET_ACCOUNT_BY_COMMON_ID = gql`
  query GetAccountByCommonId($accountCommonId: String!) {
    accountByCommonId(accountCommonId: $accountCommonId) {
      ...AccountFields
    }
  }
  ${AccountFieldsFragment}
`

interface AdHocAccountContext {
  account: Account,
  accountId: string,
}

const [
  useAdHocAccount,
  ContextProvider,
] = createTypedContext<AdHocAccountContext>('AdHocAccountContext')

export { useAdHocAccount }

interface AdHocAccountProviderProps {
  accountCommonId: string,
  children: ReactElement,
}
export function AdHocAccountProvider({ accountCommonId, children }: AdHocAccountProviderProps) {
  const { data, error, loading } = useQuery<GetAccountIdByCommonIdData, GetAccountIdByCommonIdVariables>(
    GET_ACCOUNT_BY_COMMON_ID,
    {
      variables: {
        accountCommonId,
      },
    }
  )

  return (
    <QueryResult data={data} error={error} loading={loading}>
      {({ accountByCommonId: account }) => (
        <ContextProvider value={{ account, accountId: account.id }}>
          {children}
        </ContextProvider>
      )}
    </QueryResult>
  )
}
