import { EntityType } from '@vms/vmspro3-core/dist/systemConsts'

export const getEntityTypeLabel = (entityType, ancestry) => {
  switch(entityType) {
    case EntityType.PORTFOLIO: return ancestry === '/' ? 'Portfolio' : 'Subportfolio'
    case EntityType.PROGRAM: return 'Program'
    case EntityType.PROJECT: return 'Project'
    case EntityType.RISK: return 'Risk'
    default: return '(Unknown)'
  }
}
