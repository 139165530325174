import { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import QRCode from 'react-qr-code'
import { Button, Modal, Spin, Empty, Checkbox, Tooltip } from 'antd'
import { match } from 'ts-pattern'

import { ParticipationSessionType } from '@vms/vmspro3-core/dist/types'
import {
  startParticipationSession,
  endParticipationSession,
  updateParticipationSession,
} from '@vms/vmspro3-core/dist/actions/decision'

import Server from '../../../../server/VMSProServerAdapter'
import { useAugmentAction } from '../../../../hooks/useAugmentAction'
import { useAccount } from '../../../../context'
import { ParticipationSessionLabel } from '../../../../utils/appConsts'
import { useDecision, useParticipationSession } from '../../../../redux/hooks'
import { useAppDispatch } from '../../../../redux'

import { ReactComponent as Closed } from '../../../../assets/svg/closed.svg'
import style from './ParticipationTab.module.css'
import { ParticipationSessionData } from '@vms/vmspro3-core/dist/nextgen/participationSession'

interface CriteriaPrioritizationConfigProps {
  readonly?: boolean
  decisionId: string
  hasIntrinsicRoot: boolean
  participationSession: ParticipationSessionData
}
function CriteriaPrioritizationConfig(props: CriteriaPrioritizationConfigProps) {
  const { readonly, decisionId, hasIntrinsicRoot, participationSession: ps } = props
  const dispatch = useAppDispatch()
  const includeIntrinsicChange = useCallback(() => {
    dispatch(updateParticipationSession(decisionId, ps.id, { includeIntrinsic: !ps.includeIntrinsic }))
  }, [dispatch, decisionId, ps.id, ps.includeIntrinsic])

  const checkbox = (
    <Checkbox
      checked={ps.includeIntrinsic}
      disabled={readonly}
      onChange={includeIntrinsicChange}
    >
      Include Quantitative Criteria (cost/time)
    </Checkbox>
  )

  return (
    <div>
      {hasIntrinsicRoot && (
        readonly
          ? (
            <Tooltip title="This value cannot be changed while a participation session is in progress">
              {checkbox}
            </Tooltip>
          )
          : checkbox
      )}
    </div>
  )
}

function OptionRatingConfig() {
  // currently no options
  return null
}

function useParticipationTabControls(
  accountId: string,
  decisionId: string,
  participationSession: ParticipationSessionData,
  canStart: boolean
) {
  const dispatch = useAppDispatch()

  const augmentAction = useAugmentAction(accountId)
  const [loadingParticipation, setLoadingParticipation] = useState(false)
  const onOpenParticipation = useCallback(
    () => {
      if(participationSession.type === 'CriteriaPrioritization' && !canStart) {
        Modal.error({
          title: `Cannot Start Participation Session`,
          content: (
            <>
              <p>To start a criteria prioritization session, you must either have
                two or more performance criteria (in addition to the root performance
                criterion), or have the "include quantitative criteria" option checked.
              </p>
            </>
          ),
        })
        return
      }
      const action = augmentAction(startParticipationSession(decisionId, participationSession.id))
      setLoadingParticipation(true)
      Server.tryAction(action)
        .then(() => {
          action.meta.ephemeral = true
          dispatch(action)
          setLoadingParticipation(false)
        })
    },
    [dispatch, decisionId, participationSession, augmentAction, canStart]
  )
  const openParticipationButton = (
    <Button
      loading={loadingParticipation}
      onClick={onOpenParticipation}
      type="primary"
    >
      Open Participation
    </Button>
  )

  const onCloseParticipation = useCallback(
    () => Modal.confirm({
      title: 'Close Participation',
      content: 'Are you sure you want to close participation?',
      onOk: () => {
        dispatch(endParticipationSession(decisionId, participationSession.id))
      },
    }),
    [dispatch, decisionId, participationSession.id]
  )
  const closeParticipationButton = (
    <Button onClick={onCloseParticipation} danger>
      Close Participation
    </Button>
  )
  return {
    openParticipationButton,
    closeParticipationButton,
  }
}

type ParticipationTabProps = {
  decisionId: string,
  participationSessionType: ParticipationSessionType,
  joinSessionLinkLabel: string,
}
export function ParticipationTab({
  decisionId,
  participationSessionType,
  joinSessionLinkLabel,
}: ParticipationTabProps): JSX.Element {
  const { accountId, accountCommonId } = useAccount()
  const participationSession = useParticipationSession(decisionId, participationSessionType)
  const { status, id: sessionId } = participationSession

  const decision = useDecision(decisionId)
  const ratingContexts = useMemo(() => decision.getRatingContexts('CriteriaPrioritization', '*'), [decision])
  const hasIntrinsicRoot = useMemo(() => !!decision.criteria.all.find(c => c.type === 'IntrinsicRoot'), [decision])

  const canStart = ratingContexts.length > 0

  const {
    openParticipationButton,
    closeParticipationButton,
  } = useParticipationTabControls(accountId, decisionId, participationSession, canStart)

  const participationPathname = `/${accountCommonId}/decision/${decisionId}/participation/${sessionId}`

  if(!participationSession) return <Spin />

  const sessionLabel = ParticipationSessionLabel[participationSessionType]

  if(participationSessionType === 'OptionRating' && decision.options.all.length === 0) {
    return (
      <Empty
        description={(
          <div>
            <h2>No Options</h2>
            <p>In order to rate options, you must have at least one option.</p>
          </div>
        )}
      />
    )
  }

  return (
    <>
      <h2>{sessionLabel}</h2>
      {status === 'Active' && (
        <>
        <div className={style.tabStyle}>
          <div className={style.linkAndCopy}>
            <Link to={participationPathname}>{joinSessionLinkLabel}</Link>
            <Button
              style={{ width: '120px' }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}${participationPathname}`)
              }}
            >Copy Link</Button>
            {match(participationSessionType)
              .with('CriteriaPrioritization', () =>
                <CriteriaPrioritizationConfig
                  readonly
                  decisionId={decisionId}
                  hasIntrinsicRoot={hasIntrinsicRoot}
                  participationSession={participationSession}
                />)
              .with('OptionRating', () =>
                <OptionRatingConfig />
              )
              .exhaustive()
            }
          </div>
          <div className={style.qrCode}>
            <QRCode value={`${window.location.origin}${participationPathname}`} />
          </div>
          <div className={style.openCloseSession}>
           {closeParticipationButton}
          </div>
        </div>
        </>
      )}
      {status === 'Inactive' && (
        <div className={style.tabStyle}>
          <div>
            <p>Participation is closed.</p>
            {match(participationSessionType)
              .with('CriteriaPrioritization', () =>
                <CriteriaPrioritizationConfig
                  decisionId={decisionId}
                  hasIntrinsicRoot={hasIntrinsicRoot}
                  participationSession={participationSession}
                />)
              .with('OptionRating', () =>
                <OptionRatingConfig />
              )
              .exhaustive()
            }
          </div>
          <Closed className={style.closedSvg} />
          <div className={style.openCloseSession}>
          {openParticipationButton}
          </div>
        </div>
      )}
    </>
  )
}
