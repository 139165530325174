import { createContext, useContext } from 'react'

type TreeContextValue = {
  readOnly?: boolean,
  selectedItemId?: string,
  onSelectItem?: (itemId: string) => void,
}
const TreeContext = createContext<TreeContextValue | undefined>(undefined)

export const TreeProvider = TreeContext.Provider
export const useTreeContext = (): TreeContextValue => {
  const context = useContext(TreeContext)

  if(context === undefined) {
    throw new Error('useTreeContext must be used within TreeContext.Provider')
  }

  return context
}
