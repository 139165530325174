import { produce } from 'immer'

import { Participant } from '@vms/vmspro3-core/dist/types'
import {
  CreateParticipantAction,
  UpdateParticipantAction,
  DeleteParticipantAction,
} from '@vms/vmspro3-core/dist/actions/decision'

import { FetchDecisionEntitySuccessAction, FetchDecisionFolderChildrenSuccessAction } from '../actions'

export type ParticipantsState = {
  byId: Record<string, Participant>
}
type ParticipantsReducerAction =
  | FetchDecisionEntitySuccessAction
  | FetchDecisionFolderChildrenSuccessAction
  | CreateParticipantAction
  | UpdateParticipantAction
  | DeleteParticipantAction

export const initialState: ParticipantsState = {
  byId: {},
}

export const participantsReducer = produce<ParticipantsState, [ParticipantsReducerAction?]>((state, action) => {
  if(!action?.type) return

  switch(action.type) {
    case 'FetchDecisionFolderChildrenSuccess': // fall through to FetchDecisionEntitySuccess
    case 'FetchDecisionEntitySuccess': {
      if(action.payload.participants) {
        Object.assign(state.byId, action.payload.participants)
      }
      break
    }

    case 'Create Participant': {
      const created = {
        timestamp: Date.now(),
        location: '{}',
      }
      state.byId[action.payload.id] = {
        ...action.payload,
        created,
        updated: created,
      }
      break
    }

    case 'Update Participant': {
      Object.assign(state.byId[action.meta.participantId], action.payload)
      break
    }

    case 'Delete Participant': {
      delete state.byId[action.meta.participantId]
      break
    }

    default: {
      break
    }
  }
}, initialState)
