import React, { useEffect, useMemo, useState } from 'react'
import _cloneDeep from 'lodash/cloneDeep'
import _clone from 'lodash/clone'
import _get from 'lodash/get'
import { css } from 'glamor'
import { Col, Radio, Row } from 'antd'

import systemConsts from '@vms/vmspro3-core/dist/systemConsts'
import { getSeverityClass } from '@vms/vmspro3-core/dist/utils/risk'

import { Select } from '../controls'
import Heatmap from '../common/Heatmap'

const { RiskColor, RISK_COLOR_MATRIX } = systemConsts

const riskMetrics = {
  COST: { key: 'cost', title: { short: 'Cost', long: 'Cost' } },
  TIME: { key: 'time', title: { short: 'Sched', long: 'Schedule' } },
  PERF: { key: 'perf', title: { short: 'Perf', long: 'Performance' } },
  SEVERITY: { key: 'severity', title: { short: 'Sev', long: 'Severity' } },
}

const THREAT = 'THREAT'
const OPPORTUNITY = 'OPPORTUNITY'
const COST = 'COST'
const BOTH = 'BOTH'

const RiskInventoryHeatmapFilter = ({
  effectiveRiskContext,
  dataSource,
  setDisplayData,
}) => {
  // TODO: now that there can only be one group selected at a time, these three filters should be consolidated
  // into one state value
  const [lowFilter, setLowFilter] = useState(false)
  const [medFilter, setMedFilter] = useState(false)
  const [highFilter, setHighFilter] = useState(false)

  const [managed, setManaged] = useState(true)
  const [riskType, setRiskType] = useState(THREAT)
  const [impactType, setImpactType] = useState(COST.toLowerCase())
  const [activeCells, setActiveCells] = useState([])
  const [sevTotals, setSevTotals] = useState({ L: [], M: [], H: [] })
  const prefix = managed ? 'managed.' : ''

  const probScale = useMemo(() =>
    _cloneDeep(effectiveRiskContext.riskScales.prob).reverse(), [effectiveRiskContext])
  const impactScale = useMemo(() =>
    _cloneDeep(effectiveRiskContext.riskScales.prob), [effectiveRiskContext])

  /**
   * Filter the dataSource down to only the selected risks from the heatmap.
   */
  const displayData = useMemo(() => dataSource.filter(r => {
    if(impactType === 'severity') {
      const sev = _get(r, `${prefix}severity.total`, '')
      const sevQual = getSeverityClass(sev)
      if((lowFilter && sevQual?.key === 'L') && r.type === riskType) return true
      if((medFilter && sevQual?.key === 'M') && r.type === riskType) return true
      if((highFilter && sevQual?.key === 'H') && r.type === riskType) return true
      if((!lowFilter && !medFilter && !highFilter) && riskType === BOTH) return true
      if((!lowFilter && !medFilter && !highFilter) && r.type === riskType) return true
      return false
    }
    const probIdx = probScale.findIndex(({ key }) => key === _get(r, `${prefix}prob.qual`, ''))
    const impactIdx = impactScale.findIndex(({ key }) => key === _get(r, `${prefix}impact.${impactType}.qual`, ''))
    return (activeCells.length
      ? activeCells.some(([r, c]) => r === probIdx && impactIdx === c)
      : true) && _get(r, `${prefix}impact.${impactType}.type`) === riskType
  }), [
    activeCells,
    dataSource,
    impactScale,
    impactType,
    prefix,
    probScale,
    riskType,
    highFilter,
    lowFilter,
    medFilter,
  ])

  // When display data changes, send to the parent component.
  useEffect(() => setDisplayData(displayData), [displayData, setDisplayData])

  /**
   * Build the custom matrix for the heatmap component. The value for the cells is
   * the sum of the risks that will display when selected. Track the overall sum in
   * the sevTotals object.
   */
  const matrix = useMemo(() => {
    const sevTotals = { L: [], M: [], H: [] }
    const matrix = probScale.map((prob, row) =>
      impactScale.map((impact, col) => {
        const matchingRisks = dataSource.filter(risk => {
          const probQual = _get(risk, `${prefix}prob.qual`, '')
          const impactQual = _get(risk, `${prefix}impact.${impactType}.qual`, '')
          return _get(risk, `${prefix}impact.${impactType}.type`) === riskType
            && probQual === prob?.key
            && impactQual === impact.key
        })
        matchingRisks.forEach(risk => sevTotals[RISK_COLOR_MATRIX[row][col]].push(risk.id))
        return matchingRisks.length
      })
    )
    if(impactType === 'severity') {
      // if considering total severity, must use a different technique for calculating sev counts
      dataSource.forEach(risk => {
        const sev = _get(risk, `${prefix}severity.total`, '')
        if(riskType !== BOTH && riskType !== _get(risk, `type`)) return
        const sevQual = getSeverityClass(sev)?.key
        sevTotals[sevQual] && sevTotals[sevQual].push(risk.id)
      })
    }
    setSevTotals(sevTotals)
    return matrix
  }, [dataSource, impactScale, impactType, prefix, probScale, riskType])

  /**
   * Select all options from a table with the same severity level
   *
   * @param {String} group - [L, M, H] - string correlation with the RISK_COLOR_MATRIX in systemConsts
   *
   * @returns an array of active cell indices
   */
  const getGroupedCells = group => {
    const indices = []
    RISK_COLOR_MATRIX.forEach((r, rIdx) => r.forEach((c, cIdx) => { if(c === group) indices.push([rIdx, cIdx]) }))
    return indices
  }

  /**
   * Find the index of a given cell in an array of activeCells
   *
   * @param {Array<Array>} activeCells - the activeCells array to compare against
   * @param {Number} prob - the row index or probability
   * @param {Number} impact - the column index or impact level
   */
  const matrixIndex = (activeCells, prob, impact) =>
    activeCells.findIndex(([mtrxProb, mtrxImpact]) =>
      (mtrxProb === prob && mtrxImpact === impact)
    )

  /**
   * Check if all cells are active for a given severity level
   *
   * @param {Object} activeCells - the activeCells array to compare against
   * @param {String} level - the severity level
   */
  const checkGroupedCellsActive = level => {
    const groupedCells = getGroupedCells(level)
    const groupActive = groupedCells.every(([prob, impact]) => matrixIndex(matrix, prob, impact) > -1)
    switch(level) {
      case 'L': setLowFilter(groupActive); break
      case 'M': setMedFilter(groupActive); break
      case 'H': setHighFilter(groupActive); break
      default: break
    }
  }

  /**
   * handle individual cell selections on the heatmap table.
   * Check for updates against severity groups.
   *
   * @param {Number} prob - the row of the cell selected
   * @param {Number} impact - the column of the cell selected
   */
  const handleHeatmapSelection = (prob, impact) => {
    let _activeCells = _clone(activeCells)
    const idx = matrixIndex(_activeCells, prob, impact)
    const sevLevel = RISK_COLOR_MATRIX[prob][impact]
    // clear unwanted group selection
    if(_activeCells.length > 1) _activeCells = []
    // re-select a cell or delete selected cell
    if(_activeCells.length > 0) {
      const isSelectedCell = [prob, impact].every((e, idx) => e === _activeCells[0][idx])
      _activeCells.splice(idx, 1)
      !isSelectedCell && _activeCells.push([prob, impact])
    } else {
      // add single selected cell, either initially or new single selected cell after group selection
      _activeCells.push([prob, impact])
    }
    checkGroupedCellsActive(sevLevel)
    setActiveCells(_activeCells)
  }

  /**
   * Handle click to update severity group changes
   *
   * @param {String} level - [L, M, H] - the severity level
   */
  const setFilterGroup = level => {
    let activate = true
    // this is silly but it short circuits original functionality
    if(lowFilter) setLowFilter(false)
    if(medFilter) setMedFilter(false)
    if(highFilter) setHighFilter(false)
    switch(level) {
      case 'L': activate = !lowFilter; setLowFilter(activate); break
      case 'M': activate = !medFilter; setMedFilter(activate); break
      case 'H': activate = !highFilter; setHighFilter(activate); break
      default: break
    }
    const groupedCells = getGroupedCells(level)

    // need some clean up
    if(activate) setActiveCells(groupedCells)
    else setActiveCells([])
  }
  /**
   * Set the riskType and clear the active filters on change of the TO Switch.
   * True = Threat
   * False = Opportunity
   *
   * @param {Boolean} checked - Boolean for the state of the switch.
   */
  const toggleTO = ({ target: { value } }) => {
    setRiskType(value)
    setActiveCells([])
    setLowFilter(false)
    setMedFilter(false)
    setHighFilter(false)
  }

  const onImpactTypeChange = ({ props }) => {
    if(riskType === BOTH) setRiskType(THREAT)
    setImpactType(props.id)
  }

  return (
    <>
      <Row type="flex" justify="end" {...style.headerWrapper}>
        <Col span={14}>
          <Row gutter={12}>
            <Col span={8}>
              <div onClick={() => setFilterGroup('L')} {...style.cell(riskType, 'LOW', lowFilter)}>
                Low: {sevTotals.L.length}
              </div>
            </Col>
            <Col span={8}>
              <div onClick={() => setFilterGroup('M')} {...style.cell(riskType, 'MED', medFilter)}>
                Medium: {sevTotals.M.length}
              </div>
            </Col>
            <Col span={8}>
              <div onClick={() => setFilterGroup('H')} {...style.cell(riskType, 'HIGH', highFilter)}>
                High: {sevTotals.H.length}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={8} style={style.filterControls}>
          <Select
            style={style.select}
            allowClear={false}
            onChange={(key, val) => onImpactTypeChange(val)}
            value={riskMetrics[impactType.toUpperCase()].title.long}
          >
            {Object.values(riskMetrics).map(({ key, title }) =>
              <Select.Option key={key} id={key}>{title.long}</Select.Option>
            )}
          </Select>
          <Radio.Group onChange={({ target: { value } }) => setManaged(value)} defaultValue style={style.radio}>
            <Radio.Button value>Managed</Radio.Button>
            <Radio.Button value={false}>Unmanaged</Radio.Button>
          </Radio.Group>
          <Radio.Group onChange={toggleTO} defaultValue={THREAT} value={riskType} style={style.radio}>
            <Radio.Button value={THREAT}>Threat</Radio.Button>
            <Radio.Button value={OPPORTUNITY}>Opportunity</Radio.Button>
            {impactType === 'severity' && <Radio.Button value={BOTH}>Both</Radio.Button>}
          </Radio.Group>
        </Col>
        <Col span={16} style={style.heatmapWrapper}>
          {impactType !== 'severity' &&
            <Heatmap
              active={false}
              impactType={riskType}
              costImpact={null}
              timeImpact={null}
              perfImpact={null}
              probability={0}
              width="100%"
              customMatrix={matrix}
              activeCells={activeCells}
              onClick={handleHeatmapSelection}
            />
          }
        </Col>
      </Row>
      <Row type="flex" justify="end">{`Filtered ${displayData.length} of ${dataSource.length} total risks.`}</Row>
    </>
  )
}

const style = {
  radio: {
    margin: '18px 0 0 6px',
  },
  cell: (riskType, level, active) => css({
    alignItems: 'center',
    backgroundColor: RiskColor[`${riskType}_${level}`],
    color: 'white',
    display: 'flex',
    flex: '1',
    fontSize: 'large',
    fontWeight: 'bold',
    justifyContent: 'center',
    textShadow: '2px 2px rgba(0, 0, 0, 0.4)',
    border: `2px solid ${active ? 'black' : 'white'}`,
  }),
  filterControls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  headerWrapper: css({
    margin: '0 0 12px 0',
  }),
  heatmapWrapper: {
    paddingRight: '26px',
  },
  select: {
    width: '220px',
    paddingLeft: '6px',
  },
}

export default RiskInventoryHeatmapFilter
