import MenuUnfoldOutlined from '@ant-design/icons/MenuUnfoldOutlined'
import MenuFoldOutlined from '@ant-design/icons/MenuFoldOutlined'

import style from './MenuToggle.module.css'

interface MenuToggleProps {
  menuOpen: boolean,
  toggleMenuOpen: VoidFunction,
}
export function MenuToggle({ menuOpen, toggleMenuOpen }: MenuToggleProps) {
  return (
    <span className={style.menuToggle} onClick={toggleMenuOpen}>
      {menuOpen ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
    </span>
  )
}
