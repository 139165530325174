/* eslint-disable @typescript-eslint/no-var-requires, no-undef */
const _get = require('lodash/get')
const _has = require('lodash/has')
const _cloneDeep = require('lodash/cloneDeep')
const _defaultsDeep = require('lodash/defaultsDeep')
const _mapValues = require('lodash/mapValues')
const _keyBy = require('lodash/keyBy')

const { Quantity, Cost, Duration, Dimensionless } = require('../qty')

const { Color, RiskColor } = require('../systemConsts')

/**
 * Risk scale value.
 *
 * @typedef {object} RiskScaleValue
 * @property {string} key - The key to be used to represent this value.
 * @property {string|object} label - If a string, the label for this value.
 * @property {string} label.short - The short (abbreviated) label for this value.
 * @property {string} label.long - The full (unabbreviated) label for this value.
 * @property {boolean} isDefault - The default or initial risk value (if any).
 * @property {number[]} quantRange - The range of quantitative values that this
 *  (qualitative) value represents.  It is an array with two elements; the first
 *  element is inclusive and the second exlement is exclusive.
 */

/**
 * Return default risk scale for a given attribute (prob, cost, time, perf).
 *
 * @param {string} attr - One of "prob", "cost", "time", or "perf".
 * @returns {RiskScaleValue[]} - Contiguous values comprising the default risk scale.
 */
function createDefaultRiskScale(attr) {
  const quantRanges = {
    prob: {
      VL: [0.000, 0.200],
      L:  [0.200, 0.400],
      M:  [0.400, 0.600],
      H:  [0.600, 0.800],
      VH: [0.800, 1.000],
    },
    cost: {
      VL: [0.000, 0.025],
      L:  [0.025, 0.050],
      M:  [0.050, 0.075],
      H:  [0.075, 0.100],
      VH: [0.100, 0.150],
    },
    time: {
      VL: [0.000, 0.025],
      L:  [0.025, 0.050],
      M:  [0.050, 0.075],
      H:  [0.075, 0.100],
      VH: [0.100, 0.150],
    },
    perf: {
      VL: [0.000, 0.050],
      L:  [0.050, 0.100],
      M:  [0.100, 0.150],
      H:  [0.150, 0.200],
      VH: [0.200, 0.250],
    },
  }
  return [
    {
      key: 'VL',
      severityScore: 2,
      label: { short: 'VL', long: 'Very Low' },
    },
    {
      key: 'L',
      severityScore: 4,
      label: { short: 'L', long: 'Low' },
    },
    {
      key: 'M',
      severityScore: 6,
      label: { short: 'M', long: 'Medium' },
      isDefault: true,
    },
    {
      key: 'H',
      severityScore: 8,
      label: { short: 'H', long: 'High' },
    },
    {
      key: 'VH',
      severityScore: 10,
      label: { short: 'VH', long: 'Very High' },
    },
  ].map(v => Object.assign(v, { quantRange: quantRanges[attr][v.key] }))
}

function createRootRiskContext() {
  return {
    riskScales: {
      prob: createDefaultRiskScale('prob'),
      cost: createDefaultRiskScale('cost'),
      time: createDefaultRiskScale('time'),
      perf: createDefaultRiskScale('perf'),
    },
    attributes: {
      cost: {
        key: 'cost',
        weight: 1/3,
        label: { long: 'Cost', short: 'Cost' },
      },
      time: {
        key: 'time',
        weight: 1/3,
        label: { long: 'Schedule', short: 'Sched' },
      },
      perf: {
        key: 'perf',
        weight: 1/3,
        label: { long: 'Performance', short: 'Perf' },
      },
    },
    types: {
      status: {
        key: 'status',
        nullProxyValue: null,
        defaultValue: 'ACTIVE',
        additionalAttrs: [
          { name: 'desc', type: 'TEXT' },
        ],
        isEditable: false,
        values: [
          { value: 'ACTIVE', label: { short: 'Active', long: 'Active' } },
          { value: 'DORMANT', label: { short: 'Dormant', long: 'Dormant' } },
          { value: 'RETIRED', label: { short: 'Retired', long: 'Retired' } },
        ],
      },
      category: {
        key: 'category',
        nullProxyValue: null,
        defaultValue: null,
        additionalAttrs: [
          { name: 'desc', type: 'TEXT' },
        ],
        isEditable: true,
        values: [],
      },
      phase: {
        key: 'phase',
        nullProxyValue: null,
        defaultValue: null,
        additionalAttrs: [
          { name: 'desc', type: 'TEXT' },
        ],
        isEditable: true,
        values: [],
      },
      riskType: {
        key: 'riskType',
        nullProxyValue: 'NONE',
        defaultValue: 'THREAT',
        additionalAttrs: [
          { name: 'desc', type: 'TEXT' },
        ],
        isEditable: false,
        values: [
          { value: 'THREAT', label: { short: 'T', long: 'Threat' } },
          { value: 'OPPORTUNITY', label: { short: 'O', long: 'Opportunity' } },
          { value: 'NONE', label: { short: '-', long: 'Neither' } },
        ],
      },
      riskRespFocus: {
        key: 'riskRespFocus',
        nullProxyValue: 'NONE',
        defaultValue: 'THREAT',
        additionalAttrs: [
          { name: 'desc', type: 'TEXT' },
        ],
        isEditable: false,
        values: [
          { value: 'INT', label: { short: 'Int', long: 'Internal' } },
          { value: 'EXT', label: { short: 'Ext', long: 'External' } },
          { value: 'INT_EXT', label: { short: 'Int & Ext', long: 'Internal / External' } },
        ],
      },
      riskRespStrat: {
        key: 'riskRespStrat',
        nullProxyValue: 'NONE',
        defaultValue: 'THREAT',
        additionalAttrs: [
          { name: 'desc', type: 'string' },
          { name: 'scope', type: 'string:riskType' },
        ],
        isEditable: false,
        values: [
          { value: 'MITIGATE', label: { short: 'Mitigate', long: 'Mitigate' }, scope: 'THREAT' },
          { value: 'AVOID', label: { short: 'Avoid', long: 'Avoid' }, scope: 'THREAT' },
          { value: 'TRANSFER', label: { short: 'Transfer', long: 'Transfer' }, scope: 'THREAT' },
          { value: 'ACCEPT', label: { short: 'Accept', long: 'Accept' }, scope: 'THREAT' },
          { value: 'ENHANCE', label: { short: 'Enhance', long: 'Enhance' }, scope: 'OPPORTUNITY' },
          { value: 'SHARE', label: { short: 'Share', long: 'Share' }, scope: 'OPPORTUNITY' },
          { value: 'EXPLOIT', label: { short: 'Exploit', long: 'Exploit' }, scope: 'OPPORTUNITY' },
        ],
      },
    },
    fields: {
      status: {
        key: 'status',
        allowNull: false,
        type: 'string:status',
        label: { short: 'Risk Category', long: 'Risk Category' },
      },
      category: {
        key: 'category',
        allowNull: true,
        type: 'string:category',
        label: { short: 'Risk Category', long: 'Risk Category' },
      },
      phase: {
        key: 'phase',
        allowNull: true,
        type: 'string:phase',
        label: { short: 'Risk Phase', long: 'Risk Phase' },
      },
      riskType: {
        key: 'riskType',
        allowNull: true,
        type: 'string:riskType',
        label: { short: 'Risk Type', long: 'Risk Type' },
      },
      riskRespFocus: {
        key: 'riskRespFocus',
        allowNull: true,
        type: 'string:riskRespFocus',
        group: 'RISK_MGMT_PLAN',
        label: { short: 'Risk Resp. Focus', long: 'Risk Response Focus' },
      },
      riskRespStrat: {
        key: 'riskRespStrat',
        allowNull: true,
        type: 'string:riskRespStrat',
        group: 'RISK_MGMT_PLAN',
        label: { short: 'Risk Resp. Strat.', long: 'Risk Response Strategy' },
      },
      description: {
        key: 'description',
        allowNull: true,
        type: 'html',
        group: 'INFO',
        label: { short: 'Description', long: 'S.M.A.R.T. Description' },
      },
      trigger: {
        key: 'trigger',
        allowNull: true,
        type: 'html',
        group: 'INFO',
        label: { short: 'Trigger', long: 'Trigger' } },
      comments: {
        key: 'comments',
        allowNull: true,
        type: 'html',
        group: 'INFO',
        label: { short: 'Comments', long: 'Additional Comments' }
      },
      primaryActionPlan: {
        key: 'primaryActionPlan',
        allowNull: true,
        type: 'html',
        group: 'INFO',
        label: { short: 'Primary Action Plan', long: 'Primary Action Plan' }
      },
      fallbackActionPlan: {
        key: 'fallbackActionPlan',
        allowNull: true,
        type: 'html',
        group: 'INFO',
        label: { short: 'Fallback Action Plan', long: 'Fallback Action Plan' }
      },
      // NOTE: "riskOwnerNotes" will eventually be replaced with a true "owner" field that references
      // a user (with a possible fallback to some non-user identifying info); for now, this field
      // will be labeled "Owner" in the UI pending that enhancement
      riskOwnerNotes: {
        key: 'riskOwnerNotes',
        allowNull: true,
        type: 'html',
        group: 'RISK_MGMT_PLAN',
        label: { short: 'Owner', long: 'Owner' }
      },
      statusUpdateComments: {
        key: 'statusUpdateComments',
        allowNull: true,
        type: 'html',
        group: 'RISK_MGMT_PLAN',
        label: { short: 'Status Update Comments', long: 'Status Update Comments' }
      },
      // NOTE: I would like to see this broken up into a date field "lastReviewed" and
      // enum field "reviewFrequency", but I am de-scoping this for the MVP...they get a rich text for now
      reviewDateAndFreq: {
        key: 'reviewDateAndFreq',
        allowNull: true,
        type: 'html',
        group: 'RISK_MGMT_PLAN',
        label: { short: 'Review Date/Freq', long: 'Risk Review Date/Frequency' }
      },
      baseCostImpacts: {
        key: 'baseCostImpacts',
        allowNull: true,
        type: 'currency',
        group: 'RISK_MGMT_PLAN',
        label: { short: 'Base Cost Impacts', long: 'Base Cost Impacts' },
      },
      baseScheduleImpacts: {
        key: 'baseScheduleImpacts',
        allowNull: true,
        type: 'duration',
        group: 'RISK_MGMT_PLAN',
        label: { short: 'Base Schedule Impacts', long: 'Base Schedule Impacts' },
      },
    },
    defaultCostUnit: 'USD',
    defaultDurationUnit: 'Months',
    nextRiskNum: 1,
  }
}

function createOverlayRiskContext(effectiveRiskContext, cost, time) {
  const { riskScales, attributes } = effectiveRiskContext
  return {
    riskScales,
    attributes: {
      ...attributes,
      cost: { ...attributes.cost, value: cost },
      time: { ...attributes.time, value: time },
      perf: { ...attributes.perf, value: new Dimensionless(1) },
    },
    // each context gets its own re-started risk numbering
    nextRiskNum: 1,
  }
}

const demoEffectiveRiskContextDefaultConfig = {
  cost: Cost.USD(1e6),
  time: Duration.Months(10),
  categories: [
    { value: 'c0', ancestry: '/', label: { short: 'Env', long: 'Environmental' } },
    { value: 'c1', ancestry: '/c0', label: { short: 'Soil', long: 'Soil' } },
    { value: 'c2', ancestry: '/c0', label: { short: 'Air', long: 'Air' } },
    { value: 'c3', ancestry: '/c0', label: { short: 'Water', long: 'Water' } },
    { value: 'c4', ancestry: '/', label: { short: 'Pol', long: 'Political' } },
    { value: 'c5', ancestry: '/c4', label: { short: 'Fed', long: 'Federal' } },
    { value: 'c6', ancestry: '/c4', label: { short: 'St', long: 'State' } },
    { value: 'c7', ancestry: '/c4', label: { short: 'Loc', long: 'Local' } },
  ],
  phases: [
    { value: 'p0', label: { short: 'Plan', long: 'Planning' } },
    { value: 'p1', label: { short: 'Cons', long: 'Construction' } },
    { value: 'p2', label: { short: 'Maint', long: 'Maintenance' } },
  ],
}

/**
 * Creates a demo effective risk context, useful for testing & development.  By default,
 * the risk context returned will have sample categories, phases, a cost, and duration.
 *
 * Sample categories and phases are not described below.  See demoEffectiveRiskContextDefaultConfig
 * (not exported) for more information.
 *
 * @param {Cost} [config.cost = Cost.USD(1e6)]
 * @param {Duration} [config.time = Duration.Months(10)]
 * @param {object} [config.categories = sampleCateogries]
 * @param {object} [config.phases = samplePhases]
 *
 * @returns {EffectiveRiskContext}
 */
function createDemoEffectiveRiskContext(config = demoEffectiveRiskContextDefaultConfig) {
  const rootRiskContext = createRootRiskContext()
  rootRiskContext.types.category.values = config.categories
  rootRiskContext.types.phase.values = config.phases
  const overlayRiskContext = createOverlayRiskContext(rootRiskContext, config.cost, config.time)
  return getEffectiveRiskContext([rootRiskContext, overlayRiskContext])
}

/**
 * Given an inheritence chain of partial risks contexts, returns an effective risk
 * context.
 *
 * This has been simplified (for now) to simply use _defaultsDeep (used such that
 * child contexts override their parent).  In the future, there will have to be
 * rules added to handle subtractive enum types.
 *
 * @param {Array<*>} contexts - Array of partial risk contexts, ordered from ancestor
 *    to descendant (e.g. grandparent, parent, child)
 * @returns {Object} - Effective risk configuration based on the combination of the
 *    risk contexts.
 */
function getEffectiveRiskContext(riskContexts) {
  // only root context can contain types & fields...at least for now.  in the future,
  // we will need to support additive fields and subtractive type values
  const nonRootContexts = riskContexts.slice(1)
  if(nonRootContexts.some(c => c.types)) throw new Error('type extension not currently supported')
  if(nonRootContexts.some(c => c.fields)) throw new Error('field extension not currently supported')
  return _defaultsDeep({}, ...riskContexts.slice().reverse())
}

/**
 * Given a risk scale and a value key, returns the corresponding quantitative range.
 *
 * @param {object[]} scale - Risk scale.
 * @param {string} key - Valid qualitative value for scale.
 * @returns {number[]} - Quantitiave range corresponding with qualitative value.
 */
const getQuantRange = (scale, key) => _get(scale.find(v => v.key === key), 'quantRange', [])

/**
 * Given a risk scale and a quantitative value, returns the appropriate qualitative
 * value that corresponds to the scale.  Note that quantitative values below the
 * lowest range in the scale will return the lowest qualitative value, and values
 * larger than the higher than the highest range in the scale will return the highest
 * qualitative value.
 *
 * @param {object[]} scale - Risk scale.
 * @param {number} quant - Quantitative value.
 * @returns {string} - Qualitative value key corresponding to quantiative value.
 */
const getQualFromQuant = (scale, quant) => _get(scale.find(({ quantRange }, idx, l) => {
  if(idx === l.length - 1) return true  // in or larger than last range
  const [, max] = quantRange
  // note there's no need to test against min; if the quant value is lower than the
  // first range, use the first range
  return quant < max
}), 'key')

/**
 * Returns the default scale value (if any) for a given scale.
 *
 * @param {object[]} scale - Risk scale.
 * @returns {string|undefined} - The key (if any) of the scale value marked with isDefault.
 */
const getDefaultScaleValue = scale => _get(scale.find(v => v.isDefault), 'key')

// cost fields for normalization/denormalization purposes
const costFields = [
  'impact.cost.quant.min',
  'impact.cost.quant.ml',
  'impact.cost.quant.max',
  'impact.cost.quant.ev',
  'managed.impact.cost.quant.min',
  'managed.impact.cost.quant.ml',
  'managed.impact.cost.quant.max',
  'managed.impact.cost.quant.ev',
]
// time fields for normalization/denormalization purposes
const timeFields = [
  'impact.time.quant.min',
  'impact.time.quant.ml',
  'impact.time.quant.max',
  'impact.time.quant.ev',
  'managed.impact.time.quant.min',
  'managed.impact.time.quant.ml',
  'managed.impact.time.quant.max',
  'managed.impact.time.quant.ev',
]
// perf fields for normalization/denormalization purposes
const perfFields = [
  'impact.perf.quant.min',
  'impact.perf.quant.ml',
  'impact.perf.quant.max',
  'impact.perf.quant.ev',
  'managed.impact.perf.quant.min',
  'managed.impact.perf.quant.ml',
  'managed.impact.perf.quant.max',
  'managed.impact.perf.quant.ev',
]

/**
 * Normalizes cost and time fields (specified above) in an update
 * object by replacing the object representation with their numeric value.
 * This also does a deep clone of all values.
 *
 * @param {Object} update - An update object.
 * @returns {Object} - An update object with cost and time objects
 *  replaced with numeric values.
 */
const normalizeUpdate = update => _mapValues(update, (v, k) =>
  costFields.includes(k) || timeFields.includes(k) || perfFields.includes(k)
    ? _get(v, 'value')
    : _cloneDeep(v)
)

/**
 * Denormalizes cost and time fields (specified above) in an update
 * object by replacing numeric values with cost and time objects.
 *
 * @param {Object} update - An update object.
 * @returns {Object} - An update object with numeric values replaced
 *  replaced with cost and time objects.
 */
const denormalizeUpdate = (update, costUnit, durationUnit) => _mapValues(update, (v, k) =>
  costFields.includes(k)
    ? (typeof v === 'number' ? new Cost(costUnit, v) : null)
    : timeFields.includes(k)
      ? (typeof v === 'number' ? new Duration(durationUnit, v) : null)
      : perfFields.includes(k)
        ? (typeof v === 'number' ? new Dimensionless(v) : null)
        : v
)

/**
 * Calculates attribute severity.
 *
 * @param {object[]} probRiskScale - Probability risk scale.
 * @param {object[]} impactRiskScale - Attribute impact risk scale.
 * @param {string} probQual - Qualitative probability key.
 * @param {string} impactType - Impact type (NONE/THREAT/OPPORTUNITY).
 * @param {string} impactQual - Qualitative impact key.
 * @returns {number|null} - Attribute severity for the given prob/impact combination.
 */
const getAttrSeverity = (probRiskScale, impactRiskScale, probQual, impactType, impactQual) => {
  if(!impactType || impactType === 'NONE') return null
  const probIdx = probRiskScale.findIndex(({ key }) => key === probQual)
  const impactIdx = impactRiskScale.findIndex(({ key }) => key === impactQual)
  return ((impactType === 'THREAT' ? 1 : -1) *
    (probIdx + 1) / probRiskScale.length * (impactIdx + 1) / impactRiskScale.length
  )
}

/**
 * Validates a client risk update.  Clients are only expected to update
 * certain values; the completeRiskUpdate function is designed to fill
 * in updates for derived values.  For example, the client can request
 * an update to the qualitative cost impact, which will result in a change
 * in minimum cost impact, but the client can't request a change to minimum
 * cost impact directly.  Likewise, certain combinations are not allowed.
 * For example, you can only update qualitative probability OR quantitative
 * probability but not both at once (since they need to be kept in sync).
 *
 * @param {object} update - A risk update object.
 * @throws An error if any derived properties or invalid property combinations
 *  are include in the update.
 */
function validateRiskUpdate(update) {
  const derivedRiskProps = [
    'severity.total',
    'severity.cost',
    'severity.time',
    'severity.perf',
    'impact.cost.quant.min',
    'impact.cost.quant.max',
    'impact.cost.quant.ev',
    'impact.time.quant.min',
    'impact.time.quant.max',
    'impact.time.quant.ev',
    'impact.perf.quant.min',
    'impact.perf.quant.max',
    'impact.perf.quant.ev',
  ]
  const invalidPropCombos = [
    ['prob.qual', 'prob.quant'],
    ['impact.cost.qual', 'impact.cost.ml'],
    ['impact.time.qual', 'impact.time.ml'],
    ['impact.perf.qual', 'impact.perf.ml'],
  ]
  if(derivedRiskProps.some(path => _has(update, path))) {
    throw new Error('update object contains derived properties')
  }
  if(invalidPropCombos.some(paths => paths.every(path => _has(update, path)))) {
    throw new Error('update object contains invalid combination')
  }
}

function riskGetFactory(risk, update) {
  return path => {
    let v = undefined
    const isQuant = costFields.includes(path) || timeFields.includes(path) || perfFields.includes(path)
    v =
      _get(update, path,
        _get(risk, isQuant ? path + '.value' : path))
    return v
  }
}

/**
 * Given a risk, context, and update object, this function will return a new
 * update object that "completes" the update according to the interrelationship
 * rules of risk properties (see https://vms.atlassian.net/wiki/spaces/VMSPRO3/pages/301039617/Sound+Transit+MVP#1.6.4-Behavior-of-Interrelated-Risk-Properties).
 *
 * To enforce the data relationships, this function relies on being called with
 * updates ONLY against "source" properties; that is, non-derived properties.
 * For example, you can update the qualitative impact for an attribute, which in
 * turn will update the min and max quantitative values for that impact, but you
 * can't set those min and max values directly.  Also, qual/quant updates cannot
 * be combined.  For example, you can EITHER set qualitative probability OR
 * quantitative probability but not both at the same time (to ensure they stay
 * in sync).
 *
 * @example
 *   // given "risk" and "context" objects (project cost: $100)
 *   completeRiskUpdate(risk, { 'prob.qual': 'M' }, context)
 *     // returns { 'prob.qual': M, 'prob.quant': 0.5 }
 *   completeRiskUpdate(risk, { 'impact.cost.qual': 'M' }, context)
 *     // returns {
 *     //   'impact.cost.qual': 'M',
 *     //   'impact.cost.quant.min': { base: 'cost', unit: 'USD', value: 4 },
 *     //   'impact.cost.quant.ml': { base: 'cost', unit: 'USD', value: 5 },
 *     //   'impact.cost.quant.max': { base: 'cost', unit: 'USD', value: 6 },
 *     //   'impact.cost.quant.ev': { base: 'cost', unit: 'USD', value: 2.5 },
 *     // }
 *
 * @params {object} risk - Risk object.
 * @params {object} update - Risk update.
 * @params {object} context - Effective risk context.
 * @returns {object} - A new update object containing, in addition to the original update
 *  properties, any derived or related updates.
 */
function completeRiskUpdate(risk, update, context) {
  validateRiskUpdate(update)

  const contextCost = context.attributes.cost
  const contextTime = context.attributes.time
  const contextPerf = context.attributes.perf
  if(!contextCost || !contextCost.value) throw new Error('context missing required cost')
  if(!contextTime || !contextTime.value) throw new Error('context missing required time')

  // we'll need these to denomralize at the end
  const costUnit = contextCost.value.unit
  const durationUnit = contextTime.value.unit

  // normalize cost and time objects...this just makes manipulation much easier
  update = normalizeUpdate(update)
  // get normalized values for attributes
  const attrValues = {
    cost: contextCost.value.value,
    time: contextTime.value.value,
    perf: contextPerf.value.value,
  }

  // explicitly updating a managed value will set its "linked" value to false
  if(_has(update, 'managed.prob.qual') || _has(update, 'managed.prob.quant')) {
    update['managed.prob.linked'] = false
  }
  for(const attr of ['cost', 'time', 'perf']) {
    const prefix = `managed.impact.${attr}.`
    if(Object.keys(update).some(k => k.startsWith(prefix) && k !== `${prefix}linked`)) {
      update[`${prefix}linked`] = false
    }
  }

  // update prob
  for(const prefix of ['', 'managed.']) {
    if(_has(update, `${prefix}prob.qual`)) {
      const [min, max] = getQuantRange(context.riskScales.prob, _get(update, `${prefix}prob.qual`))
      update[`${prefix}prob.quant`] = (min + max) / 2
    } else if(_has(update, `${prefix}prob.quant`)) {
      const probQual = getQualFromQuant(context.riskScales.prob, _get(update, `${prefix}prob.quant`))
      // update only if qual range changed
      if(probQual !== _get(risk, `${prefix}prob.qual`)) update[`${prefix}prob.qual`] = probQual
    }
  }

  // update risk impacts
  for(const prefix of ['', 'managed.']) {
    for(const attr of ['cost', 'time', 'perf']) {
      const qualPath = `${prefix}impact.${attr}.qual`
      const mlPath = `${prefix}impact.${attr}.quant.ml`
      if(_has(update, qualPath)) {
        const [min, max] = getQuantRange(context.riskScales[attr], _get(update, qualPath))
        update[mlPath] = (min + max) / 2 * attrValues[attr]
      } else if(_has(update, mlPath)) {
        const mlPct = _get(update, mlPath) / attrValues[attr]
        const qual = getQualFromQuant(context.riskScales[attr], mlPct)
        if(qual !== _get(risk, qualPath)) update[qualPath] = qual
      }
    }
  }

  // we want to get risk values as if they had already been updated...so we
  // try the update value first, and only go to the risk if it hasn't been set.
  // also, we need to normalize risk values
  const riskGet = riskGetFactory(risk, update)

  // update derived values as necessary, starting with impacts...
  for(const prefix of ['', 'managed.']) {
    for(const attr of ['cost', 'time', 'perf']) {
      // convenience assignments
      const impactTypePath = `${prefix}impact.${attr}.type`
      const probQuantPath = `${prefix}prob.quant`
      const impactQualPath = `${prefix}impact.${attr}.qual`
      const minPath = `${prefix}impact.${attr}.quant.min`
      const mlPath = `${prefix}impact.${attr}.quant.ml`
      const maxPath = `${prefix}impact.${attr}.quant.max`
      const evPath = `${prefix}impact.${attr}.quant.ev`

      const fromImpactType = _get(risk, impactTypePath, 'NONE')
      const impactType = riskGet(impactTypePath) || 'NONE'
      // note that an undefined impact type can happen before it is set
      // for the first time; this is equivalent to an impact type of NONE
      if(!impactType || impactType === 'NONE') {
        if(fromImpactType !== 'NONE') {
          // if the risk *had* an impact type (THREAT or OPPORTUNITY), and now
          // it's going to NONE, we need to clear the other impact values
          // (see https://vms.atlassian.net/browse/VP3-1852 for some history
          // on this)
          update[impactQualPath] = null
          update[minPath] = null
          update[mlPath] = null
          update[maxPath] = null
          update[evPath] = null
        }
        // in either event, we need to go no further...there are no further
        // impact changes to process
        continue
      }

      // if, on the other hand, impact type is set, and the qual value isn't (which will
      // happen when the impact type is set for the first time), we default the qual
      // impact, which will have the desired cascade effects
      if(!riskGet(impactQualPath)) {
        update[impactQualPath] = getDefaultScaleValue(context.riskScales[attr])
      }

      // note the order here is important; we need to do min/max before ev

      // qual impact -> min, ml, max
      const qualImpact = _get(update, impactQualPath)
      if(qualImpact) {
        const [min, max] = getQuantRange(context.riskScales[attr], qualImpact)
        update[minPath] = min * attrValues[attr]
        // only update ml if it isn't already in the update
        if(!_has(update, mlPath)) update[mlPath] = (min + max) / 2 * attrValues[attr]
        update[maxPath] = max * attrValues[attr]
      }
      // probQual, min, ml, max -> ev
      if(_has(update, probQuantPath) || _has(update, minPath) || _has(update, mlPath) || _has(update, maxPath)) {
        const min = riskGet(minPath)
        const ml = riskGet(mlPath)
        const max = riskGet(maxPath)
        const probQuant = riskGet(probQuantPath)
        update[evPath] = probQuant * (min + ml * 4 + max) / 6
      }
    }
  }

  // for any managed overlay that's still linked, update it if its underlying value has changed (note
  // we need to do this before we calculate severity...which is *effectively* linked because its a
  // derived value).
  const keys = Object.keys(update)
  if(riskGet('managed.prob.linked')) {
    keys
      .filter(k => k.startsWith('prob.') && k !== 'prob.linked')
      .forEach(k => update['managed.' + k] = update[k])
  }
  for(const attr of ['cost', 'time', 'perf']) {
    if(!riskGet(`managed.impact.${attr}.linked`)) continue
    keys
      .filter(k => k.startsWith(`impact.${attr}.`) && k !== `impact.${attr}.linked`)
      .forEach(k => update['managed.' + k] = update[k])
  }

  // attribute severity
  for(const prefix of ['', 'managed.']) {
    for(const attr of ['cost', 'time', 'perf']) {
      const probQualPath = `${prefix}prob.qual`
      const impactQualPath = `${prefix}impact.${attr}.qual`
      const impactTypePath = `${prefix}impact.${attr}.type`
      if(_has(update, probQualPath) || _has(update, impactQualPath) || _has(update, impactTypePath)) {
        const probQual = riskGet(probQualPath)
        const impactQual = riskGet(impactQualPath)
        const impactType = riskGet(impactTypePath)
        update[`${prefix}severity.${attr}`] =
          getAttrSeverity(context.riskScales.prob, context.riskScales[attr], probQual, impactType, impactQual)
      }
    }
  }

  // total severity
  for(const prefix of ['', 'managed.']) {
    const costSevPath = `${prefix}severity.cost`
    const timeSevPath = `${prefix}severity.time`
    const perfSevPath = `${prefix}severity.perf`
    if(_has(update, costSevPath) || _has(update, timeSevPath) || _has(update, perfSevPath)) {
      const costSev = riskGet(costSevPath)
      const timeSev = riskGet(timeSevPath)
      const perfSev = riskGet(perfSevPath)
      if(!Number.isFinite(costSev) && !Number.isFinite(timeSev) && !Number.isFinite(perfSev)) {
        update[`${prefix}severity.total`] = null
      } else {
        update[`${prefix}severity.total`] =
          (costSev || 0) * contextCost.weight +
          (timeSev || 0) * contextTime.weight +
          (perfSev || 0) * contextPerf.weight
      }
    }
  }

  return denormalizeUpdate(update, costUnit, durationUnit)
}

/**
 * Flatten a risk object into a single level object.
 *
 * NOTE: This function does NOT flatten our custom objects (Cost, Duration, and HTML)
 * as these are valid in our system.
 *
 * Example:
 *  const risk = {
 *    name: 'test',
 *    impact: {
 *      cost: {
 *        qual: 'H',
 *        quant: {
 *          min: { ...costObj },
 *          ml: { ...costObj },
 *          max: { ...costObj },
 *          ev: { ...costObj },
 *        }
 *      }
 *    }
 *  }
 *
 *  flattenRisk(risk) returns:
 *  {
 *    name: 'test',
 *    impact.cost.qual: 'H',
 *    impact.cost.quant.min: { ...costObj },
 *    impact.cost.quant.ml: { ...costObj },
 *    impact.cost.quant.max: { ...costObj },
 *    impact.cost.quant.ev: { ...costObj },
 *  }
 *
 * @param {Object} o - the risk object to flatten
 * @param {String} [pk] - parent key string to prepend to the flattened key
 */
function flattenRisk(o, pk) {
  return Object.assign({}, ...Object.keys(o)
    .map(k => {
      const key = (pk ? pk + '.' : '') + k
      // Check if object exists, is a type object, and is not a quantity or other $type object
      return (o[k] && typeof o[k] === 'object' && !Quantity.isQuantity(o[k]) && !o[k].$type
        ? flattenRisk(o[k], key)
        : ({ [key]: o[k] })
      )
    })
  )
}

/**
 * @param {number} sev - severity of a risk
 * @returns {string} hexcode color representing the level of severity or opportunity.
 */
const getSeverityColor = sev => sev < 0
  ? sev > -0.18
    ? RiskColor.OPPORTUNITY_LOW
    : sev > -0.38
      ? RiskColor.OPPORTUNITY_MED
      : RiskColor.OPPORTUNITY_HIGH
  : sev > 0
    ? sev < 0.18
      ? RiskColor.THREAT_LOW
      : sev < 0.38
        ? RiskColor.THREAT_MED
        : RiskColor.THREAT_HIGH
    : Color.Grey_2_LIGHT

/**
 * Given a qualitative probablity and impact, and impact type, returns a quantitative severity score.
 *
 * @param {object} context - The risk context (must include probability and impact scales)
 * @param {string} probQual - The qualitative probability (from scale)
 * @param {string} impactAttr - The impact attribute (cost, time, perf, etc)
 * @param {string} impactType - The impact type (threat/opportunity/none)
 * @param {string} impactQual - The qualitative impact (from scale)
 * @returns {number} - The severity; a number between -1(strong opportunity) and 1(strong threat). 0 represents
 * neither a threat nor an opportunity
 */
const getSeverity = (context, probQual, impactAttr, impactType, impactQual) => {
  const probN = context.riskScales.prob.findIndex(e => e.key === probQual) + 1
  const impactN = context.riskScales[impactAttr].findIndex(e => e.key === impactQual) + 1
  if(probN === 0) throw new Error(`invalid qualitative probability: ${probQual}`)
  if(impactN === 0) throw new Error(`invalid qualitative ${impactAttr} impact: ${impactQual}`)

  const probLength = context.riskScales.prob.length
  const impactLength = context.riskScales[impactAttr].length

  const severity = probN / probLength * impactN / impactLength

  return impactType === 'THREAT'
    ? severity
    : impactType === 'OPPORTUNITY'
      ? -severity
      : 0

}

/**
 * Given a context and a risk, will recompute all quantitative values from the
 * context and the qualitative values.  That is:
 *
 *   [managed.]prob.qual + context -> [managed.]prob.quant
 *   [managed.]impact.*.qual + context -> impact.*.quant
 *
 * This function is for use when the risk context changes, and cached risk values
 * must be updated.  Note that this will reset impact values as functions of
 * qualitative values.  That is, no attempt will be made to back qualitative values
 * out from quantiative values, or interpolate ml.
 *
 * @param {object} context - The effective risk context; quantiative impact values
 *  will be recomputed from this context.
 * @param {object} risk - A risk to update.
 * @returns {object} - An object containing an update object (property "update"),
 *   and a warnings array (property "warnings").  Warnings will be issued when
 *   the risk have a ml value that's outside of its min and max value, indicating
 *   that the user had chosen a custom value outside of the band.  Note that no
 *   such warning will be issued if ml is between min and max, even though the user
 *   may have updated it.
 */
const recomputeQuantValues = (context, risk) => {
  const update = {}
  const warnings = []

  // we'll need these to denomralize at the end
  const costUnit = context.attributes.cost.value.unit
  const durationUnit = context.attributes.time.value.unit

  const attrValues = {
    cost: context.attributes.cost.value.value,
    time: context.attributes.time.value.value,
    perf: context.attributes.perf.value.value,
  }

  // get risk value, respecting overly rules, and normalizing cost & time fields
  const riskGet = riskGetFactory(risk, update)

  for(const prefix of ['', 'managed.']) {
    const probQualPath = `${prefix}prob.qual`
    const probQuantPath = `${prefix}prob.quant`
    const probQual = riskGet(probQualPath)

    const [rmin, rmax] = getQuantRange(context.riskScales.prob, probQual)
    update[probQuantPath] = (rmin + rmax) / 2

    for(const attr of ['cost', 'time', 'perf']) {
      // skip attributes that aren't present (note that we need to use
      // regular _get here, not riskGet...otherwise we'll get unwelcome
      // values in the update!)
      if(!_get(risk, `${prefix}impact.${attr}`)) continue

      // convenience assignments
      const impactTypePath = `${prefix}impact.${attr}.type`
      const impactQualPath = `${prefix}impact.${attr}.qual`
      const minPath = `${prefix}impact.${attr}.quant.min`
      const mlPath = `${prefix}impact.${attr}.quant.ml`
      const maxPath = `${prefix}impact.${attr}.quant.max`
      const evPath = `${prefix}impact.${attr}.quant.ev`

      if(riskGet(impactTypePath) === 'NONE') {
        update[minPath] = null
        update[mlPath] = null
        update[maxPath] = null
        update[evPath] = null
        continue
      }

      const qualImpact = riskGet(impactQualPath)
      const probQuant = riskGet(probQuantPath)

      const [rmin, rmax] = getQuantRange(context.riskScales[attr], qualImpact)

      if(riskGet(mlPath) < riskGet(minPath) || riskGet(mlPath) >= riskGet(maxPath)) {
        warnings.push(
          `${prefix ? prefix + ' ' : ''}${attr} most likely (ml) ` +
          `value set outside the band established by qualitative scale; ` +
          `value will be updated to the median of the qualitative scale.`
        )
      }

      const min = rmin * attrValues[attr]
      const ml = (rmin + rmax) / 2 * attrValues[attr]
      const max = rmax * attrValues[attr]
      update[minPath] = min
      update[mlPath] = ml
      update[maxPath] = max
      update[evPath] = probQuant * (min + ml * 4 + max) / 6
    }
  }

  return {
    update: denormalizeUpdate(update, costUnit, durationUnit),
    warnings,
  }
}

const RiskSeverityClass = {
  Low: {
    key: 'L',
    label: 'Low',
  },
  Medium: {
    key: 'M',
    label: 'Medium',
  },
  High: {
    label: 'High',
    key: 'H',
  },
}

const getSeverityClass = sev => {
  if(typeof sev !== 'number') return null
  sev = Math.abs(sev)
  return (sev < 0.18
    ? RiskSeverityClass.Low
    : sev < 0.38
      ? RiskSeverityClass.Medium
      : RiskSeverityClass.High
  )
}

/**
 * Factory to create a "risk category labeler", which will take a category value,
 * combine it with its cateogry ancestry, and construct a label that includes
 * its ancestry.
 *
 * The element separator defaults to ':' for short labels and ' : ' for long labels.
 *
 * Note: values marked as deleted (isDeleted is truthy) will result in a label of
 *   an empty string.
 *
 * @example
 *
 *   const categoryValues = [
 *     { value: 'c0', label: { long: 'Environmental', short: 'Env' }, ancestry: '/' },
 *     { value: 'c1', label: { long: 'Soil', short: 'Soil' }, ancestry: '/c0' },
 *     { value: 'c0', label: { long: 'Water', short: 'Water' }, ancestry: '/c0' },
 *   ]
 *   const getRiskCategoryLabel = getRiskCategoryLabelFactory(categoryValues)
 *   getRiskCategoryLabel('c1')                  // "Env:Soil"
 *   getRiskCategoryLabel('c1', 'short', '>')    // "Env>Soil"
 *   getRiskCategoryLabel('c1', 'long')          // "Environmental : Soil"
 *   getRiskCategoryLabel('c1', 'long', ' > ')   // "Environmental > Soil"
 *
 * @param {object[]} categoryValues - The category values array (see types.category.values
 *   in a risk context).
 * @returns {(categoryValue: string, labelType: 'short'|'long' = 'short', sep?: string) => string} -
 *   function that can take a category value (usually a UUID) and return a string that includes the
 *   labels of its ancestors.  See examples above.  If the category value is falsy, the the label
 *   generator will return an empty string.
 */
const getRiskCategoryLabelFactory = categoryValues => {
  const categoriesByValue = _keyBy(categoryValues, 'value')
  return (categoryValue, labelType = 'short', sep) => {
    if(!categoryValue || !categoriesByValue[categoryValue] || categoriesByValue[categoryValue].isDeleted) return ''
    sep = sep || (labelType === 'short' ? ':' : ' : ')
    return [...categoriesByValue[categoryValue].ancestry.split('/').filter(Boolean), categoryValue]
      .filter(Boolean)
      .map(ancestorCategoryValue => categoriesByValue[ancestorCategoryValue].label[labelType])
      .join(sep)
  }
}

export {
  createDefaultRiskScale,
  createRootRiskContext,
  createOverlayRiskContext,
  createDemoEffectiveRiskContext,
  flattenRisk,
  getEffectiveRiskContext,
  getSeverity,
  getSeverityClass,
  getSeverityColor,
  completeRiskUpdate,
  recomputeQuantValues,
  getRiskCategoryLabelFactory,
}
