import { createSelector } from 'reselect'

import { RootState } from '../store'

export const selectUsers = createSelector(
  (state: RootState) => state.users.ids,
  (state: RootState) => state.users.byId,
  (usersIds, usersById) => usersIds.map(id => usersById[id])
)

export const selectActiveUsers = createSelector(
  selectUsers,
  users => users.filter(user => !user.disabled)
)
