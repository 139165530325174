import { ReactElement, useEffect } from 'react'
import { ROOT_DECISION_NODE_ID } from '@vms/vmspro3-core/dist/systemConsts'
import { Spin } from 'antd'

import { Page } from '../../common/Page'
import { DecisionFolderChildren } from './DecisionFolderChildren'

import { useQuerystringValue } from '../../../hooks/useQuerystringValue'
import { useAppDispatch, useAppSelector } from '../../../redux'
import { fetchDecisionEntity } from '../../../redux/actions'
import { useAccount } from '../../../context'
import {
  useDecisionFolder,
  useDecisionFolderBreadcrumbRoutes,
  useLoadDecisionFolderChildren,
} from '../../../redux/hooks'

interface DecisionExplorerProps {
  accountCommonId: string,
  accountId: string,
  decisionFolderId: string,
}
function DecisionExplorer({ accountCommonId, accountId, decisionFolderId }: DecisionExplorerProps): ReactElement {

  const decisionFolder = useDecisionFolder(decisionFolderId)
  const childrenLoading = useLoadDecisionFolderChildren(accountId, decisionFolderId)
  const breadcrumbRoutes = useDecisionFolderBreadcrumbRoutes(accountCommonId, decisionFolderId)

  return (
    <Page loading={childrenLoading} title={decisionFolder.name} breadcrumbRoutes={breadcrumbRoutes}>
      <DecisionFolderChildren accountCommonId={accountCommonId} decisionFolderId={decisionFolderId} />
    </Page>
  )
}

export function DecisionFolderPage(): ReactElement {
  const [decisionFolderId] = useQuerystringValue('folder', ROOT_DECISION_NODE_ID)
  const { accountId, accountCommonId } = useAccount()

  const decisionFolderStatus = useAppSelector(state => state.decisionFolders[decisionFolderId]?.status ?? 'Idle')

  const dispatch = useAppDispatch()
  useEffect(
    () => {
      if(decisionFolderStatus === 'Idle') {
        dispatch(fetchDecisionEntity(accountId, decisionFolderId, 'DecisionFolder'))
      }
    },
    [dispatch, accountId, decisionFolderId, decisionFolderStatus]
  )

  if(decisionFolderStatus !== 'Success') {
    return <Spin />
  }

  return (
    <DecisionExplorer
      accountCommonId={accountCommonId}
      accountId={accountId}
      decisionFolderId={decisionFolderId}
    />
  )
}
