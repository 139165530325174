import { useCallback, useMemo, ReactElement } from 'react'
import { Column } from 'react-table'
import { Link } from 'react-router-dom'
import { UnreachableCaseError } from 'ts-essentials'
import { createSelector } from 'reselect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { FolderAddOutlined, PlusOutlined } from '@ant-design/icons'
import { Dropdown, Button, Menu, Modal, Tooltip } from 'antd'

import { Html } from '@vms/vmspro3-core/dist/types'
import { deleteDecision } from '@vms/vmspro3-core/dist/actions/decision'

import { DecisionNodeCreateModal } from './DecisionNodeCreateModal'
import { DecisionEditModal } from './DecisionEditModal'
import { DecisionFolderDeleteModal } from './DecisionFolderDeleteModal'
import { DecisionFolderEditModal } from './DecisionFolderEditModal'
import { Table, TableCellRenderer } from '../../common/Table'

import { useAppDispatch, useAppSelector } from '../../../redux'
import { useDecisionFolderChildAncestry } from '../../../redux/hooks'
import { getSelectLoadableChildDecisionFolders, getSelectLoadableChildDecisions } from '../../../redux/selectors'
import { useModalState } from '../../../hooks/useModalState'
import { testId } from '../../../test-automation'

type DecisionFolderChildNode = {
  id: string,
  entityType: 'Decision' | 'DecisionFolder',
  typeLabel: 'Decision' | 'Decision Folder',
  name: string,
  optionsCount?: number,
  description?: Html,
}

type DecisionFolderProps = {
  accountCommonId: string,
  decisionFolderId: string,
}
export function DecisionFolderChildren({ accountCommonId, decisionFolderId }: DecisionFolderProps): ReactElement {
  const childAncestry = useDecisionFolderChildAncestry(decisionFolderId)

  const columns = useMemo<Column<DecisionFolderChildNode>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: cell => {
          const entity = cell.row.original
          switch(entity.entityType) {
            case 'Decision': {
              return <Link to={`/${accountCommonId}/decision/${entity.id}`}>{entity.name}</Link>
            }
            case 'DecisionFolder': {
              return <Link to={`/${accountCommonId}/decision?folder=${entity.id}`}>{entity.name}</Link>
            }
            default: {
              throw new UnreachableCaseError(entity.entityType)
            }
          }
        },
      },
      {
        Header: 'Type',
        accessor: 'typeLabel',
      },
      {
        Header: 'Description',
        accessor: 'description',
        disableSortBy: true,
        Cell: TableCellRenderer.Html,
      },
      {
        Header: 'Options',
        accessor: 'optionsCount',
        sortType: 'numeric',
        align: 'right',
      },
    ],
    [accountCommonId]
  )

  const decisionFolderChildNodes = useAppSelector(useMemo(
    () => createSelector(
      getSelectLoadableChildDecisionFolders(decisionFolderId),
      getSelectLoadableChildDecisions(decisionFolderId),
      (decisionFolders, decisions) => {
        const childNodes: DecisionFolderChildNode[] = []
        decisionFolders.forEach(decisionFolder => {
          childNodes.push({
            id: decisionFolder.data.id,
            entityType: decisionFolder.data.entityType,
            name: decisionFolder.data.name,
            typeLabel: 'Decision Folder',
          })
        })
        decisions.forEach(decision => {
          childNodes.push({
            id: decision.data.id,
            entityType: decision.data.entityType,
            name: decision.data.name,
            description: decision.data.description,
            typeLabel: 'Decision',
            optionsCount: decision.children && (decision.children?.options?.length ?? 0),
          })
        })
        return childNodes
      }
    ),
    [decisionFolderId]
  ))

  const dispatch = useAppDispatch()

  const { modal, showModal, hideModal } = useModalState()

  const showDecisionNodeCreateModal = useCallback(
    (decisionNodeType: 'Decision' | 'DecisionFolder') => showModal(
      <DecisionNodeCreateModal
        hide={hideModal}
        ancestry={childAncestry}
        decisionNodeType={decisionNodeType}
      />
    ),
    [showModal, hideModal, childAncestry]
  )

  return (
    <>
      {modal}
      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '12px',
          justifyContent: 'flex-end',
        }}
      >
        <Dropdown
          placement="bottomRight"
          overlay={(
            <Menu theme="dark">
              <Menu.Item
                {...testId('folder')}
                key="DecisionFolder"
                icon={<FolderAddOutlined />}
                onClick={() => showDecisionNodeCreateModal('DecisionFolder')}
              >
                Decision Folder
              </Menu.Item>
              <Menu.Item
                {...testId('decision')}
                key="Decision"
                icon={<PlusOutlined />}
                onClick={() => showDecisionNodeCreateModal('Decision')}
              >
                Decision
              </Menu.Item>
            </Menu>
          )}
        >
          <Button type="primary" icon={<PlusOutlined />}>
            Create
          </Button>
        </Dropdown>
      </div>
      <Table<DecisionFolderChildNode>
        columns={columns}
        data={decisionFolderChildNodes}
        rowControls={decisionNode => (
          <>
            <Tooltip title="Edit">
              <FontAwesomeIcon
                aria-label="Edit"
                icon={faEdit}
                onClick={() => decisionNode.entityType === 'DecisionFolder'
                  ? showModal(
                    <DecisionFolderEditModal
                      decisionFolderId={decisionNode.id}
                      hide={hideModal}
                    />
                  )
                  : showModal(
                    <DecisionEditModal
                      decisionId={decisionNode.id}
                      hide={hideModal}
                    />
                  )
                }
              />
            </Tooltip>
            <Tooltip title="Delete">
              <FontAwesomeIcon
                aria-label="Delete"
                icon={faTrashAlt}
                onClick={() => decisionNode.entityType === 'DecisionFolder'
                  ? showModal(
                    <DecisionFolderDeleteModal
                      decisionFolderId={decisionNode.id}
                      hide={hideModal}
                    />
                  )
                  : Modal.confirm({
                      title: `Deleting ${decisionNode.name}`,
                      content: `Are you sure you want to delete ${decisionNode.name}?`,
                      onOk: () => {
                        dispatch(deleteDecision(decisionNode.id, decisionFolderId))
                      },
                    })
                }
              />
            </Tooltip>
          </>
        )}
      />
    </>
  )
}
