import { ReactElement } from 'react'

import { AdHocAccountProvider } from '../../../context/AdHocAccountContext'
import { AdHocParticipantProvider } from '../../../context/AdHocParticipantContext'
import { AdHocDecisionProvider } from '../../../context/AdHocDecisionContext'

interface AdHocParticipationProps {
  accountCommonId: string,
  decisionId: string,
  participationSessionId: string,
  children: ReactElement,
}
export function AdHocParticipationProviders({
  accountCommonId,
  decisionId,
  participationSessionId,
  children,
}: AdHocParticipationProps) {
  return (
    <AdHocAccountProvider accountCommonId={accountCommonId}>
      <AdHocParticipantProvider decisionId={decisionId} participationSessionId={participationSessionId}>
        <AdHocDecisionProvider decisionId={decisionId}>
          {children}
        </AdHocDecisionProvider>
      </AdHocParticipantProvider>
    </AdHocAccountProvider>
  )
}
