import { useMemo, ReactElement } from 'react'
import { Tabs, Empty } from 'antd'

import { Ratings } from '../../../common/Ratings/Ratings'
import { CycleSelect } from '../../../common/CycleSelect'
import { ContextRatingNotes } from '../CriteriaRatings/ContextRatingNotes'
import { OptionRatingsForOptionTable } from './OptionRatingsForOptionTable'
import { OptionRatingsForCriterionTable } from './OptionRatingsForCriterionTable'
import { OptionRatingNotesForCriterionTable } from './OptionRatingNotesForCriterionTable'
import { StatsLegend } from '../../../common/StatsLegend'

import { useQuerystringValue } from '../../../../hooks/useQuerystringValue'
import {
  useLeafCriterionLabel,
  useLeafCriteriaSelectOptions,
  useOptionSelectOptions,
  useParticipationSessionId,
  useRootPerfCriterionId,
} from '../../../../redux/hooks'

const maximumFractionDigits = 1
const formatter = Intl.NumberFormat(undefined, {
  minimumFractionDigits: maximumFractionDigits,
  maximumFractionDigits,
})

export type OptionRatingsProps = {
  decisionId: string,
}
export function OptionRatings({ decisionId }: OptionRatingsProps): ReactElement {
  const [tab, setTab] = useQuerystringValue('ratings-tab', 'option')

  const optionSelectOptions = useOptionSelectOptions(decisionId)
  const [optionId, setOptionId] = useQuerystringValue('optionId', optionSelectOptions[0]?.value)

  const participationSessionId = useParticipationSessionId(decisionId, 'OptionRating')
  const rootPerformanceCriterionId = useRootPerfCriterionId(decisionId)
  const leafCriteriaSelectOptions = useLeafCriteriaSelectOptions(decisionId, rootPerformanceCriterionId)
  const [criterionId, setCriterionId] = useQuerystringValue('criterionId', leafCriteriaSelectOptions[0].value)
  const selectedCriterionLabel = useLeafCriterionLabel(decisionId, criterionId, rootPerformanceCriterionId)

  const pivotOptions = useMemo(
    () => optionSelectOptions.map(o => ({ id: o.value, label: o.label })),
    [optionSelectOptions]
  )

  if(optionSelectOptions.length === 0) {
    return (
      <Empty
        description={(
          <div>
            <h2>No Options</h2>
            <p>In order to have option rating results, you must have at least one option.</p>
          </div>
        )}
      />
    )
  }

  return (
    <Tabs tabPosition="left" activeKey={tab} onChange={setTab}>
      <Tabs.TabPane tab="By Option" key="option">
        <CycleSelect
          label="Option"
          selectOptions={optionSelectOptions}
          value={optionId}
          onChange={setOptionId}
        />
        <OptionRatingsForOptionTable decisionId={decisionId} optionId={optionId} />
        <StatsLegend formatter={formatter} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="By Criterion" key="criterion">
        <CycleSelect
          label="Criterion"
          selectOptions={leafCriteriaSelectOptions}
          value={criterionId}
          onChange={setCriterionId}
        />
        <OptionRatingsForCriterionTable decisionId={decisionId} criterionId={criterionId} />
        <div style={{ margin: '24px 0' }}>
          <ContextRatingNotes
            decisionId={decisionId}
            participationSessionId={participationSessionId}
            participantId="*"
            contextId={criterionId}
            contextLabel={`${selectedCriterionLabel} notes`}
            subjectType="Option"
          />
        </div>
        <OptionRatingNotesForCriterionTable decisionId={decisionId} criterionId={criterionId} />
        <StatsLegend formatter={formatter} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Participant Ratings" key="participant">
        <Ratings
          decisionId={decisionId}
          pivotType="subject"
          pivotOptions={pivotOptions}
          subjectType="Option"
          pivotLabel="Option"
          pivotIdQuerystringKey="optionId"
        />
      </Tabs.TabPane>
    </Tabs>
  )
}
