import React from 'react'
import { Col, Row, Slider } from 'antd'

import { NumberInput_Risk, Table } from '../controls'
import { testId } from '../../../test-automation'

const RiskAttributeWeights = ({
  attributes,
  readOnly,
  updateAttributeWeights,
  weightsTotal,
  weightsValid,
}) => {
  const attrWeightRows = Object.values(attributes).map(a => ({
    title: a.label.long,
    key: a.key,
    value: a.weight,
  }))

  const attrWeightColumns = [
    {
      title: 'Attribute',
      dataIndex: 'title',
    },
    {
      title: 'Weight',
      dataIndex: 'value',
      render: (value, { key }) => (
        <Row gutter={24} type="flex" align="middle">
          <Col span={16}>
            <Slider
              max={100}
              min={0}
              step={0.01}
              onChange={v => updateAttributeWeights(key, v / 100)}
              value={value * 100}
              disabled={readOnly}
            />
          </Col>
          <Col span={8} style={style.alignRight}>
            {readOnly
              ? (value * 100).toFixed(2) + '%'
              : (
                <NumberInput_Risk
                  {...testId(`${key}-weight-num-input`)}
                  value={value * 100}
                  decimalPlaces={2}
                  onChange={v => updateAttributeWeights(key, v / 100)}
                  addonAfter="%"
                />
              )
            }
          </Col>
        </Row>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      align: 'center',
      width: '100px',
    },
  ]

  return (
    <Table
      size="middle"
      columns={attrWeightColumns}
      dataSource={attrWeightRows}
      rowKey="key"
      onCell={(_, idx) => idx === 0
        ? {
          children: (
            <span {...testId('total')} style={style.total(weightsValid)}>
              {(weightsTotal * 100).toFixed()}%
            </span>
          ),
          props: {
            rowSpan: attrWeightRows.length,
          },
        }
        : { props: { rowSpan: 0 } }
      }
    />
  )
}

const style = {
  alignRight: { textAlign: 'right' },
  total: valid => ({
    color: valid ? 'green' : 'red',
    fontWeight: '700',
  }),
}

export default RiskAttributeWeights
