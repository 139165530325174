import { useState, useCallback } from 'react'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Form, Button, Space } from 'antd'

import { actions } from '@vms/vmspro3-core/dist'
import { Product, Subscription } from '@vms/vmspro3-core/dist/types'

import { EndSubscriptionConfirmModal } from './EndSubscriptionConfirmModal'
import { PlanSelectionModal } from './PlanSelectionModal'
import { SubscriptionPlanCard } from '../../common/SubscriptionPlanCard'

import Server from '../../../server/VMSProServerAdapter'
import config from '../../../config.json'
import { useAppDispatch } from '../../../redux'
import { useAugmentAction } from '../../../hooks/useAugmentAction'
import { useModalState } from '../../../hooks/useModalState'
import { useAccount } from '../../../context'

const cardBrandLabel = {
  amex: 'American Express',
  diners: 'Diners Club',    // note no apostrophe in Diners Club brand guidelines
  discover: 'Discover',
  jcb: 'JCB',
  mastercard: 'Mastercard', // note Mastercard brand guidelines do not capitalize the "C"
  unionpay: 'UnionPay',
  visa: 'Visa',
}

interface SubscriptionEditingProps {
  onUpdate: (last4: string | undefined, brand: string | undefined) => void,
  onCancel: () => void,
}
function SubscriptionEditing({ onUpdate, onCancel }: SubscriptionEditingProps) {
  const [errorMsg, setErrorMsg] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  const elements = useElements()
  const stripe = useStripe()

  const { accountId } = useAccount()
  const augmentAction = useAugmentAction(accountId)
  const dispatch = useAppDispatch()

  const onFinish = async () => {
    if(!elements || !stripe) return
    const card = elements.getElement(CardElement)
    setLoading(true)
    if(card) {
      const { paymentMethod, error: stripeError }  = await stripe.createPaymentMethod({ type: 'card', card })
      if(paymentMethod) {
        const action = augmentAction(actions.account.updatePaymentMethod(paymentMethod.id))

        await Server.tryAction(action)
          .catch(err => {
            const msg = err?.response?.data?.message || 'Uknown error; please contact support.'
            setErrorMsg(msg)
            setLoading(false)
          })

        action.meta.ephemeral = true  // it's already been handled!  we don't want isomorphic redux
                                      // sending it to the server again
        dispatch(action)
        const { last4, brand } = paymentMethod?.card ?? {}
        onUpdate(last4, brand)
      } else {
        if(stripeError?.message) setErrorMsg(stripeError.message)
        setLoading(false)
      }
    }
  }

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item
        label="Card Information"
        validateStatus={errorMsg ? 'error' : undefined}
        help={errorMsg}
      >
        <CardElement />
      </Form.Item>
      <Form.Item>
        <Space>
          <Button loading={loading} type="primary" htmlType="submit">Update</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

// TODO: we should probably align with Stripe terminology.  paymentMethod != card; rather,
// a card is a type of payment method
const CardDescription = ({ paymentMethod }: { paymentMethod?: { last4?: string, brand?: string } }) => {
  const brandLabel = paymentMethod?.brand
    ? cardBrandLabel[paymentMethod.brand as keyof typeof cardBrandLabel]
    : undefined

  return (brandLabel
    ? <span>{brandLabel} ending in {paymentMethod?.last4}.</span>
    : <span>No card configured.</span>
  )
}

interface SubscriptionEditorProps {
  subscription: Subscription,
  onSubscriptionChange: (update: Partial<Pick<Subscription, 'paymentMethod' | 'currentMonthlyBill'>>) => void,
}
function SubscriptionEditorComponent({
  subscription,
  onSubscriptionChange,
}: SubscriptionEditorProps) {
  const [editing, setEditing] = useState(false)

  const onUpdatePayment = useCallback(
    (last4: string | undefined, brand: string | undefined) => {
      onSubscriptionChange({ paymentMethod: { last4, brand } })
      setEditing(false)
    },
    [onSubscriptionChange, setEditing]
  )

  const { account } = useAccount()
  const currentProductId = account.productId
  const currentProduct = (config.instance.products as Product[]).find(p => p.id === currentProductId)
  const productIsInternal = currentProduct?.type === 'Internal'

  const { modal, showModal, hideModal } = useModalState()

  const showPlanSelectionModal = useCallback(
    () => showModal(
      <PlanSelectionModal
        onSubscriptionChange={onSubscriptionChange}
        hideModal={hideModal}
      />
    ),
    [showModal, hideModal, onSubscriptionChange]
  )

  if(editing) {
    return <SubscriptionEditing onUpdate={onUpdatePayment} onCancel={() => setEditing(false)} />
  }

  return (
    <>
      {modal}


      <h3>Current Plan</h3>
      {account.status === 'Canceled'
        ? <>
            <p><i>Your account has been canceled, and you are not currently subscribed to any plan.</i></p>
            <Button onClick={showPlanSelectionModal} type="primary">Choose Plan</Button>
          </>
        : currentProduct
          ?
            <SubscriptionPlanCard
              product={currentProduct}
              actionText="Change Plan"
              onAction={showPlanSelectionModal}
            />
          : null
      }
      <h3 style={{ marginTop: '36px' }}>Payment Information</h3>
      {productIsInternal &&
        <p>
          <i>This account is subscribed to a free internal product. No payment method is required.  If you
          wish to switch to a paid acount, you must provide a payment method first.</i>
        </p>
      }
      <CardDescription paymentMethod={subscription.paymentMethod} />
      <div style={{ marginTop: '12px' }}>
        <Space>
          <Button type="primary" onClick={() => setEditing(true)}>Update</Button>
          {!productIsInternal && account.status !== 'Canceled' &&
            <Button
              danger
              onClick={() => showModal(
                <EndSubscriptionConfirmModal hideModal={hideModal} />
              )}
            >
              End Subscription
            </Button>
          }
        </Space>
      </div>
    </>
  )
}

const stripe = loadStripe(config.instance.stripe.publishableKeys.test)

export function SubscriptionEditor(props: SubscriptionEditorProps) {
  return (
    <Elements stripe={stripe}>
      <SubscriptionEditorComponent {...props} />
    </Elements>
  )
}
