import { AccountPolicy, AccountPolicy_App, RiskEntity_App } from '@vms/vmspro3-core/dist/types'
import unflattenDeep from '@vms/vmspro3-core/dist/utils/unflattenDeep'

import Server from '../server/VMSProServerAdapter'

// TODO: resolve "id" vs "policyId" issues
// (risk entity IDs are already mapped on the server-side)
function policiesMapToAppPoliciesMap(
  policies: Record<string, AccountPolicy[]>
): Record<string, AccountPolicy_App[]> {
  return Object.fromEntries(
    Object.entries(policies).map(([key, policies]) => {
      const policies_app = policies.map(({ policyId, ...restPolicy }) => ({
        ...restPolicy,
        id: policyId,
      }))
      return [key, policies_app]
    })
  )
}

export async function getRiskEntity(entityId: string) {
  return Server.getItem(entityId)
    .then(({ entity, ancestors, policiesByResourceId }) => ({
      entity: unflattenDeep(entity) as RiskEntity_App,
      ancestors: unflattenDeep(ancestors) as RiskEntity_App[],
      policiesByResourceId: policiesMapToAppPoliciesMap(policiesByResourceId),
    }))
}

export async function getRiskEntityChildren(childAncestry: string) {
  return Server.getItemsByAncestry(childAncestry)
    .then(({ entities, policiesByResourceId }) => ({
      entities: unflattenDeep(entities) as RiskEntity_App[],
      policiesByResourceId: policiesMapToAppPoliciesMap(policiesByResourceId),
    }))
}
