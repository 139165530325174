import { ReactElement, useCallback, useMemo } from 'react'
import { Checkbox, Form, Input, Modal, Select } from 'antd'
import { gql, useMutation } from '@apollo/client'

import { Rating, Participant } from '@vms/vmspro3-core/dist/types'

import { OptionFieldsFragment, RatingFieldsFragment } from '../../../../graphql'
import { colorSelectOptions } from '../../../../utils/appConsts'
import { SelectOption } from '../../../../types'
import { selectAuthUserId } from '../../../../redux/selectors'
import { useAppSelector } from '../../../../redux'
import { useInitialFocusRef } from '../../../../hooks/useInitialFocusRef'
import { OptionData } from '@vms/vmspro3-core/dist/nextgen/Option'

const requiredStringRule = [
  { required: true, validateTrigger: 'onChange' },
  { whitespace: true, validateTrigger: 'onBlur' },
]

const DUPLICATE_OPTION = gql`
  mutation DuplicateOption(
    $accountId: ID!
    $decisionId: ID!
    $sourceOptionId: ID!
    $input: DuplicateOptionInput!
  ) {
    duplicateOption(
      accountId: $accountId
      decisionId: $decisionId
      sourceOptionId: $sourceOptionId
      input: $input
    ) {
      option { ...OptionFields }
      ratings { ...RatingFields }
    }
  }
  ${OptionFieldsFragment}
  ${RatingFieldsFragment}
`
type DuplicateOptionData = {
  option: OptionData,
  ratings: Rating[],
}
type DuplicateOptionVariables = {
  accountId: string,
  decisionId: string,
  sourceOptionId: string,
  input: Pick<OptionData, 'name' | 'abbrev' | 'color'> & {
    assignRatingsToParticipantId?: string,
  },
}
type OptionFormData = DuplicateOptionVariables['input'] & {
  assignRatings: boolean,
}

export type OptionDuplicateModalProps = {
  accountId: string
  decisionId: string
  sourceOptionId: string
  initialValues: OptionFormData
  participants: Participant[]
  participantSelectOptions: SelectOption[]
  hideModal: VoidFunction,
}
export function OptionDuplicateModal({
  accountId,
  decisionId,
  sourceOptionId,
  initialValues,
  participants,
  participantSelectOptions,
  hideModal,
}: OptionDuplicateModalProps): ReactElement {

  const userId = useAppSelector(selectAuthUserId)
  const userIsParticipant = useMemo(() => (
    !!participants?.some(p => p.userId === userId)
  ), [participants, userId])

  const [form] = Form.useForm<OptionFormData>()

  const [duplicateOptionMutation] = useMutation<DuplicateOptionData, DuplicateOptionVariables>(DUPLICATE_OPTION)
  const onOk = useCallback(
    async () => {
      const { name, abbrev, color, assignRatings, assignRatingsToParticipantId } = await form.validateFields()
      duplicateOptionMutation({
        variables: {
          accountId,
          decisionId,
          sourceOptionId,
          input: {
            name,
            abbrev,
            color,
            assignRatingsToParticipantId: assignRatings ? assignRatingsToParticipantId : undefined,
          },
        },
      })
      hideModal()
    },
    [form, duplicateOptionMutation, accountId, decisionId, sourceOptionId, hideModal]
  )

  return (
    <Modal
      visible
      title="Duplicate Option"
      onOk={onOk}
      onCancel={hideModal}
    >
      <Form form={form} layout="vertical" initialValues={initialValues} requiredMark={false}>
        <Form.Item label="Name" name="name" rules={requiredStringRule}>
          <Input ref={useInitialFocusRef()} />
        </Form.Item>
        <Form.Item label="Abbreviation" name="abbrev" rules={requiredStringRule}>
          <Input />
        </Form.Item>
        <Form.Item label="Color" name="color" rules={[{ required: true }]}>
          <Select>
            {colorSelectOptions.map(o => (
              <Select.Option key={o.value} value={o.value} style={{ display: 'flex' }}>
                <div style={{ width: '100%', height: '100%', padding: '4px 0', display: 'flex', gap: '12px' }}>
                  <div style={{ width: '50px', backgroundColor: o.value }} />
                  <span style={{ alignSelf: 'center' }}>{o.label}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="assignRatings" valuePropName="checked">
          <Checkbox>
            Add ratings set to the group average of the original ratings
          </Checkbox>
        </Form.Item>
        {!userIsParticipant && (
          <p>
            <b>Note</b>: You are not a participant on this decision; if you wish to assign
            group average ratings to yourself, please go back to Criteria Prioritization
            or Option Rating and add yourself as a participant first.
          </p>
        )}
        <Form.Item shouldUpdate={(prevVals, currVals) => prevVals.assignRatings !== currVals.assignRatings}>
          {formInstance => {
            const assignRatings = formInstance.getFieldValue('assignRatings')
            return (
              <Form.Item
                label="Assign ratings to"
                name="assignRatingsToParticipantId"
                rules={assignRatings && requiredStringRule}
              >
                <Select disabled={!assignRatings} options={participantSelectOptions} />
              </Form.Item>
            )
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
}
