import { ReactElement } from 'react'
import { gql, useQuery } from '@apollo/client'

import { Decision, Rating, RatingNotes } from '@vms/vmspro3-core/dist/types'
import { CriterionData } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { OptionData } from '@vms/vmspro3-core/dist/nextgen/Option'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'

import { createTypedContext } from '../utils/createTypedContext'
import { useAdHocParticipant } from './AdHocParticipantContext'
import { useAdHocAccount } from './AdHocAccountContext'
import { QueryResult } from '../components/common/QueryResult'

import {
  DecisionFieldsFragment,
  CriterionFieldsFragment,
  OptionFieldsFragment,
  ParticipationSessionFieldsFragment,
  RatingFieldsFragment,
  RatingNotesFieldsFragment,
} from '../graphql'

const sessionClosedErrorMessage = 'Participation for this decision is currently closed.\n' +
  'Please contact the decision facilitator for more information.'

type AdHocDecisionContext = {
  decisionId: string,
  decision: Decision,
  criteria: CriterionData[],
  options: OptionData[],
  ratings: Rating[],
  ratingNotes: RatingNotes[],
}
const [
  useAdHocDecision,
  ContextProvider,
] = createTypedContext<AdHocDecisionContext>('AdHocDecisionContext')

export { useAdHocDecision }

interface DecisionForParticipantData {
  decisionForParticipant: Decision & {
    criteria: CriterionData[],
    options: OptionData[],
    ratings: Rating[],
    ratingNotes: RatingNotes[],
  }
}
interface DecisionForParticipantVariables {
  accountId: string | undefined,
  decisionId: string,
  participantId: string | undefined,
}
const GET_DECISION_FOR_PARTICIPANT = gql`
  query GetDecisionForParticipant($accountId: ID!, $decisionId: ID!, $participantId: ID!) {
    decisionForParticipant(accountId: $accountId, decisionId: $decisionId, participantId: $participantId) {
      ...DecisionFields
      criteria { ...CriterionFields }
      options { ...OptionFields }
      participationSessions { ...ParticipationSessionFields }
      ratings { ...RatingFields }
      ratingNotes { ...RatingNotesFields }
    }
  }
  ${DecisionFieldsFragment}
  ${CriterionFieldsFragment}
  ${OptionFieldsFragment}
  ${ParticipationSessionFieldsFragment}
  ${RatingFieldsFragment}
  ${RatingNotesFieldsFragment}
`

interface AdHocDecisionProviderProps {
  decisionId: string,
  children: ReactElement,
}
export function AdHocDecisionProvider({
  decisionId,
  children,
}: AdHocDecisionProviderProps) {
  const { accountId } = useAdHocAccount()
  const { participantId } = useAdHocParticipant()

  const decisionForParticipantResult = useQuery<DecisionForParticipantData, DecisionForParticipantVariables>(
    GET_DECISION_FOR_PARTICIPANT,
    {
      variables: {
        accountId,
        decisionId,
        participantId,
      },
    }
  )

  if(decisionForParticipantResult.error?.graphQLErrors
      .some(err => err.message === 'participation session is not active')
  ) {
    return (
      <span>{sessionClosedErrorMessage}</span>
    )
  }

  return (
    <QueryResult {...decisionForParticipantResult}>
      {({ decisionForParticipant: { criteria, options, ratings, ratingNotes, ...decision } }) => (
        <ContextProvider
          value={{
            decisionId,
            decision: {
              ...decision,
              entityType: 'Decision',
              objective: createHtmlObject(decision.objective.value),
              description: createHtmlObject(decision.description.value),
            },
            criteria,
            options,
            ratings,
            ratingNotes,
          }}
        >
          {children}
        </ContextProvider>
      )}
    </QueryResult>
  )
}
