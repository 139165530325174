import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import _groupBy from 'lodash/groupBy'
import { Spin } from 'antd'

import { EntityType } from '@vms/vmspro3-core/dist/systemConsts'
import { joinAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { actions } from '@vms/vmspro3-core/dist'

import ConfirmModal from './ConfirmModal'

import { useModalData } from '../RiskModalContext'
import Server from '../../../server/VMSProServerAdapter'
import { getEntityTypeLabel } from '../utils/getEntityTypeLabel'

/**
 * Delete an entity (excpeting the root portfolio).
 *
 * @prop actionMeta - the custom action metadata - including resource authorization
 * @prop afterDeletePath - path to navigate to after deleting the entity
 * @prop entityName - name of entity being deleted
 * @prop entityAncestry - ancestry of entity being deleted
 */
const EntityDeleteModal = () => {
  const dispatch = useDispatch()
  const {
    actionMeta,
    afterDeletePath,
    entityId,
    entityName,
    entityType,
    entityAncestry,
  } = useModalData(EntityDeleteModal.id)

  const navigate = useNavigate()
  const onOk = () => {
    if(afterDeletePath) navigate(afterDeletePath)
    dispatch(actions.riskEntity.delete(null, { ...actionMeta, entityId, entityType, ancestry: entityAncestry }))
  }

  const [stats, setStats] = useState(null)

  useEffect(() => {
    const descendantAncestry = joinAncestry(entityAncestry, entityId)
    Server.getItemsByAncestryBeginsWith(descendantAncestry, null, ['entityType'])
      .then(({ entities }) => {
        const byType = _groupBy(entities, 'entityType')
        setStats({
          projects: byType[EntityType.PROJECT]?.length || 0,
          risks: byType[EntityType.RISK]?.length || 0,
        })
      })
  }, [entityAncestry, entityId])

  const entityTypeLabel = getEntityTypeLabel(entityType, entityAncestry).toLowerCase()

  return (
    <ConfirmModal id={EntityDeleteModal.id} onOk={onOk} okButtonProps={{ disabled: !stats }}>
      {stats
        ? (
          <>
            <h3>Deleting "{entityName}"</h3>
            {stats.projects
              ? <p>This {entityTypeLabel.toLowerCase()} contains {stats.risks} risk(s)
                 in {stats.projects} project(s); these will also be deleted.  Are you sure?</p>
              : <p>This {entityTypeLabel.toLowerCase()} contains {stats.risks} risk(s);
                 these will also be deleted.  Are you sure?</p>
            }
          </>
        )
        : (
          <>
            <h3>Deleting "{entityName}"</h3>
            <Spin style={{ marginRight: '12px' }} /> Analyzing {entityTypeLabel}...
          </>
        )
      }
    </ConfirmModal>
  )
}
EntityDeleteModal.id = 'EntityDeleteModal'
EntityDeleteModal.defaultProps = {
  actionMeta: {},
}

export default EntityDeleteModal
