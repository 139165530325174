import { Link } from 'react-router-dom'
import { Modal } from 'antd'

import { updateAccountSubscription } from '@vms/vmspro3-core/dist/actions/account/updateSubscription'
import { Product, Subscription } from '@vms/vmspro3-core/dist/types'

import { SubscriptionPlanCard } from '../../common/SubscriptionPlanCard'

import { selectAccount } from '../../../redux/selectors'
import { useProductsForSubscriptionUpdate } from '../../../hooks/products'
import { useAppDispatch, useAppSelector } from '../../../redux'

interface PlanSelectionModalProps {
  onSubscriptionChange: (update: Partial<Pick<Subscription, 'currentMonthlyBill' | 'paymentMethod'>>) => void,
  hideModal: VoidFunction,
}
export function PlanSelectionModal({
  onSubscriptionChange,
  hideModal,
}: PlanSelectionModalProps) {
  const account = useAppSelector(selectAccount)
  if(!account) {
    throw new Error('Account must be loaded before rendering PlanSelectionModal')
  }

  const { defaultPaymentMethodId } = account.integrations.stripe || {}

  const dispatch = useAppDispatch()

  const onChangePlan = (product: Product) => {
    Modal.confirm({
      title: 'Change Plan',
      content: `Are you sure you want to change your plan to "${product.name}"?`,
      onOk: () => {
        dispatch(updateAccountSubscription(account, product, defaultPaymentMethodId ?? null))
        onSubscriptionChange({ currentMonthlyBill: product.type !== 'Internal' ? product.price : undefined })
        hideModal()
      },
    })
  }

  const products = useProductsForSubscriptionUpdate()

  if(!products.length) {
    return (
      <Modal visible onCancel={hideModal} onOk={hideModal}>
        <i>There are no other available plans that are eligible for your current usage
          of {account.quotas.users.used} users and {account.quotas.projects.used} projects;
          please <Link to="TODO">contact us</Link> for enterprise accounts.
        </i>
      </Modal>
    )
  }

  return (
    <Modal
      visible
      onCancel={hideModal}
      title={products.length ? "Eligible Plans" : "No Eligible Plans"}
      footer={null}
    >
      {!defaultPaymentMethodId &&
        <p><i>Note: you do not currently have a payment method configured, which will
          limit your options to internal products.  If you wish to switch to a commercial
          product, please exit this screen, and update your payment information.</i></p>
      }
      <p><i>The following plans can accommodate your current usage:</i></p>
      {products.map(product => (
        <SubscriptionPlanCard
          key={product.id}
          product={product}
          actionText="Select Plan"
          onAction={() => onChangePlan(product)}
        />
      ))}
    </Modal>
  )
}
