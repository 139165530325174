import { ReactNode, useMemo } from 'react'
import { AnyAction } from 'redux'
import { AlertProps, Alert, Button, Card, Col, Row } from 'antd'

import { Color } from '@vms/vmspro3-core/dist/systemConsts'

import { ReactComponent as Logo } from '../../../assets/OptionLab_blackText.svg'

import useAuthz from '../../../hooks/useAuthz'

export interface CardConfig {
  actions?: AnyAction[]
  button?: {
    label: string,
    onClick: () => void,
  },
  description: ReactNode,
  key: string,
  title: ReactNode,
}
interface MainLandingPageViewProps {
  alert?: Omit<AlertProps, 'style'>,
  cardsConfig: CardConfig[],
}
export const MainLandingPageView = ({
  alert,
  cardsConfig,
}: MainLandingPageViewProps) => {
  const authz = useAuthz()

  const visibleCards = useMemo(
    () => cardsConfig.filter(({ actions }) => !actions || actions.some(action => authz(action))),
    [cardsConfig, authz]
  )

  return (
    <div style={style.content}>
      {alert && <Alert {...alert} style={style.alert} />}
      <div style={style.imageWrapper}>
        <Logo style={style.logo} title="OptionLab Logo" />
      </div>
      <div style={style.dashboardCards}>
        <Row gutter={30} justify="center">
          {visibleCards.map(({ key, title }) => (
            <Col span={6} key={key}>
              <span style={style.cardTitle}>{title}</span>
            </Col>
          ))}
        </Row>
        <Row gutter={30} justify="center">
          {visibleCards.map(({ key, description, button }) => (
              <Col span={6} key={key}>
                <Card bordered={false} style={style.card} bodyStyle={style.cardBody}>
                  <div style={style.cardContent}>
                    {description}
                    {button &&
                      <Button
                        type="link"
                        size="small"
                        style={style.cardLink}
                        onClick={button.onClick}
                      >
                        {button.label}
                      </Button>
                    }
                  </div>
                </Card>
              </Col>
            ))
          }
        </Row>
      </div>
    </div>
  )
}

const style: Record<string, React.CSSProperties> = {
  alert: {
    width: '95%',
    marginTop: '33px',
  },
  cardBody: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  card: {
    height: '100%',
  },
  cardContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  cardLink: {
    fontWeight: 600,
    alignSelf: 'flex-end',
    marginTop: 'auto',
    color: Color.PRIMARY,
  },
  cardTitle: {
    color: Color.BLACK,
    display: 'block',
    fontSize: '20px',
    fontWeight: 500,
    marginBottom: '12px',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: '30px',
    width: '100%',
  },
  dashboardCards: {
    margin: '0px 30px',
  },
  imageWrapper: {
    padding: '30px 30px 48px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '60%',
  },
  orgTitle: {
    color: Color.GRAY_TEXT,
    paddingTop: '16px',
  },
}
