import React, { ReactElement, useCallback, useMemo } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Select } from 'antd'

import { SelectOption } from '../../../types'
import style from './CycleSelect.module.css'

function useCycleKeys(keys: string[], selectedKey?: string) {
  return useMemo(
    () => {
      const highestIndex = keys.length - 1

      if(!selectedKey) {
        return {
          prevKey: () => keys[highestIndex],
          nextKey: () => keys[0],
        }
      }

      const currentIndex = keys.indexOf(selectedKey)

      return {
        prevKey: () => keys[currentIndex <= 0 ? highestIndex : currentIndex - 1],
        nextKey: () => keys[currentIndex < highestIndex ? currentIndex + 1 : 0],
      }
    },
    [selectedKey, keys]
  )
}

type CycleSelectProps = {
  label?: string,
  onChange: (itemId: string) => void,
  value?: string,
  selectOptions: SelectOption[],
  prevLabel?: string,
  prevIcon?: ReactElement,
  nextLabel?: string,
  nextIcon?: ReactElement,
}
export const CycleSelect = React.memo<CycleSelectProps>(({
  label,
  value,
  onChange,
  selectOptions,
  prevLabel = 'Prev',
  prevIcon = <LeftOutlined />,
  nextLabel = 'Next',
  nextIcon = <RightOutlined />,
}): JSX.Element => {
  const values = useMemo(() => selectOptions.map(o => o.value), [selectOptions])
  const { prevKey, nextKey } = useCycleKeys(values, value)

  const onPrev = useCallback(() => onChange(prevKey()), [onChange, prevKey])
  const onNext = useCallback(() => onChange(nextKey()), [onChange, nextKey])

  const cycleDisabled = Boolean(values.length === 0 || (values.length === 1 && value && values[0] === value))
  const selectId = 'cycle-select-' + label

  return (
    <div className={style.cycleSelect}>
      <Button
        type="primary"
        onClick={onPrev}
        disabled={cycleDisabled}
      >
        {prevIcon}{prevLabel}
      </Button>
      <div className={style.selectWithLabel}>
        {label && <label htmlFor={selectId}>{label}:</label>}
        <Select
          id={selectId}
          className={style.select}
          options={selectOptions}
          onChange={onChange}
          value={value}
          allowClear={false}
        />
      </div>
      <Button
        type="primary"
        onClick={onNext}
        disabled={cycleDisabled}
      >
        {nextLabel}{nextIcon}
      </Button>
    </div>
  )
})
