import _groupBy from 'lodash/groupBy'
import _sum from 'lodash/sum'
import _sumBy from 'lodash/sumBy'
import _keyBy from 'lodash/keyBy'
import _mapValues from 'lodash/mapValues'
import _partition from 'lodash/partition'
import _uniq from 'lodash/uniq'
import _mean from 'lodash/mean'

import { Criterion } from '../types/criterion'
import { Rating, ValidRating } from '../types/rating'

import { RatingsToPrioritizationAlgorithms, RatingToPrioritizationAlgorithm } from './quickPrioritization'

export type CriteriaIndex = {
  all: Criterion[]
  byId: CriteriaById
  rootCriteria: Criterion[]
  internalCriteria: Criterion[]
  leafCriteria: Criterion[]
  childrenByParentId: { [id: string]: Criterion[] }
}
/**
 * Utility function to create commonly-used indexes on a criteria set.
 */
export function indexCriteria(allCriteria: readonly Criterion[]): CriteriaIndex {
  // this old valuemetrics code was written before intrisinc (quantitative) criteria were part
  // of criteria data; we are filtering them out
  const criteria = allCriteria.filter(c => c.type !== 'IntrinsicRoot' && c.type !== 'Intrinsic')
  const rootCriteria: Criterion[] = []
  type ChildrenByParentId = CriteriaIndex['childrenByParentId']
  const childrenByParentId = criteria.reduce<ChildrenByParentId>((childrenByParentId, criterion) => {
    if(criterion.parentId === null) {
      rootCriteria.push(criterion)
    } else {
      const { parentId } = criterion
      if(!(parentId in childrenByParentId)) childrenByParentId[parentId] = [] as Criterion[]
      childrenByParentId[parentId].push(criterion)
    }
    return childrenByParentId
  }, {})
  const [internalCriteria, leafCriteria] = _partition(criteria, c => c.id in childrenByParentId)
  return {
    all: criteria.slice(),
    byId: _keyBy(criteria, 'id'),
    rootCriteria,
    internalCriteria,
    leafCriteria,
    childrenByParentId,
  }
}

/**
 * Convert a preference vector to its components (reciprocal "left" and "right" values).
 */
export function toPreferenceComponents(p: number) : { leftValue: number, rightValue: number } {
  const leftValue = p < 0 ? 1 - p : 1 / (1 + p)
  return {
    leftValue,
    rightValue: 1 / leftValue,
  }
}

/**
 * Convert preference components (reciprocal "left" and "right" values) to a preference
 * vector (negative values indicating a preference for the left component, positive values
 * indicating a preference for the right component, and zero indicating no preference).
 */
export function toPreferenceVector(pc: Readonly<{ leftValue: number, rightValue: number }>) : number {
  if(Math.abs(pc.leftValue * pc.rightValue - 1) > 0.0001) throw new Error('comparison values not reciprocal')
  return pc.leftValue <= 1 ? pc.rightValue - 1 : -(pc.leftValue - 1)
}

/**
 * An anonymous paired comparison is a paired comparison with the identity
 * of the person making the judgement stripped away.  Any two identifiable
 * things can be compared, though it is usually used for criteria.
 */
type AnonymousPairedComparison = {
  comparableIds: [string, string]   // note that comparable IDs are expected to be sorted
  preference: number  // negative numbers represent preference for comparableIds[0],
                      // positive numbers represent preference for comparableIds[1],
                      // and zero represents no preference
}

/**
 * A paired comparison indicates a preference between two arbitrary things
 * (the only requirement on the "things" is that they have unique IDs).
 */
export type PairedComparison = AnonymousPairedComparison & {
  userId: string
}

/**
 * Consistency metrics for a prioritization.  Prioritizations done via AHP
 * can have inconsistencies in the paired comparisons.  The simplest example
 * is a user who prefers A over B and B over C, but then indicates they
 * prefer C over A (logically, they should prefer A over C).  These metrics
 * quantify that inconsistency based on the prioritization's "distance" from
 * totally random judgements.
 */
type PrioritizationConsistencyAhp = {
  lambdaMax: number
  consistencyIndex: number
  consistencyRatio: number
  consistencyAssessment: string
}

/**
 * The local and global prioritization of a single criterion within a prioritization
 * of criteria.  The local prioritization is the priority of this criterion among
 * its siblings, and the global prioritization is this criterion's priority within the
 * entire prioritization tree.
 *
 * Example:
 *
 *   Criterion          Local       Global
 *   ---------          -----       ------
 *   Root                 1.0         1.00
 *     A                  0.8         0.80
 *       A1               0.5         0.40
 *       A2               0.5         0.40
 *     B                  0.2         0.20
 *       B1               0.6         0.12
 *       B2               0.4         0.08
 *
 * The sum of local priorities within its parent will always be 1, and the sum of all global
 * priorities will be one (as long as there is no double counting; that is, if you sum the
 * children of A, you have already accounted for A).
 */
export type CriterionPrioritizationResult = {
  criterionId: string
  local: number
  global: number
}

/**
 * A prioritization context is simply the context in which related criteria are prioritized.
 * For example, when trying to establish the performance of a project, "performance" itself
 * is a prioritization context.  If the criteria that comprise performance are "Environmental
 * Impact" and "Social Impact", then these two criteria exist within the context of performance.
 * "Environmental Impact" may be further broken down into "Air", "Water", and "Soil", which would
 * exist within the context of "Environmental Impact".
 *
 * When using AHP to prioritize, each context has its own consistency metrics.
 */
type PrioritizationContext = {
  contextCriterionId: string
  criteriaIds: string[]
}

/**
 * Container for the contexts that comprise a prioritization, and the prioritizations of the
 * criteria they contain.
 */
export type CriteriaPrioritization = {
  contexts: PrioritizationContext[],
  criteriaPrioritizations: CriterionPrioritizationResult[]
  criteriaPrioritizationsByParticipantId: { [id: string]: CriterionPrioritizationResult[] }
}

export type CriteriaPrioritizationAhp = CriteriaPrioritization & {
  consistencyByContextId: { [id: string]: PrioritizationConsistencyAhp }
}

export function filterInvalidRatings(ratings: Rating[]): ValidRating[] {
  return ratings.filter((r): r is ValidRating => !r.abstain && r.ratingVector !== null)
}


interface ContextPrioritization {
  [contextCriterionId: string]: {
    criterionId: string
    aggregate: number | null
    byParticipantId: Record<string, number>
  }
  // TODO: missing rating info; any criterion w/out ratings will result in a prioritization of 0
}

export function prioritizeContext(
  contextCriteria: Criterion[],
  contextRatings: ValidRating[],
  ratingsToPrioritizationAlgorithm: RatingToPrioritizationAlgorithm
): ContextPrioritization {
  const byCriByPart: Record<string, Record<string, number>> = {}
  const ratingsToPri = RatingsToPrioritizationAlgorithms[ratingsToPrioritizationAlgorithm]
  Object.entries(_groupBy(contextRatings, 'participantId')).forEach(([participantId, ratings]) => {
    const pris = ratingsToPri(contextCriteria, ratings)
    Object.entries(pris).forEach(([criterionId, rating]) => {
      const byPart = byCriByPart[criterionId] || {}
      byPart[participantId] = rating
      byCriByPart[criterionId] = byPart
    })
  })
  const byCri: ContextPrioritization = {}
  contextCriteria.forEach(c => {
    const byParticipantId = byCriByPart[c.id] || {}
    const ratings = Object.values(byParticipantId)
    // here is where we can detect missing ratings; ratings.length === 0 indicates no one rated
    // this criterion
    const aggregate = ratings.length === 0 ? null : _mean(ratings)
    byCri[c.id] = {
      criterionId: c.id,
      aggregate,
      byParticipantId,
    }
  })
  return byCri
}

/**
 * Given a collection of criteria ratings (from one or many participants), creates a criteria
 * prioritization based on those ratings using the "quick prioritization" algorithm.
 *
 * The heuristic for handling missing ratings is simple: if there is a criterion in a given context
 * that has no associated ratings, it is not included in the prioritization (as if it weren't there
 * at all). However, the criterion with missing ratings is still identified as part of the context;
 * in this way, you can identify missing information.  For example:
 *
 * @example
 *
 *   createCriteriaPrioritization(
 *     indexCriteria([
 *       { id: 'R', name: 'Root', abbrev: 'R', parentId: null },
 *       { id: 'A', name: 'A', abbrev: 'A', parentId: 'R' },
 *       { id: 'B', name: 'B', abbrev: 'B', parentId: 'R' },
 *     ]),
 *     [
 *      { participantId: 'Judy', subjectId: 'A', contextId: 'R', ratingVector: [3, 0] },
 *     ]
 *   )
 *   // result:
 *   //
 *   //   {
 *   //     contexts: [{ contextCriterionId: 'R', criteriaIds: ['A', 'B'] }],
 *   //     criteriaPrioritizations: [
 *   //       { criterionId: 'A', local: 1, global: 1 },
 *   //     ],
 *   //   }
 *
 * In the example above, B is still listed as being part of the R context, but it doesn't have an
 * associated prioritization (and, indeed, in the absense of any other criteria, A has a priority of 1).
 *
 * @deprecated see core/src/nextgen/criteria.ts (create a Criteria with a ParticipationSession;
 *   each Criterion in Criteria.all will then have pri.local and pri.global.
 */
export function createCriteriaPrioritization(
  criteria: CriteriaIndex,
  allRatings: ValidRating[],
  ratingToPrioritizationAlgorithm: RatingToPrioritizationAlgorithm = 'RecenterAndNormalize',
  includeParticipantBreakdown = true, // we use this to prevent recursion loop
): CriteriaPrioritization {
  const ratings = filterInvalidRatings(allRatings)
  const cp: CriteriaPrioritization = {
    // each internal node forms a context (including the root node)
    contexts: criteria.internalCriteria.map(c => ({
      contextCriterionId: c.id,
      criteriaIds: criteria.childrenByParentId[c.id].map(c => c.id),
    })),
    criteriaPrioritizationsByParticipantId: {},
    criteriaPrioritizations: [],
  }
  const ratingsByContext = _groupBy(ratings, 'contextId')
  cp.contexts.forEach(ctx => {
    const contextPri = prioritizeContext(
      ctx.criteriaIds.map(id => criteria.byId[id]),
      ratingsByContext[ctx.contextCriterionId],
      ratingToPrioritizationAlgorithm,
    )
    Object.values(contextPri).forEach(({ criterionId, aggregate }) => {
      cp.criteriaPrioritizations.push({
        criterionId,
        local: aggregate ?? 0,
        global: 0,  // will be filled in later
      })
    })
  })
  const prisByCriId = _keyBy(cp.criteriaPrioritizations, 'criterionId')
  // now that we have local priorities, we can establish global priorities
  // we need to do a breadth-first traversal, because each criterion's
  // global priority requires that its parents global priority has been set
  const remainingCri = criteria.rootCriteria
    .map(c => criteria.childrenByParentId[c.id])
    .flat()
  while(remainingCri.length) {
    const cri = remainingCri.shift()
    if(!cri) throw new Error('cri undefined')
    const children = criteria.childrenByParentId[cri.id]
    if(children) remainingCri.push(...children)
    const cp = prisByCriId[cri.id] ?? { criterionId: cri.id, local: 0, global: 0 }
    if(criteria.byId[cp.criterionId].type === 'Performance') {
      // root performance local & global priority should always be 1
      cp.local = cp.global = 1
    }
    const parentGlobalPri = cri.parentId ? prisByCriId[cri.parentId]?.global ?? 1 : 1
    cp.global = parentGlobalPri * cp.local
    prisByCriId[cri.id] = cp // necessary for cps created here
  }
  cp.criteriaPrioritizations = Object.values(prisByCriId)
  if(includeParticipantBreakdown) {
    // fill in prioritizations per participant
    _uniq(ratings.map(r => r.participantId)).forEach(pid => {
      const pRatings = ratings.filter(r => r.participantId === pid)
      const pCp = createCriteriaPrioritization(criteria, pRatings, ratingToPrioritizationAlgorithm, false)
      cp.criteriaPrioritizationsByParticipantId[pid] = pCp.criteriaPrioritizations
    })
  }
  return cp
}

export type CriteriaPrioritizationIndex = {
  contexts: PrioritizationContext[]
  criteriaPrioritizations: CriterionPrioritizationResult[]
  criteriaPrioritizationsByParticipantId: { [id: string]: CriterionPrioritizationResult[] },
  localPriorityByCriteriaId: { [id: string]: number }
  globalPriorityByCriteriaId: { [id: string]: number }
  byParticipantId: {
    [id: string]: {
      localPriorityByCriteriaId: { [id: string]: number }
      globalPriorityByCriteriaId: { [id: string]: number }
    }
  }
}

/**
 * Convenience function to create useful indexes from a criteria prioritization.
 *
 * @deprecated see core/src/nextgen/criteria.ts
 */
export function indexCriteriaPrioritization(cp: CriteriaPrioritization): CriteriaPrioritizationIndex {
  const index: CriteriaPrioritizationIndex = {
    ...cp,
    localPriorityByCriteriaId: {},
    globalPriorityByCriteriaId: {},
    byParticipantId: {},
  }
  cp.criteriaPrioritizations.forEach(({ criterionId, local, global }) => {
    index.localPriorityByCriteriaId[criterionId] = local
    index.globalPriorityByCriteriaId[criterionId] = global
  })
  Object.entries(cp.criteriaPrioritizationsByParticipantId).forEach(([participantId, cprs]) => {
    index.byParticipantId[participantId] = {
      localPriorityByCriteriaId: {},
      globalPriorityByCriteriaId: {},
    }
    cprs.forEach(cpr => {
      index.byParticipantId[participantId].localPriorityByCriteriaId[cpr.criterionId] = cpr.local
      index.byParticipantId[participantId].globalPriorityByCriteriaId[cpr.criterionId] = cpr.global
    })
  })
  return index
}

/**
 * Convenience definition for a collection of criteria indexed by ID.
 */
export type CriteriaById = { [id: string]: Criterion }

/**
 * Given a collection of paired comparisons by different actors, this aggregates the individual paired
 * comparisons (which results in a collection of "anonymous" paired comparisons, since they now represent
 * collective judgement).
 *
 * Note that it's expected that the comparable IDs are sorted.
 */
export function aggregatePairedComparisons(pairedComparisons: readonly PairedComparison[])
    : AnonymousPairedComparison[] {
  const byKey = _groupBy<PairedComparison>(pairedComparisons, pc => pc.comparableIds.slice().join(':'))
  return Object.values(byKey).map(pcs => (
    { comparableIds: pcs[0].comparableIds, preference: _sumBy(pcs, 'preference') }
  ))
}

/**
 * Converts a collection of (anonymous) paired comparisons into an AHP paired comparison matrix
 * (used as an intermediate step in calculating priority).
 *
 * The indexes in the resultant matrix will correspond with the indexes in the provided criteria
 * matrix.  That is, the 0th output row will correspond to criteria[0], and so on.
 */
export function getPairedComparisonMatrix(
  criteria: readonly Criterion[],
  pairedComparisons: readonly AnonymousPairedComparison[]
) : number[][] {
  const criteriaIndexById = criteria
    .reduce<{ [id: string]: number }>((o, c, i) => Object.assign(o, { [c.id]: i }), {})
  const C = criteria.map(() => criteria.map(() => 1))   // comparison matrix
  pairedComparisons.forEach(pc => {
    if(pc.preference === 0) return     // nothing to do; default is already "no preference"
    const [row, col] = pc.comparableIds.map(id => criteriaIndexById[id])
    const { leftValue, rightValue } = toPreferenceComponents(pc.preference)
    C[row][col] = leftValue
    C[col][row] = rightValue
  })
  return C
}

/**
 * Options for the AHP algorithm.
 */
type AhpOptions = {
  randomIndices: number[]
  consistencyThreshold: number
}

/**
 * Default options for the AHP algorithm.
 */
const defaultAhpOptions : AhpOptions = {
  // random indices from 0 to 15 attributes
  // source: Kardi Teknomo's tutorial on Analytic Hierarchy Process (AHP)
  // Copyright 2005 by Kardi Teknomo
  randomIndices: [
    0.00, 0.00, 0.00, 0.52, 0.59,
    1.11, 1.25, 1.35, 1.40, 1.45,
    1.49, 1.52, 1.54, 1.56, 1.58,
    1.59,
  ],
  consistencyThreshold: 1/3,
}

/**
 * AHP criteria prioritization algorithm.  Takes local criteria, anonymous paired comparisons, and algorithm
 * options, and returns a collection of criterion prioritizations and consistency metrics.
 *
 * Note that this algorithm only calculates local priorities; the paired comparisons provided should all
 * belong to the same prioritization context.  Local and global priorities returned will be the same (hierarchical
 * global priorities can be constructed later after applying this algorithm for each context).
 */
export function ahpLocal(
    criteria: readonly Criterion[],
    pairedComparisons: readonly AnonymousPairedComparison[],
    ahpOptions: AhpOptions = defaultAhpOptions,
  )
  : { criteriaPrioritizations: CriterionPrioritizationResult[], consistency: PrioritizationConsistencyAhp } {
  // this is simple AHP at this point; we have a flat set of criteria, and anonymous paired comparisons
  // that apply only to those criteria; we can use this to establish local priority and consistency
  const C = getPairedComparisonMatrix(criteria, pairedComparisons)
  const colSums = C.map((row, colIdx) => _sum(C.map(row => row[colIdx])))
  const CN = C.map(row => row.map((cell, colIdx) => cell / colSums[colIdx]))
  const rowSumsN = CN.map(row => _sum(row))
  const rowSumsNsum = _sum(rowSumsN)
  const priorities = rowSumsN.map(rowSum => rowSum /= rowSumsNsum)
  const lambdaMax = _sum(colSums.map((colSum, colIdx) => colSum * priorities[colIdx]))

  const n = criteria.length

  // logarithmic fit for random indices for n>15 courtesy Wolfram Alpha
  // (based on Saaty/Peniwati random indices up to n = 15)
  const randomIndex = n > ahpOptions.randomIndices.length - 1
    ? 0.7 * Math.log(0.782166 * n)
    : ahpOptions.randomIndices[n]

  const consistencyIndex = n <= 2 ? 0 : (lambdaMax - n) / (n - 1)
  const consistencyRatio = n <= 2 ? 0 : consistencyIndex / randomIndex
  const consistencyAssessment = consistencyRatio >= ahpOptions.consistencyThreshold
      ? "not consistent" : "acceptable"

  return {
    criteriaPrioritizations: criteria.map((c, i) => ({
      criterionId: c.id,
      global: priorities[i],
      local: priorities[i]
    })),
    consistency: {
      lambdaMax,
      consistencyIndex,
      consistencyRatio,
      consistencyAssessment,
    },
  }
}

/**
 * For a given collection of criteria, and aggregated (anonymous) paired comparisons, will return a full
 * hierarchical prioritization of all the criteria.
 */
export function ahpAnonymous(
    criteriaById: CriteriaById,
    pairedComparisons: AnonymousPairedComparison[])
  : CriteriaPrioritizationAhp {

  // determine contexts
  const criteria = Object.values(criteriaById)
  const internalCriteriaIds = [...new Set([
    ...criteria.filter(c => c.parentId === null).map(c => c.id),          // root critera
    ...criteria.filter(c => c.parentId).map(c => c.parentId as string),   // criteria that have children
  ])]
    const contexts = internalCriteriaIds.map<PrioritizationContext>(criterionId => ({
    contextCriterionId: criterionId,
    criteriaIds: [],  // will be filled in in forEach loop below
    consistency: {
      lambdaMax: NaN,
      consistencyIndex: NaN,
      consistencyRatio: NaN,
      consistencyAssessment: 'not yet calculated',
    }
  }))

  // use AHP for prioritization in each context
  const criteriaPrioritizationsById : { [id: string]: CriterionPrioritizationResult } = {}
  const consistencyByContextId: { [id: string]: PrioritizationConsistencyAhp } = {}
  contexts.forEach(context => {
    const contextCriteria = criteria.filter(c => c.parentId === context.contextCriterionId)
    context.criteriaIds = contextCriteria.map(c => c.id)
    const contextCriteriaById = _keyBy(contextCriteria, 'id')
    const contextPairedComparisons = pairedComparisons
      .filter(pc => pc.comparableIds.every(id => contextCriteriaById[id]))
    const { criteriaPrioritizations, consistency } = ahpLocal(contextCriteria, contextPairedComparisons)
    criteriaPrioritizations.forEach(cp => criteriaPrioritizationsById[cp.criterionId] = cp)
    consistencyByContextId[context.contextCriterionId] = consistency
  })

  // depth-first traversal to set local & global priorities
  criteria.filter(c => c.parentId === null).forEach(rootCriterion => {
    // root criteria have a global & local priority of 1
    criteriaPrioritizationsById[rootCriterion.id] = { criterionId: rootCriterion.id, global: 1, local: 1 }
    const remaining : [string | null, string][] =
      criteria.filter(c => c.parentId === rootCriterion.id).map(c => [c.parentId, c.id])
    while(true) { // eslint-disable-line no-constant-condition
      const arr = remaining.pop()
      if(!arr) break
      const [parentId, criterionId] = arr
      remaining.push(...criteria
        .filter(c => c.parentId === criterionId)
        .map<[string, string]>(c => [criterionId, c.id])
      )
      const cp = criteriaPrioritizationsById[criterionId]
      if(!parentId) throw new Error('unexpected null parent ID')
      cp.global = criteriaPrioritizationsById[parentId].global * cp.local
    }
  })

  // note that root-level criteria have a global & local priority of 1
  const criteriaPrioritizations = criteria.map(c => criteriaPrioritizationsById[c.id])

  return {
    contexts,
    criteriaPrioritizations,
    criteriaPrioritizationsByParticipantId: {}, // not used in "anonymous"
    consistencyByContextId,
  }
}

/**
 * Given a collection of criteria and paired comparisons, will return a full hierarchical prioritization.
 * This will include an aggregate prioritization, as well as prioritizations by user ID (as if only that
 * that actor's preferences had been considered).
 */
export function ahp(criteriaById: CriteriaById, pairedComparisons: PairedComparison[])
  : { aggregate: CriteriaPrioritizationAhp, byUserId: { [id: string]: CriteriaPrioritizationAhp } } {
  const aggregate = ahpAnonymous(criteriaById, aggregatePairedComparisons(pairedComparisons))
  const pcsByUserId = _groupBy(pairedComparisons, 'userId')
  const byUserId = _mapValues(pcsByUserId, pcs => ahpAnonymous(criteriaById, pcs))
  return {
    aggregate,
    byUserId,
  }
}
