/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Color } from '@vms/vmspro3-core/dist/systemConsts'
import { PageHeaderProps, BreadcrumbProps, PageHeader, Spin } from 'antd'

import style from './Page.module.css'

function getBreadcrumbConfig(routes: BreadcrumbProps['routes']): BreadcrumbProps {
  return {
    separator: '>',
    itemRender: (route, params, routes) => {
      const last = routes.indexOf(route) === routes.length - 1
      const name = route.breadcrumbName
      return last ? <span>{name}</span> : <Link to={route.path}>{name}</Link>
    },
    routes,
  }
}

export interface PageProps {
  breadcrumbRoutes?: BreadcrumbProps['routes'];
  children: React.ReactNode;
  extra?: React.ReactNode;
  loading?: boolean;
  tags?: PageHeaderProps['tags'];
  title?: string;
}

export const Page: React.FC<PageProps> = ({
  title,
  breadcrumbRoutes,
  children,
  loading,
  tags,
  extra,
}) => {
  const breadcrumb: PageHeaderProps['breadcrumb'] = useMemo(
    () => breadcrumbRoutes && getBreadcrumbConfig(breadcrumbRoutes),
    [breadcrumbRoutes]
  )

  return (
    <>
      <PageHeader
        breadcrumb={breadcrumb}
        breadcrumbRender={(props, originBreadcrumb) => (
          <div className={style.breadcrumb}>
            {originBreadcrumb}
          </div>
        )}
        title={title}
        tags={tags}
        extra={extra}
      />
      <div
        css={{
          flex: '1 1 0',
          backgroundColor: Color.WHITE,
          margin: '24px 30px',
          padding: '12px 24px',
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          ['@media screen and (max-width: 600px)']: {
            margin: '0',
            padding: '12px',
          },
        }}
      >
        {loading ? <Spin /> : children}
      </div>
    </>
  )
}
