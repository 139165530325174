import { useMemo } from 'react'
import { CellProps, Column } from 'react-table'

import { Invitation } from '@vms/vmspro3-core/dist/types'

import { Table } from '../../common/Table'
import { useAppSelector } from '../../../redux'
import { selectDateFormat } from '../../../redux/selectors'
import { testId } from '../../../test-automation'
import { formatTimestamp } from '../../../utils/formatUtils'

export function PendingInvitations({ invitations }: { invitations: Invitation[] }) {
  const dateFormat = useAppSelector(selectDateFormat)
  const columns = useMemo<Column<Invitation>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'fullName',
      },
      {
        Header: 'Invited By',
        accessor: 'invitedByName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Date Invited',
        accessor: 'created',
        Cell: ({ value }: CellProps<Invitation, Invitation['created']>) => (
          formatTimestamp(value, dateFormat)
        ),
      },
    ],
    [dateFormat]
  )

  return (
    <>
      <h2>Pending Invitations</h2>
      <Table
        {...testId('pending-invitations')}
        data={invitations}
        columns={columns}
      />
    </>
  )
}
