import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector, batch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Spin, message } from 'antd'

import { EntityType, RiskAnalysisStatus } from '@vms/vmspro3-core/dist/systemConsts'
import { joinAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { actions } from '@vms/vmspro3-core/dist'

import FormModal from './FormModal'
import { Select, LinkButton2 } from '../controls'

import Server from '../../../server/VMSProServerAdapter'
import useRiskEntity from '../hooks/useRiskEntity'
import { LoadingStatus } from '../../../utils/appConsts'
import { getRisk } from '../selectors'
import { useModalData } from '../RiskModalContext'
import { naturalAlphanumericSort } from '../../../utils/sort-utils'

/**
 * Create a unique name for the new risk. This function is used only for pre-populating the
 * risk name field which is editable by the user.
 *
 * Suffix the parent risk name with " (Copy)" or " (Copy N)", where N is 2, 3, 4, etc.
 *
 * A couple considerations:
 *    1) A Unique name is not a requirement in the system - but is expected for pre-population of a name.
 *    2) 'Collisions' of these actions in the server could cause risks to have the same name.
 *
 * @param {Object} riskName - the name of the risk to be copied
 * @param {Object} riskNames - the current riskNames associated with the risk context id
 *
 * @returns {string} risk name with unique suffix
 */
const getNewRiskName = (riskName, riskNames) => {
  riskName = riskName.trim()
  const dupes = riskNames.filter(n => riskName === n.trim() || n.trim().startsWith(`${riskName} (Copy`))
  return `${riskName} (Copy${dupes.length < 2 ? '' : ' ' + dupes.length})`
}

/**
 * Modal to Create a copy of an existing Risk from the Risk Interface.
 *
 * @prop {string} sourceProjectId - the projectId to reflect updated statistics
 * @prop {string} sourceRiskId - the identifier of the risk being cloned
 */
const RiskCopyModal = () => {
  const {
    sourceProjectId,
    sourceRiskId,
  } = useModalData(RiskCopyModal.id)

  const sourceRisk = useSelector(getRisk(sourceRiskId))
  const { name: sourceRiskName } = sourceRisk

  const [destinationProjectId, setDestinationProjectId] = useState(sourceProjectId)
  const {
    entity: {
      name: destinationProjectName,
      ancestry: destinationProjectAncestry,
    } = {},
    entityLoadingStatus: destinationProjectLoadingStatus,
    children: destinationChildren,
    effectiveRiskContext,
  } = useRiskEntity(destinationProjectId, { loadChildren: true })
  const destinationProjectLoaded = destinationProjectLoadingStatus === LoadingStatus.Loaded
  const destinationRiskPool = useMemo(
    () => destinationChildren?.filter(c => c.entityType === EntityType.RISK),
    [destinationChildren]
  )

  const [projectSelectOptions, setProjectSelectOptions] = useState()
  useEffect(() => {
    Server.getItemsByAncestryBeginsWith('/', EntityType.PROJECT, ['name', 'id'])
      .then(({ entities }) => {
        setProjectSelectOptions(entities
          .sort((a, b) => {
            // leave source project at the top
            if(a.id === sourceProjectId) return -1
            if(b.id === sourceProjectId) return 1
            return naturalAlphanumericSort(a.name, b.name)
          })
          .map(({ name, id }) => ({
            label: name + (id === sourceProjectId ? ' (source project)' : ''),
            value: id,
          }))
        )
      })
  }, [sourceProjectId])

  const disableOnOk = !(projectSelectOptions && destinationProjectLoaded && Array.isArray(destinationRiskPool))

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const navigateToRisk = riskId => navigate(`/proj/${destinationProjectId}/risk/${riskId}`)
  const onOk = ({ riskName }) => {
    // the OK button should be disabled if destination project is not loaded, but just in case...
    if(disableOnOk) return

    const riskCopyAction = actions.risk.create({
      num: effectiveRiskContext.nextRiskNum,
      ancestry: joinAncestry(destinationProjectAncestry, destinationProjectId),
      effectiveRiskContext,
      name: riskName,
      sourceRisk,
      isCopy: true,
    }, { projectId: destinationProjectId })

    batch(() => {
      dispatch(riskCopyAction)

      if(riskCopyAction.payload.status === RiskAnalysisStatus.ACTIVE) {
        // If status is 'ACTIVE' dispatch to update the statistics
        dispatch(actions.riskProject.updateStatistics(
          {
            activeRisks: {
              operation: 'INCREMENT',
              value: 1,
            },
          },
          {
            sourceProjectId,
            projectId: destinationProjectId,
            ancestry: destinationProjectAncestry,
          }
        ))
      }
    })

    message.success({
      content: (
        <p>
          <span>New risk </span>
          <LinkButton2 onClick={() => navigateToRisk(riskCopyAction.payload.id)}>
            {riskName}
          </LinkButton2>
          <span> has been copied to <b>{destinationProjectName}</b> from <b>{sourceRiskName}</b>!</span>
        </p>
      ),
      duration: 5, // seconds
    })
  }

  const [form] = Form.useForm()
  const nameFieldLoading = !destinationRiskPool && !form.isFieldTouched('riskName')
  const projectFieldLoading = !projectSelectOptions
  useEffect(
    () => {
      if(!form.isFieldTouched('riskName') && Array.isArray(destinationRiskPool)) {
        const newRiskName = getNewRiskName(sourceRiskName, destinationRiskPool.map(r => r.name))
        form.setFields([{
          name: 'riskName',
          value: newRiskName,
        }])
      }
    },
    [form, sourceRiskName, destinationRiskPool, destinationProjectId]
  )

  return (
    <FormModal
      formInstance={form}
      modalId={RiskCopyModal.id}
      onOk={onOk}
      confirmLoading={disableOnOk}
    >
      {initialFocus =>
        <>
          <p>Create a new copy of {sourceRiskName}</p>
          <Form.Item label="Name for new Risk">
            <Spin spinning={nameFieldLoading}>
              <Form.Item
                noStyle
                name="riskName"
                rules={[{ required: true, message: 'A name is required.' }]}
              >
                <Input disabled={nameFieldLoading} ref={initialFocus} />
              </Form.Item>
            </Spin>
          </Form.Item>
          {/*
            the following field is controlled outside the standard form
            instance to handle async loading of destination project data.
          */}
          <Form.Item label="Destination Project">
            <Spin spinning={projectFieldLoading}>
              <Select
                disabled={projectFieldLoading}
                allowClear={false}
                onChange={setDestinationProjectId}
                options={projectSelectOptions}
                placeholder={destinationProjectId && 'Loading...'}
                value={projectSelectOptions && destinationProjectId}
              />
            </Spin>
          </Form.Item>
        </>
      }
    </FormModal>
  )
}
RiskCopyModal.id = 'RiskCopyModal'

export default RiskCopyModal
