import * as Sentry from '@sentry/browser'
import _debounce from 'lodash/debounce'

import Server from '../../server/VMSProServerAdapter'
import clientId from '../../utils/clientId'
import * as localStorageUtils from '../../utils/localStorageUtils'

const LOCAL_STORAGE_KEY = 'ACTION_QUEUE'

export function getQueueFromLocalStorage() {
  return localStorageUtils.getObject(LOCAL_STORAGE_KEY)
}
export function clearLocalStorageQueue() {
  localStorageUtils.setObject(LOCAL_STORAGE_KEY, [])
}

/**
 * Queue to handle the processing of actions. "Processing" can be any
 * asynchronous work; currently sending actions to the server.
 */
class ActionQueue {
  /**
   * @constructor
   * @param {number} [options.wait = 0] - When an action is enqued, this is the amount
   *  of time to delay before processing the action queue.
   * @param {number} [options.maxWait = 100] - The maximum time to wait before processing
   *  the action queue.  This operates like a "backstop", so that if a long stream of actions
   *  are being enqueued, processing the queue will not be deferred indefinitely.
   */
  constructor(options = { wait: 0, maxWait: 100 }) {
    this.queue = []
    this.processing = false
    this.options = options
    this.listeners = new Set()
    this.batchCount = 0
    this.batchInfo = {
      current: null,
      previous: null,
    }
    this.updateProcessActions()
  }

  updateOptions(options) {
    Object.assign(this.options, options)
    this.updateProcessActions()
  }

  addListener(fn) {
    if(typeof fn !== 'function') throw new Error('listener must be a function')
    this.listeners.add(fn)
  }

  removeListener(fn) {
    this.listeners.delete(fn)
  }

  broadcast(evt) {
    this.listeners.forEach(listener => listener(evt))
  }

  saveQueueToLocalStorage() {
    localStorageUtils.setObject(LOCAL_STORAGE_KEY, this.queue)
  }

  updateProcessActions() {
    /**
     * Function to invoke the processor with the batch of actions currently in the queue and handle
     * resolving the promise returned by the processor function. If actions are added to the queue
     * before the promise resolves this function will be invoked recursively to process the next
     * batch of actions in the queue. The function is debounced by Lodash#debounce with maxWait.
     *
     * @param {Function} processor - a function that receives the pending actions in the
     *   queue and processes them, returning a promise. If the promise is rejected, the error
     *   is printed to the console.
     */
    this.processActions = _debounce(processor => {
      this.processing = true
      const actionQueue = this.queue.splice(0, this.queue.length)
      this.batchInfo.current = {
        batch: ++this.batchCount,
        start: Date.now(),
        finish: null,
        time: null,
        actions: actionQueue,
      }
      // the actions that were removed from the queue are now considered
      // processed (which is *sort* of a lie, because the server could reject
      // them), so we remove them from the backup.  this does mean that if a user
      // closes a window in the ~250 ms window in which the server is processing
      // the actions, those actions could fail and the user would not know about
      // it.  however, the local storage backup is probably not the right mechanism
      // for handling this case.
      this.saveQueueToLocalStorage()
      this.broadcast(`processing ${actionQueue.length} actions`)
      return processor(actionQueue)
        .then(() => {
          const finish = Date.now()
          this.batchInfo.previous = Object.assign(this.batchInfo.current, {
            finish,
            time: finish - this.batchInfo.current.start,
          })
          this.batchInfo.current = null
          this.processing = false
          this.broadcast(`processed ${actionQueue.length} actions`)
        })
        .catch(err => {
          Sentry.captureException(err)
          console.error('>>> error processing action queue: ', err)
        })
        .finally(() => {
        })
    }, this.options.wait, { maxWait: this.options.maxWait })
  }

  enqueue(action, processor) {
    this.broadcast(`enqueuing 1 action (${action.type})`)
    this.queue.push(action)
    // save queue to local storage so we can recover it later in case browser closes
    this.saveQueueToLocalStorage()
    if(!this.processing) this.processActions(processor)
  }
}

/**
 * Returns a function to process an array of actions. If the promise resolves successfully but
 * results in a "failure", an error modal is dispatched. If the promise is rejected, the error
 * is printed to the console (the error is handled elsewhere).
 *
 * @returns {Function} - function to post actions to the server
 */
const processActionQueue = _actions => Server.postAction(_actions)
  .then(({ result, error: errorMessage } = {}) => {
    if(result === "failure") {
      console.error(`>>> error posting action queue:`)
      console.error('successful promise resolution with result: ', result)
      const sentryId = Sentry.captureException(new Error(errorMessage))
      // hard coding action based on TS type here because importing actions
      // was causing circular dependency

      Modal.confirm({
        cancelText: 'Go Home',
        onCancel: () => window.location.href = '/',
        okText: 'Reload Page',
        onOk: () => window.location.reload(),
        // from AWS Amplify documentation:
        //  > Under the hood the API category utilizes Axios to execute the
        //  > HTTP requests. API status code response > 299 are thrown as an
        //  > exception. If you need to handle errors managed by your API,
        //  > work with the error.response object."
        // see: https://aws-amplify.github.io/docs/js/api#using-the-api-client
        title: 'System Error',
        content: (
          <>
            <p>We&apos;re sorry, we&apos;ve encountered an error. The development team has been notified.</p>
            <p style={{ fontSize: 'smaller' }}>
              Error receipt ID: <span style={{ fontFamily: 'monospace' }}>{sentryId}</span>
            </p>
          </>
        ),
      })
    }
  })

// save this to window object for introspection/control purposes
window.actionQueue = new ActionQueue({ wait: 50, maxWait: 500 })

// you can blacklist actions from getting sent to
// the server; this is useful during development
const actionTypeBlacklist = []

const isomorphicReduxMiddleware = ( ) => next => action => {
  // TODO: caching/buffering to help with connectivity issues
  // we only inform the server about non-ephemeral actions that originate from this client

  const { meta, type } = action
  // short-circuit for ineligible actions
  if(!meta
    || meta.ephemeral                       // ephemeral actions
    || meta.clientId !== clientId           // did not originate from this client
    || actionTypeBlacklist.includes(type)   // blacklisted actions
  ) return next(action)

  // queue action for sending to server
  window.actionQueue.enqueue(action, processActionQueue)

  return next(action)
}

export default isomorphicReduxMiddleware
