import React from 'react'

import Checkbox from 'antd/lib/checkbox'
import { getOptionLabel } from '../utils/getOptionLabel'

import 'antd/lib/checkbox/style/css'

const AntGroup = Checkbox.Group

Checkbox.Group = React.forwardRef(({
  options,
  readOnly,
  style,
  value,
  ...props
}, ref) => {
  if(readOnly) {
    if(value === undefined || value === null) return null

    return Array.isArray(value)
      ? (
        <ul ref={ref} style={readOnlyStyle.list}>
          {value.map(v =>
            <li key={v}>{getOptionLabel(v, options)}</li>
          )}
        </ul>
      )
      : (
        <div ref={ref} style={readOnlyStyle.single}>
          {getOptionLabel(value, options)}
        </div>
      )
  }

  return (
    <AntGroup
      ref={ref}
      options={options}
      style={getGroupStyle(style)}
      value={value}
      {...props}
    />
  )
})

const getGroupStyle = style => ({
  display: 'flex',
  flexDirection: 'column',
  ...style,
})

const readOnlyStyle = {
  list: {
    paddingLeft: '24px',
    marginBottom: '0',
  },
  single: {
    lineHeight: '32px',
    paddingLeft: '12px',
  },
}

export default Checkbox
