import React, { Suspense, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import _keyBy from 'lodash/keyBy'
import { Button, Space, Spin } from 'antd'

import { actions } from '@vms/vmspro3-core/dist'

import config from '../../../config.json'
import { selectAuthUserId } from '../../../redux/selectors'

const eulasById = _keyBy(config.instance?.eulas, 'id')
const productsById = _keyBy(config.instance.products, 'id')

function Eula({ account }) {
  const authUserId = useSelector(selectAuthUserId)

  const accountId = account?.id

  const eula = eulasById[productsById[account?.productId]?.eulaId]

  // feels weird to use <Spin /> twice (here and in the <Suspense> below), but not sure
  // how else to do this....`
  const EulaBody = eula
    // strangely, template strings do NOT work here, but regular string concatenation does....
    // ? React.lazy(() => import(`./eulas/Eula_${eula.id}_${eula.version}`))
    ? React.lazy(() => import('./eulas/Eula_' + eula.id + '_' + eula.version))
    : () => <Spin />

  const dispatch = useDispatch()
  const agreeToEula = useCallback(
    () => {
      const { id: eulaId, version } = eula

      dispatch(actions.user.agreeToEula({
        eulaId,
        version,
      }, { userId: authUserId, accountId }))
    },
    [dispatch, eula, authUserId, accountId]
  )

  const navigate = useNavigate()

  return (
    <div style={style.container}>
      <div style={style.eula}>
        <Suspense fallback={<Spin />}>
          <EulaBody />
        </Suspense>
      </div>
      <div style={style.controls}>
        <Space size="middle">
          <Button onClick={() => navigate('/')}>Disagree</Button>
          <Button onClick={agreeToEula} type="primary">
            Agree
          </Button>
        </Space>
      </div>
    </div>
  )
}

const style = {
  container: {
    height: '100%',
    padding: '60px',
    backgroundColor: '#f0f2f5',
  },
  controls: {
    textAlign: 'right',
  },
  eula: {
    backgroundColor: 'white',
    border: '1px solid black',
    padding: '24px',
    height: '500px',
    overflowY: 'scroll',
    marginBottom: '24px',
  },
}

export default Eula
