import { ReactElement } from 'react'

import { TreeNode, TreeNodeData } from './TreeNode'
import { TreeProvider } from './treeContext'

type TreeProps<T> = {
  rootNode?: T,
  readOnly?: boolean,
  selectedItemId?: string,
  onSelectItem?: (itemId: string) => void,
  renderLabel?: (item: T) => string | ReactElement,
  isNodeDisabled?: (item: T) => boolean,
}
export const Tree = <T extends TreeNodeData<T>>({
  rootNode,
  readOnly,
  selectedItemId,
  onSelectItem,
  renderLabel = c => c.name,
  isNodeDisabled,
}: TreeProps<T>): JSX.Element | null => {
  if(!rootNode) return null

  return (
    <TreeProvider value={{ readOnly, selectedItemId, onSelectItem }}>
      <TreeNode<T>
        key={rootNode.id}
        node={rootNode}
        renderLabel={renderLabel}
        isNodeDisabled={isNodeDisabled}
      />
    </TreeProvider>
  )
}
