import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PolicyType } from '@vms/vmspro3-core/dist/systemConsts'
import { Form } from 'antd'

import { actions } from '@vms/vmspro3-core/dist'

import FormModal from './FormModal'
import { Select } from '../controls'

import useAuthz from '../../../hooks/useAuthz'
import { selectActiveUsers } from '../../../redux/selectors'
import { useModalData } from '../RiskModalContext'

/**
 * Add a user to a resource policy
 */
const UsersAddResourcePolicyModal = () => {
  const {
    accountId,
    readOnlyPolicyId,
    resourceId,
    resourceType,
    resourcePolicies,
  } = useModalData(UsersAddResourcePolicyModal.id)
  if(!readOnlyPolicyId) throw new Error('Polciy ID not provided.')
  if(!resourcePolicies) throw new Error('No resource policies provided to add a user to.')

  // grab all principals conatained in the resource policies
  const resourcePrincipals = resourcePolicies.map(p => p.Principal.map(P => P.split(':')[1])).flat()

  const payload = {
    accountId,
    policyType: PolicyType.RESOURCE,
    resourceId,
    resourceType,
    extends: readOnlyPolicyId,
  }

  const authz = useAuthz()
  const canCreatePolicies = authz(actions.policy.create({ ...payload, Principal: [] }, {}))
  const canUpdatePolicies =
    authz(actions.policy.update(null, { accountId, policyId: '*', resourceId, resourceType }))

  if(!(canCreatePolicies && canUpdatePolicies)) {
    throw new Error('Not authorized: User not authorized to update this policy.')
  }

  const activeUsers = useSelector(selectActiveUsers)
  const usersSelectOptions = activeUsers
    .filter(({ id }) => !resourcePrincipals.includes(id))
    .map(({ id, fullName }) => ({ key: id, label: fullName }))

  const dispatch = useDispatch()

  // dispatch a policy update or new policy creation
  const onOk = ({ userIds }) => {
    // find the existing policy (if exists; else null)
    const readOnlyPolicy = resourcePolicies.find(_P => _P.extends === readOnlyPolicyId)

    const newPrincipalUsers = userIds.map(userId => `user:${userId}`)

    // if the policy exists - update the policy; else create a new policy
    if(readOnlyPolicy) {
      const principal = readOnlyPolicy?.Principal || []
      // dispatch update action
      dispatch(actions.policy.update(
        { Principal: principal.concat(newPrincipalUsers) },
        {
          accountId,
          resourceId,
          resourceType,
          policyId: readOnlyPolicy.id,
          policyType: PolicyType.RESOURCE,
        }
      ))
    } else {
      // dispatch create action
      dispatch(actions.policy.create(
        { ...payload, Principal: newPrincipalUsers },
        {}
      ))
    }
  }

  return (
    <FormModal modalId={UsersAddResourcePolicyModal.id} onOk={onOk}>
      {() =>
        <>
          <h3>Add Users</h3>
          <Form.Item label="User(s):" name="userIds">
            <Select mode="multiple">
              {usersSelectOptions.map(({ key, label }) => <Select.Option key={key}>{label}</Select.Option>)}
            </Select>
          </Form.Item>
        </>
      }
    </FormModal>
  )
}
UsersAddResourcePolicyModal.id = 'UsersAddResourcePolicyModal'

export default UsersAddResourcePolicyModal
