import React, { useEffect, useState } from 'react'
import _get from 'lodash/get'
import { Duration, DurationUnitMetadata } from '@vms/vmspro3-core/dist/utils/qty'
import { Input } from 'antd'

import { formatNumValue, parseNumValue } from '../../../utils/inputUtils'
import { testId } from '../../../test-automation'

const DurationInput = React.forwardRef(({
  allowNull,
  decimalPlaces,
  id,
  unit,
  onBlur,
  onChange,
  readOnly,
  style,
  value,
  ...props
}, ref) => {
  if(!value) value = {}

  const [displayValue, setDisplayValue] = useState(formatNumValue(value.value, { allowNull, decimalPlaces }))
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    if(!editing) {
      setDisplayValue(formatNumValue(value.value, { allowNull, decimalPlaces }))
    }
  }, [allowNull, decimalPlaces, editing, value.value])

  const valueSuffix = _get(DurationUnitMetadata, [unit, 'label'], unit || '')

  if(readOnly) {
    return (
      <div ref={ref} style={readOnlyStyle}>
        {(typeof value.value === 'undefined' || value.value === null)
          ? null
          : <span>{displayValue}&nbsp;{valueSuffix}</span>
        }
      </div>
    )
  }

  const handleBlur = event => {
    setEditing(false)
    if(onBlur) onBlur(event)
  }

  const handleChange = event => {
    const v = event.target.value.replace(/[^\d.,-]/g, '')
    setEditing(true)
    setDisplayValue(v)
    onChange(new Duration(unit, parseNumValue(v, allowNull)))
  }

  return (
    <Input
      ref={ref}
      {...props}
      {...testId(id)}
      addonBefore={valueSuffix}
      onBlur={handleBlur}
      onChange={handleChange}
      style={getInputStyle(style)}
      value={displayValue}
    />
  )
})
DurationInput.defaultProps = {
  allowNull: true,
  decimalPlaces: 0,
  style: {},
}

const readOnlyStyle = {
  minHeight: '32px',
  padding: '5px 12px 6px',
  textAlign: 'right',
  width: '100%',
}

const getInputStyle = style => ({
  textAlign: 'right',
  ...style,
})

export default DurationInput
