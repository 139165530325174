import { createSelector } from 'reselect'

import { RootState } from '../store'

const defaultDateFormat = 'YYYY-MM-DD'
const defaultTimeFormat = 'hh:mm a'
const defaultDateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`

export const selectCurrentUserLoadingStatus = (state: RootState) => state.user.currentUserLoadingStatus
export const selectCurrentUserName = (state: RootState) => state.user.currentUser?.fullName
export const selectDateFormat = (state: RootState) => state.user.currentUser?.dateFormat ?? defaultDateFormat
export const selectDateTimeFormat = (state: RootState) => (
  state.user.currentUser?.dateTimeFormat ?? defaultDateTimeFormat
)

export const selectAccountUser = (state: RootState) => state.user.accountUser

export const selectUserPolicyIds = createSelector(
  (state: RootState) => state.user.currentUser?.policyIds,
  (state: RootState) => state.user.accountUser?.policyIds,
  (currentUserPolicyIds, accountUserPolicyIds) => {
    const policyIds: string[] = []
    if(currentUserPolicyIds) policyIds.push(...currentUserPolicyIds)
    if(accountUserPolicyIds) policyIds.push(...accountUserPolicyIds)
    return policyIds
  }
)

export const selectTableConfigs = (state: RootState, tableId: string) => {
  const user = selectAccountUser(state)
  return user?.tableConfigs?.[tableId]
}
