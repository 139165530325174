import React, { useMemo } from 'react'
import _keyBy from 'lodash/keyBy'
import { getRiskCategoryLabelFactory } from '@vms/vmspro3-core/dist/utils/risk'

import TreeSelect from '../controls/TreeSelect'

// convert category enum values (that have an internal ancestry key) to a shape suitable for
// use with antd's TreeSelect component
const catValuesToTree = categoryValues => {
  const riskCategoryLabelFactory = getRiskCategoryLabelFactory(categoryValues)
  const values = categoryValues.map(({ value, label, ancestry, isDeleted }) => ({
    value,
    ancestryLabel: riskCategoryLabelFactory(value, 'long'),
    title: label.long,
    parentKey: ancestry === '/' ? '/' : ancestry.split('/').pop() || '',
    children: [],
    isDeleted,
  }))
  const byValue = _keyBy(values, 'value')
  values.forEach(v => {
    v.parent = byValue[v.parentKey]
    if(v.parent && !v.isDeleted) v.parent.children.push(v)
  })
  return values.filter(v => !v.parent && !v.isDeleted)
}

/**
 * A tree select component to select risk categories.

 * @param {string} category - Selected category (or null/undefined, which will render
 *   an empty string).
 * @param {object[]} categoryValues - Category values from effecive risk context
 *   (effectiveRiskContext.types.category.values).
 * @param {(category : string) => void} onChange - Callback when the user changes or
 *   clears the current value.
 * @param {boolean} [readOnly=false]
 */
const RiskCategorySelect = ({ category, categoryValues, onChange, readOnly = false }) => {
  const categoryTreeData = useMemo(() => catValuesToTree(categoryValues), [categoryValues])

  const value = useMemo(() => {
    const { value, label } = categoryValues.find(c => c.value === category)
    return {
      value,
      label: label.long,
    }
  }, [categoryValues, category])

  return (
    <TreeSelect
      treeNodeLabelProp="ancestryLabel"
      readOnly={readOnly}
      value={value.value}
      onChange={valueObj => onChange(valueObj?.value)}
      allowClear
      treeData={categoryTreeData}
      dropdownMatchSelectWidth={false}
      labelInValue      // labelInValue means that onChange receives { value: string, label: string }
                        // instead of just the value string; this enables displaying a different label
                        // (Parent : Child) than what appears in the tree
    />
  )
}

export default RiskCategorySelect
