import { useCallback, useMemo } from 'react'
import { Participant, Rating, RatingNotes } from '@vms/vmspro3-core/dist/types'
import _mapValues from 'lodash/mapValues'
import { gql, useMutation } from '@apollo/client'
import { Form, Modal, Select } from 'antd'

import { ParticipantFieldsFragment, RatingFieldsFragment, RatingNotesFieldsFragment } from '../../../../graphql'
import { useInitialFocusRef } from '../../../../hooks/useInitialFocusRef'


interface ParticipantFields {
  fullName: string,
  email?: string,
  phone?: string,
}
interface MergeParticipantsData {
  mergeParticipants: {
    participant: Participant,
    ratings: Rating[],
    ratingNotes: RatingNotes[],
  }
}
interface MergeParticipantsVariables {
  accountId: string,
  decisionId: string,
  participantIds: string[],
  participantData: ParticipantFields,
}
const MERGE_PARTICIPANTS = gql`
  mutation MergeParticipants(
    $accountId: ID!
    $decisionId: ID!
    $participantIds: [ID!]!
    $participantData: MergeParticipantsInput!
  ) {
    mergeParticipants(
      accountId: $accountId
      decisionId: $decisionId
      participantIds: $participantIds
      participantData: $participantData
    ) {
      participant {
        ...ParticipantFields,
        associatedUser {
          id
          accountId
          fullName
        }
      }
      ratings { ...RatingFields }
      ratingNotes { ...RatingNotesFields }
    }
  }
  ${ParticipantFieldsFragment}
  ${RatingFieldsFragment}
  ${RatingNotesFieldsFragment}
`

export const ParticipantsMergeModalId = 'ParticipantsMergeModal'
export type ParticipantsMergeModalProps = {
  accountId: string,
  decisionId: string,
  participants: Participant[],
  hideModal: VoidFunction,
}
export function ParticipantsMergeModal({
  accountId,
  decisionId,
  participants,
  hideModal,
}: ParticipantsMergeModalProps) {
  const { selectOptions, initialValues } = useMemo(
    () => {
      const optionValues = {
        fullName: participants
          .map(participant => participant.fullName)
          .sort((a, b) => b.length - a.length),
        email: participants
          .slice()
          .sort((a, b) => a.created.timestamp - b.created.timestamp)
          .map(participant => participant.email),
        phone: participants.map(participant => participant.phone),
      }

      const selectOptions = _mapValues(
        optionValues,
        (values: (string | undefined)[]) => {
          const definedValues = values.filter((value): value is string => Boolean(value))
          const uniqueValues = Array.from(new Set(definedValues))
          return uniqueValues.map(value => ({ value, label: value }))
        }
      )
      const initialValues = _mapValues(selectOptions, options => options[0]?.value)

      return {
        selectOptions,
        initialValues,
      }
    },
    [participants]
  )

  const [mergeParticipantsMutation] = useMutation<MergeParticipantsData, MergeParticipantsVariables>(
    MERGE_PARTICIPANTS,
    {
      refetchQueries: ['GetParticipants'],
    }
  )

  const [form] = Form.useForm<ParticipantFields>()

  const onOk = useCallback(
    async () => {
      const participantData = await form.validateFields()
      const participantIds = participants.map(participant => participant.id)
      await mergeParticipantsMutation({
        variables: {
          accountId,
          decisionId,
          participantIds,
          participantData,
        },
      })
      hideModal()
    },
    [form, mergeParticipantsMutation, accountId, decisionId, participants, hideModal]
  )

  return (
    <Modal
      visible
      onCancel={hideModal}
      onOk={onOk}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item label="Name" name="fullName">
          <Select ref={useInitialFocusRef()} options={selectOptions.fullName} />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Select options={selectOptions.email} />
        </Form.Item>
        <Form.Item label="Phone" name="phone">
          <Select options={selectOptions.phone} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
ParticipantsMergeModal.id = ParticipantsMergeModalId
