import { useEffect } from 'react'

import { UserAccountRecord_App } from '@vms/vmspro3-core/dist/types'

import { useAppSelector, useAppDispatch } from '../store'
import { fetchUsers } from '../actions'
import { selectUsers } from '../selectors'
import { useAccount } from '../../context'

export function useUsers(): [users: UserAccountRecord_App[], loading: boolean] {
  const users = useAppSelector(selectUsers)
  const loading = useAppSelector(state => state.users.loadingStatus !== 'Loaded')

  return [users, loading]
}

export function useFetchUsers() {
  const { accountId } = useAccount()
  const usersNotLoaded = useAppSelector(state => state.users.loadingStatus === 'NotLoaded')

  const dispatch = useAppDispatch()
  useEffect(
    () => {
      if(usersNotLoaded) {
        dispatch(fetchUsers(accountId))
      }
    },
    [dispatch, accountId, usersNotLoaded]
  )
}
