import { useCallback } from 'react'
import { Form, Input, Modal, Select } from 'antd'

import { Html } from '@vms/vmspro3-core/dist/types'
import { createId } from '@vms/vmspro3-core/dist/idUtils'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'
import { Criterion } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { createChildCriterion, updateCriterion } from '@vms/vmspro3-core/dist/actions/decision'

import { useCriteria } from '../../../../redux/hooks'
import { colorSelectOptions } from '../../../../utils/appConsts'
import { getRandomColorFromLeastFrequent } from '../../../../utils/getRandomColorFromLeastFrequent'
import { useInitialFocusRef } from '../../../../hooks/useInitialFocusRef'
import { useLazyRef } from '../../../../hooks/useLazyRef'
import { useAppDispatch } from '../../../../redux'

interface CriterionModalFormFields {
  name: string,
  abbrev: string,
  color: string,
  description: Html,
}
interface CriterionModalProps {
  decisionId: string,
  criterion?: Criterion,
  parentId?: string,
  hideModal: VoidFunction,
}
export function CriterionModal({
  decisionId,
  criterion,
  parentId,
  hideModal,
}: CriterionModalProps) {
  const criteria = useCriteria(decisionId)
  const defaultColorRef = useLazyRef(() =>
    getRandomColorFromLeastFrequent(colorSelectOptions.map(o => o.value), criteria)
  )

  const [form] = Form.useForm<CriterionModalFormFields>()

  const dispatch = useAppDispatch()
  const onOk = useCallback(
    () => form.validateFields()
      .then(({ description, ...restFields }) => {
        const data = {
          ...restFields,
          description: createHtmlObject(description?.value ?? null),
        }

        if(criterion) {
          dispatch(updateCriterion(decisionId, criterion.id, data))
        } else if(parentId) {
          dispatch(createChildCriterion(decisionId, {
            id: createId(),
            ...data,
            parentId,
            type: 'Rated',
          }))
        }

        hideModal()
      }),
    [form, dispatch, criterion, decisionId, parentId, hideModal]
  )

  return (
    <Modal visible onOk={onOk} onCancel={hideModal}>
      <Form form={form} layout="vertical" initialValues={criterion?.data ?? { color: defaultColorRef.current }}>
        <Form.Item label="Name" name="name" rules={[{ required: true, whitespace: true }]}>
          <Input ref={useInitialFocusRef()} />
        </Form.Item>
        <Form.Item label="Abbrev." name="abbrev" rules={[{ required: true, whitespace: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Color" name="color" rules={[{ required: true }]}>
          <Select>
            {colorSelectOptions.map(o => (
              <Select.Option key={o.value} value={o.value} style={{ display: 'flex' }}>
                <div style={{ width: '100%', height: '100%', padding: '4px 0', display: 'flex', gap: '12px' }}>
                  <div style={{ width: '50px', backgroundColor: o.value }} />
                  <span style={{ alignSelf: 'center' }}>{o.label}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Description"
          name={['description', 'value']}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  )
}
