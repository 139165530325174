import _isMatch from 'lodash/isMatch'
import { OptionData } from '../nextgen/Option'
import { Criterion, Participant, ParticipationSession, Rating } from "../types"

type RatingIdKeys = 'participationSessionId' | 'participantId' | 'contextId' | 'subjectId'
export type RatingIdProps = Pick<Rating, RatingIdKeys>

/**
 * given an object containing the properties that uniquely identify a rating,
 * returns a composite identifier that can be used as an index or reference key.
 */
export function identifyRating(rating: RatingIdProps): string {
  const { participationSessionId, participantId, contextId, subjectId } = rating
  return [participationSessionId, participantId, contextId, subjectId].join(':')
}

/**
 * given an object or an array of objects containing one or more of the
 * properties that are used in a composite rating id, returns a predicate
 * to match rating ids where all provided property values are equal in one
 * of the match objects.
 */
export function ratingIdMatchesWith<T extends Partial<RatingIdProps>>(match: T | T[]) {
  return (ratingId: string) => {
    const [participationSessionId, participantId, contextId, subjectId] = ratingId.split(':')
    const ratingProps = {
      participationSessionId,
      participantId,
      contextId,
      subjectId,
    }

    if(Array.isArray(match)) {
      return match.some(m => _isMatch(ratingProps, m))
    }
    return _isMatch(ratingProps, match)
  }
}

type RatingWarningCode = 'ReferenceUnknown' | 'InvalidSubject'
type CheckedRatings = {
  ratings: Rating[],
  warnings: { code: RatingWarningCode, message: string }[]
}

function getRatingValidator(
  participationSession: ParticipationSession,
  participants: Record<string, Participant>,
  criteria: Record<string, Criterion>,
  options: Record<string, OptionData>,
  warnings: CheckedRatings['warnings'],
): (r: Rating) => boolean {
  switch(participationSession.type) {
    case 'CriteriaPrioritization':
      return r => {
        const newWarnings: CheckedRatings['warnings'] = []
        if(!participants[r.participantId]) {
          newWarnings.push({ code: 'ReferenceUnknown', message: `participant ${r.participantId} not found` })
        }
        if(!criteria[r.contextId]) {
          newWarnings.push({ code: 'ReferenceUnknown', message: `context criterion ${r.contextId} not found` })
        }
        if(!criteria[r.subjectId]) {
          newWarnings.push({ code: 'ReferenceUnknown', message: `subject criterion ${r.subjectId} not found` })
        }
        if(r.subjectType !== 'Criterion') {
          newWarnings.push({
            code: 'InvalidSubject',
            message: `invalid subject for criteria prioritization: ${r.subjectType}`,
          })
        }
        warnings.push(...newWarnings)
        return newWarnings.length === 0
      }
    case 'OptionRating': {
      return r => {
        const newWarnings: CheckedRatings['warnings'] = []
        if(!participants[r.participantId]) {
          newWarnings.push({ code: 'ReferenceUnknown', message: `participant ${r.participantId} not found` })
        }
        if(!criteria[r.contextId]) {
          newWarnings.push({ code: 'ReferenceUnknown', message: `context criterion ${r.contextId} not found` })
        }
        if(!options[r.subjectId]) {
          newWarnings.push({ code: 'ReferenceUnknown', message: `option ${r.subjectId} not found` })
        }
        if(r.subjectType !== 'Option') {
          newWarnings.push({
            code: 'InvalidSubject',
            message: `invalid subject for option rating: ${r.subjectType}`,
          })
        }
        warnings.push(...newWarnings)
        return newWarnings.length === 0
      }
    }
    default: throw new Error('unrecognized participation session type:' + participationSession.type)
  }
}

/**
 * Given a list of ratings, filters out any rating that includes an invalid reference (i.e. to
 * participant, criteria, or option).  It also returns a list of warnings about any invalid ratings
 * that were provided.
 */
export function validateRatings(
  ratings: Rating[],
  participationSession: ParticipationSession,
  participants: Record<string, Participant>,
  criteria: Record<string, Criterion>,
  options: Record<string, OptionData>,
): CheckedRatings {
  const warnings: CheckedRatings['warnings'] = []
  const ratingValidator = getRatingValidator(participationSession, participants, criteria, options, warnings)
  return {
    ratings: ratings.filter(ratingValidator),
    warnings,
  }
}
