import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import { useDndMonitor, useDraggable, DragMoveEvent } from '@dnd-kit/core'

import { Rating, RatingVector } from '@vms/vmspro3-core/dist/types'
import { CriterionData } from '@vms/vmspro3-core/dist/nextgen/Criterion'
import { OptionData } from '@vms/vmspro3-core/dist/nextgen/Option'

import style from './RatingSubject.module.css'
import { positionToRatingVector } from './utils'

type RatingSubjectProps = {
  subject: CriterionData | OptionData,
  rating?: Rating,
  minRating: number,
  maxRating: number,
  zIndex: number,
  hideRating: boolean,
  fontSize: string,
  disabled?: boolean,
}
export const RatingSubject = ({
  subject,
  rating,
  minRating,
  maxRating,
  zIndex,
  hideRating,
  fontSize,
  disabled,
}: RatingSubjectProps): ReactElement => {
  const { setNodeRef, attributes, listeners, transform } = useDraggable({
    disabled,
    id: subject.id,
    data: {
      subject,
      rating,
    },
  })

  const [rect, setRect] = useState<DOMRect | null>(null)
  const setRef = useCallback(
    (elem: HTMLDivElement) => {
      setNodeRef(elem)
      setRect(elem?.getBoundingClientRect() ?? null)
    },
    [setNodeRef]
  )

  const [ratingVector, setRatingVector] = useState<RatingVector>(rating?.ratingVector ?? null)
  useEffect(
    () => {
      setRatingVector(rating?.ratingVector ?? null)
    },
    [rating?.ratingVector]
  )
  const [abstain, setAbstain] = useState<boolean>(rating?.abstain ?? false)
  useEffect(
    () => {
      setAbstain(rating?.abstain ?? false)
    },
    [rating?.abstain]
  )

  const range = useMemo(() => ({ min: minRating, max: maxRating }), [minRating, maxRating])

  useDndMonitor({
    onDragMove: useCallback(
      (event: DragMoveEvent) => {
        if(event.active.id === subject.id) {
          const activeRect = event.active.rect.current.translated
          const overRect = event.over?.rect
          setRatingVector(positionToRatingVector(
            range,
            activeRect,
            overRect,
          ))

          setAbstain(event.over?.id === 'abstainCanvas')
        }
      },
      [subject.id, range]
    ),
  })

  const styleVariables = useMemo(
    () => {
      const style = {
        '--translate-x': `${transform?.x ?? 0}px`,
        '--translate-y': `${transform?.y ?? 0}px`,
        '--position-left': undefined as string | undefined,
        '--position-bottom': undefined as string | undefined,
        '--subject-z-index': zIndex,
        '--subject-color': subject.color,
        '--subject-font-size': fontSize,
      }

      if(rect && rating?.ratingVector) {
        const ratingToCSSPosition = (rating: number, subjectSpan: number) => {
          const positionDecimal = (rating - minRating) / (maxRating - minRating)
          const positionPercentage = positionDecimal * 100
          const offsetPx = positionDecimal * subjectSpan
          return `calc(${positionPercentage}% - ${offsetPx}px)`
        }

        style['--position-bottom'] = ratingToCSSPosition(rating.ratingVector[0], rect.height)
        style['--position-left'] = ratingToCSSPosition(rating.ratingVector[1], rect.width)
      }

      return style as React.CSSProperties
    },
    [
      rect,
      transform,
      zIndex,
      minRating,
      maxRating,
      rating?.ratingVector,
      subject.color,
      fontSize,
    ]
  )

  return (
    <div
      className={classnames(style.ratingSubject, {
        [style.hasRating]: !!rating?.ratingVector,
        [style.disabled]: disabled,
      })}
      ref={setRef}
      style={styleVariables}
      {...attributes}
      {...listeners}
    >
      <div className={style.subject}>
        <span className={style.subjectName}>{subject.abbrev}</span>
        <span
          className={classnames(style.subjectRating, {
            [style.hidden]: ratingVector === null || abstain,
            [style.displayNone]: hideRating,
          })}
        >
          {ratingVector !== null ? ratingVector[0].toFixed(1) : '-'}
        </span>
        <div className={style.subjectBackground}>
          <div className={style.subjectBackgroundColor} />
        </div>
      </div>
    </div>
  )
}
