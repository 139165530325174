import { ReactElement, useMemo } from 'react'

import { Criteria } from '@vms/vmspro3-core/dist/nextgen/criteria'
import { ParticipationSession } from '@vms/vmspro3-core/dist/nextgen/participationSession'

import { PrioritizationChart } from './PrioritizationChart'
import { CycleSelect } from '../../../common/CycleSelect'
import style from './ContextCriteriaPriorityChart.module.css'

import { useQuerystringValue } from '../../../../hooks/useQuerystringValue'
import {
  useContextCriteriaSelectOptions,
  useCriteria,
  useDecisionData,
  useParticipants,
  useParticipationSession,
  useParticipationSessionRatings,
  useRootPerfCriterionId,
} from '../../../../redux/hooks'

export type ContextCriteriaPriorityChartProps = {
  decisionId: string,
}

function useCriteriaNextgen(decisionId: string): Criteria {
  const criteriaData = useCriteria(decisionId)
  const criteriaPrioritizationSessionData = useParticipationSession(decisionId, 'CriteriaPrioritization')
  const criteriaPrioritizationRatings = useParticipationSessionRatings(decisionId, 'CriteriaPrioritization')
  const participants = useParticipants(decisionId)
  const { ratingsToPrioritizationAlgorithm } = useDecisionData(decisionId)

  return useMemo(
    () => {
      const criteria = new Criteria(criteriaData)
      const criteriaPrioritizationSession = new ParticipationSession(
        criteriaPrioritizationSessionData,
        participants,
        criteriaPrioritizationRatings,
        []
      )
      criteria.useParticipationSession(criteriaPrioritizationSession, ratingsToPrioritizationAlgorithm)
      return criteria
    },
    [
      criteriaData,
      criteriaPrioritizationSessionData,
      criteriaPrioritizationRatings,
      participants,
      ratingsToPrioritizationAlgorithm,
    ]
  )
}

export function ContextCriteriaPriorityChart({ decisionId }: ContextCriteriaPriorityChartProps): ReactElement {
  const rootPerfCriterionId = useRootPerfCriterionId(decisionId)
  const contextCriteriaSelectOptions = useContextCriteriaSelectOptions(decisionId, rootPerfCriterionId)
  const [contextCriterionId, setContextCriterionId] = useQuerystringValue(
    'contextCriterionId',
    rootPerfCriterionId,
  )

  const criteria = useCriteriaNextgen(decisionId)
  const context = criteria.byId[contextCriterionId]
  const items = context.children.map(c => ({
    id: c.id,
    name: c.name,
    abbrev: c.abbrev,
    priority: c.pri.local,
    color: c.color,
  }))
    .filter(c => c.priority !== null)

  return (
    <div className={style.container}>
      <CycleSelect
        label="Context Criterion"
        selectOptions={contextCriteriaSelectOptions}
        value={contextCriterionId}
        onChange={setContextCriterionId}
      />
      <PrioritizationChart items={items} />
    </div>
  )
}
