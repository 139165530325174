import React, { useMemo } from 'react'
import { Form, Button, Card, Statistic } from 'antd'
import { Product } from '@vms/vmspro3-core/dist/types'

interface SubscriptionPlanCardProps {
  product: Product & ({
    // this product type extension originates in useProductsForSubscriptionUpdate()
    isCurrentProduct?: boolean,
    disabled?: boolean,
    disabledReason?: string,
   }),
  actionText: string,
  onAction: () => void,
}
export function SubscriptionPlanCard({
  product,
  actionText,
  onAction,
}: SubscriptionPlanCardProps) {
  const bulletList = useMemo(
    () => (
      <ul>
        {Object.entries(product.quotas)
          .filter(a => a[1] !== 0)
          .map(([key, limit]) => <li key={key}>{key}: {limit >= 10e6 ? 'unlimited' : limit}</li>)
        }
      </ul>
    ),
    [product]
  )

  return (
    <Card title={product.name} style={style.priceCard} bordered={false}>
      <div>
        {product.description}
        {bulletList}
        {product.type !== 'Internal' && product.price &&
          <Statistic value={product.price} prefix='$' suffix='/ month' />
        }
        {onAction && (
          <Form.Item
            validateStatus={product.disabledReason ? 'error' : undefined}
            help={product.disabledReason}
          >{product.isCurrentProduct
            ? (
              <i style={style.currentPlan}>Current account plan</i>
            )
            : (
              <Button
                disabled={product.disabled || product.isCurrentProduct}
                style={style.actionButton}
                type="primary"
                onClick={onAction}
              >{actionText}</Button>
            )
          }
          </Form.Item>
        )}
      </div>
    </Card>
  )
}

const style = {
  priceCard: {
    border: 'solid 1px #b2bccc',
    boxShadow: '4px 4px 1px 1px #c2cad6',
    margin: '18px 0',
  },
  actionButton: {
    marginTop: '18px',
  },
  currentPlan: {
    display: 'inline-block',
    marginTop: '18px',
  },
}
