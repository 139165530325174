import { produce } from 'immer'

import { LoadingStatus } from '../../types'
import { FetchAuthRequestAction, SetAuthAction, SignOutAction } from '../actions'
import { AssumedAuthz } from '../../utils/authzUtils'

interface AuthState {
  loadingStatus: LoadingStatus,
  userId: string | undefined,
  email: string | undefined,
  assumedAuthz: AssumedAuthz | undefined,
}
type AuthReducerAction =
  | SetAuthAction
  | FetchAuthRequestAction
  | SignOutAction

const initialState: AuthState = {
  loadingStatus: 'NotLoaded',
  userId: undefined,
  email: undefined,
  assumedAuthz: undefined,
}

export const authReducer = produce<AuthState, [AuthReducerAction?]>((state, action) => {
  switch(action?.type) {
    case 'SignOut': {
      return initialState
    }
    case 'FetchAuthRequest': {
      state.loadingStatus = 'Requested'
      break
    }
    case 'SetAuth': {
      state.loadingStatus = 'Loaded'

      state.userId = action.payload.userId
      state.email = action.payload.email
      state.assumedAuthz = action.payload.assumedAuthz

      break
    }
    default: {
      break
    }
  }
}, initialState)
