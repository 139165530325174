import { useCallback } from 'react'

import { Rating, RatingNotes } from '@vms/vmspro3-core/dist/types'
import {
  updateRating as updateRatingAC,
  updateRatingNotes as updateRatingNotesAC,
} from '@vms/vmspro3-core/dist/actions/decision'

import { RatingInterface } from '../RatingInterface/RatingInterface'

import { useAccount } from '../../../context'
import { useAuthUserParticipantContext } from './authUserParticipantContext'
import { useQuerystringValue } from '../../../hooks/useQuerystringValue'
import { useAppDispatch } from '../../../redux'
import { useNavigate } from 'react-router-dom'
import { useDecisionChildAncestry, useDecision } from '../../../redux/hooks'

interface ParticipationProps {
  decisionId: string,
  participationSessionId: string,
}
export function Participation({
  decisionId,
  participationSessionId,
}: ParticipationProps) {
  const { accountCommonId } = useAccount()
  const navigate = useNavigate()
  const backToDecision = useCallback(
    () => navigate(`/${accountCommonId}/decision/${decisionId}`),
    [navigate, accountCommonId, decisionId]
  )

  const decision = useDecision(decisionId)
  const participant = useAuthUserParticipantContext()

  const [contextId, setContextId] = useQuerystringValue('contextId')

  const dispatch = useAppDispatch()
  const ancestry = useDecisionChildAncestry(decisionId)
  const updateRating = useCallback(
    (rating: Omit<Rating, 'updated'>) => dispatch(updateRatingAC(ancestry, rating)),
    [dispatch, ancestry]
  )
  const updateRatingNotes = useCallback(
    (ratingNotes: Omit<RatingNotes, 'updated'>) => dispatch(updateRatingNotesAC(ancestry, ratingNotes)),
    [dispatch, ancestry]
  )

  return (
    <RatingInterface
      participationSessionId={participationSessionId}
      participantId={participant.id}
      contextId={contextId}
      setContextId={setContextId}
      decision={decision}
      participant={participant}
      updateRating={updateRating}
      updateRatingNotes={updateRatingNotes}
      backToDecision={backToDecision}
    />
  )
}
