import { batch } from 'react-redux'

import { RiskEntity_App } from '@vms/vmspro3-core/dist/types'

import { getRiskEntity, getRiskEntityChildren } from '../../services/riskEntitiesService'
import { AsyncAppThunk } from '../store'
import { LoadResourcePoliciesAction } from './policiesActions'

export interface FetchRiskEntityRequestAction {
  type: 'FetchRiskEntityRequest',
  meta: { ephemeral: true },
}
export interface FetchRiskEntitySuccessAction {
  type: 'FetchRiskEntitySuccess',
  payload: {
    entity: RiskEntity_App,
    ancestors: RiskEntity_App[],
  },
  meta: {
    ephemeral: true,
    entityId: string,
  },
}
export function fetchRiskEntity(entityId: string): AsyncAppThunk {
  return async dispatch => {
    try {
      const fetchRiskEntityRequest: FetchRiskEntityRequestAction = {
        type: 'FetchRiskEntityRequest',
        meta: { ephemeral: true },
      }
      dispatch(fetchRiskEntityRequest)

      const { entity, ancestors, policiesByResourceId } = await getRiskEntity(entityId)

      const fetchRiskEntitySuccess: FetchRiskEntitySuccessAction = {
        type: 'FetchRiskEntitySuccess',
        payload: {
          entity,
          ancestors,
        },
        meta: { ephemeral: true, entityId },
      }
      const loadResourcePolicies: LoadResourcePoliciesAction = {
        type: 'LoadResourcePolicies',
        payload: policiesByResourceId,
        meta: { ephemeral: true },
      }
      batch(() => {
        dispatch(fetchRiskEntitySuccess)
        dispatch(loadResourcePolicies)
      })
    } catch(error) {
      console.error('fetchRiskEntity error: ', error)
    }
  }
}

export interface FetchRiskEntityChildrenRequestAction {
  type: 'FetchRiskEntityChildrenRequest',
  meta: { ephemeral: true },
}
export interface FetchRiskEntityChildrenSuccessAction {
  type: 'FetchRiskEntityChildrenSuccess',
  payload: {
    entities: RiskEntity_App[],
  },
  meta: {
    ephemeral: true,
    childAncestry: string,
  },
}
export function fetchRiskEntityChildren(childAncestry: string): AsyncAppThunk {
  return async dispatch => {
    try {
      const fetchRiskEntityChildrenRequest: FetchRiskEntityChildrenRequestAction = {
        type: 'FetchRiskEntityChildrenRequest',
        meta: { ephemeral: true },
      }
      dispatch(fetchRiskEntityChildrenRequest)

      const { entities, policiesByResourceId } = await getRiskEntityChildren(childAncestry)

      const fetchRiskEntityChildren: FetchRiskEntityChildrenSuccessAction = {
        type: 'FetchRiskEntityChildrenSuccess',
        payload: { entities },
        meta: { ephemeral: true, childAncestry },
      }
      const loadResourcePolicies: LoadResourcePoliciesAction = {
        type: 'LoadResourcePolicies',
        payload: policiesByResourceId,
        meta: { ephemeral: true },
      }
      batch(() => {
        dispatch(fetchRiskEntityChildren)
        dispatch(loadResourcePolicies)
      })
    } catch(error) {
      console.error('fetchRiskEntityChildren error: ', error)
    }
  }
}
