import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { splitAncestry } from '@vms/vmspro3-core/dist/utils/ancestry'
import { PageHeader } from 'antd'

import { getPathnameTypeFromEntityType } from './riskModuleUtils'
import { useAccount } from '../../../context'

function RiskPortfolioNodeHeader(props) {
  const { entityId, extra, onBack, breadcrumbAdditions = [], title } = props
  const { accountCommonId } = useAccount()

  const nodeAncestors = useSelector(state =>
    splitAncestry(props.ancestry).map(id => state.riskEntities.byId[id]))
  const entity = useSelector(state => state.riskEntities.byId[entityId])

  const breadcrumb = {
    separator: '>',
    itemRender: ({ path, breadcrumbName }) => path
      ? <Link to={path}>{breadcrumbName}</Link>
      : breadcrumbName,
    routes: [
      { path: `/${accountCommonId}`, breadcrumbName: 'Home' },
      ...nodeAncestors.map(entity => ({
        path: entity.ancestry === '/'
          ? `/${accountCommonId}/port`
          : `/${accountCommonId}/${getPathnameTypeFromEntityType(entity.entityType)}/${entity.id}`,
        breadcrumbName: entity.name,
      })),
      {
        // only hyperlink the last node if there are breadcrumb additions
        path: breadcrumbAdditions.length
          ? `/${accountCommonId}/${getPathnameTypeFromEntityType(entity.entityType)}/${entityId}`
          : undefined,
        breadcrumbName: entity.name,
      },
      ...breadcrumbAdditions,
    ],
  }

  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={title || entity.name}
      onBack={onBack}
      extra={extra}
    />
  )
}

export default RiskPortfolioNodeHeader
