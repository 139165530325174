import { AccountPolicy_App, GlobalPolicy_App } from '@vms/vmspro3-core/dist/types'

import { getPolicies } from '../../services/policiesService'
import { AsyncAppThunk } from '../store'

export interface FetchPoliciesRequestAction {
  type: 'FetchPoliciesRequest',
  meta: { ephemeral: true },
}
export interface FetchPoliciesSuccessAction {
  type: 'FetchPoliciesSuccess',
  payload: { policies: GlobalPolicy_App[] },
  meta: { ephemeral: true },
}
export function fetchPolicies(): AsyncAppThunk {
  return async dispatch => {
    try {
      dispatch({
        type: 'FetchPoliciesRequest',
        meta: { ephemeral: true },
      })

      const policies = await getPolicies()

      dispatch({
        type: 'FetchPoliciesSuccess',
        payload: { policies },
        meta: { ephemeral: true },
      })
    } catch(error) {
      console.error(error)
    }
  }
}

export interface LoadResourcePoliciesAction {
  type: 'LoadResourcePolicies',
  payload: { [resourceId: string]: AccountPolicy_App[] },
  meta: { ephemeral: true },
}
