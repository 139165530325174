import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

import { AdHocParticipationProviders } from '../common/AdHocParticipation/AdHocParticipationProviders'
import { AdHocParticipation } from '../common/AdHocParticipation'

export function AdHocParticipationPage(): ReactElement {
  const {
    accountCommonId,
    decisionId,
    participationSessionId,
  } = useParams<'accountCommonId' | 'decisionId' | 'participationSessionId'>()

  invariant(accountCommonId, 'accountCommonId required in URL parameters')
  invariant(decisionId, 'decisionId required in URL parameters')
  invariant(participationSessionId, 'participationSessionId required in URL parameters')

  return (
    <AdHocParticipationProviders
      accountCommonId={accountCommonId}
      decisionId={decisionId}
      participationSessionId={participationSessionId}
    >
      <AdHocParticipation participationSessionId={participationSessionId} />
    </AdHocParticipationProviders>
  )
}
