import { useCallback, useReducer } from 'react'
import { Navigate, Outlet, Route } from 'react-router-dom'
import _keyBy from 'lodash/keyBy'

import RiskPortfolio from './components/RiskPortfolio'
import RiskEntityConfig from './components/RiskEntityConfig'
import RiskDetail from './components/Risk'
import {
  RiskModalContext,
  visibleModalsInitialState,
  visibleModalsReducer,
} from './RiskModalContext'

import { modals } from './modals'

const modalsById = _keyBy(modals, 'id')

function WithRiskModalContext() {
  const [state, dispatch] = useReducer(visibleModalsReducer, visibleModalsInitialState)

  const showModal = useCallback(
    (modalId: string, modalData: Record<string, unknown> = {}) => dispatch({
      type: 'ShowModal',
      modalId,
      modalData,
    }),
    []
  )

  const hideModal = useCallback(
    (modalId: string) => dispatch({
      type: 'HideModal',
      modalId,
    }),
    []
  )

  return (
    <RiskModalContext.Provider value={{ showModal, hideModal, byId: state.byId }}>
      {state.stack.map(modalId => {
        const Modal = modalsById[modalId]
        return <Modal key={modalId} />
      })}
      <Outlet />
    </RiskModalContext.Provider>
  )
}

export function riskRoutes() {
  return (
    <>
      <Route path="port" element={<WithRiskModalContext />}>
        <Route index element={<RiskPortfolio entityType="PORTFOLIO" />} />
        <Route path="config" element={<RiskEntityConfig />} />
        <Route path=":entityId" element={<RiskPortfolio entityType="PORTFOLIO" />} />
        <Route path=":entityId/config" element={<RiskEntityConfig />} />
      </Route>
      <Route path="prog" element={<WithRiskModalContext />}>
        <Route index element={<Navigate to="/port" />} />
        <Route path=":entityId" element={<RiskPortfolio entityType="PROGRAM" />} />
        <Route path=":entityId/config" element={<RiskEntityConfig />} />
      </Route>
      <Route path="proj" element={<WithRiskModalContext />}>
        <Route index element={<Navigate to="/port" />} />
        <Route path=":entityId" element={<RiskPortfolio entityType="PROJECT" />} />
        <Route path=":entityId/config" element={<RiskEntityConfig />} />
        <Route path=":projectId/risk/:riskId" element={<RiskDetail />} />
      </Route>
    </>
  )
}
