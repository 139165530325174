import { createSelector } from 'reselect'

import { RootState } from '../store'
import { selectUserPolicyIds } from './userSelectors'

import { alphanumericSortByKey } from '../../utils/sort-utils'
import { RolePolicy_App } from '@vms/vmspro3-core/dist/types'

export const selectUserPolicies = createSelector(
  selectUserPolicyIds,
  (state: RootState) => state.policies.byId,
  (userPolicyIds, policiesById) => userPolicyIds.map(policyId => policiesById[policyId])
)
export const selectSystemPolicies = createSelector(
  (state: RootState) => state.policies,
  state => state.ids.map(policyId => state.byId[policyId])
)

export const selectRolePolicies = createSelector(
  (state: RootState) => state.policies,
  state => state.ids
    .map(policyId => state.byId[policyId])
    .filter((policy): policy is RolePolicy_App => policy.policyType === 'ROLE')
)

type RolePolicyMenuConfig = Array<{
  title: string,
  items: Array<{
    title: string,
    key: string,
  }>,
}>

export const selectRolePolicyMenuConfig = createSelector(
  selectRolePolicies,
  (state: RootState) => state.policies.byId,
  (rolePolicies, policiesById): RolePolicyMenuConfig => {
    // separate role policies from template policies, group template policies by templateId
    type PolicyIndex = {
      byTemplateId: Record<string, RolePolicy_App[]>,
      roles: RolePolicy_App[]
    }
    const policies = rolePolicies.reduce<PolicyIndex>((_policies, rolePolicy) => {
      // TODO: it appears that template role policies didn't make the cut
      // after PfM... should we rip the remainder of these references out
      // since we would likely need to reimagine them anyway?
      const templateId = (rolePolicy as RolePolicy_App & { instanceOf: string }).instanceOf

      if(templateId) {
        if(!_policies.byTemplateId[templateId]) _policies.byTemplateId[templateId] = []
        _policies.byTemplateId[templateId].push(rolePolicy)
      } else {
        _policies.roles.push(rolePolicy)
      }

      return _policies
    }, { roles: [], byTemplateId: {} })

    const policiesToSortedMenuItems = (_policies: RolePolicy_App[]) => _policies
      .map(({ id, name }) => ({ key: id, title: name }))
      .sort(alphanumericSortByKey('title'))

    const policyMenuConfig: RolePolicyMenuConfig = [
      {
        title: 'Global Roles',
        items: policiesToSortedMenuItems(policies.roles),
      },
    ]

    Object.entries(policies.byTemplateId)
      .map(([id, policies]) => ({
        title: policiesById[id].name,
        items: policiesToSortedMenuItems(policies),
      }))
      .sort(alphanumericSortByKey('title'))
      .forEach(templatePolicyGroup => {
        policyMenuConfig.push(templatePolicyGroup)
      })

    return policyMenuConfig
  }
)
