import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'antd'

import { usePendingInvitationsForAccount, useCanUpdateSubscription } from '../redux/hooks'
import { selectAccountUsersQuota } from '../redux/selectors'

const unlimitedUsersQty = 10_000_000

/**
 * Returns a function wrapper to enforce account user quotas before invoking the
 * function. Wait for loading before invoking function
 *
 * @returns [enforceUsersQuota, loading]
 */
function useEnforceUsersQuota() {
  const accountUsersQuota = useSelector(selectAccountUsersQuota)

  const [pendingInvitations, loading] = usePendingInvitationsForAccount()

  const canUpdateSubscription = useCanUpdateSubscription()

  const navigate = useNavigate()
  const enforceUsersQuota = useCallback(
    callback => (...args) => {
      const { allowed, used } = accountUsersQuota ?? {}
      const effectiveUsed = used + pendingInvitations.length

      if(typeof allowed === 'number' && allowed < unlimitedUsersQty && effectiveUsed >= allowed) {
        if(canUpdateSubscription) {
          Modal.confirm({
            title: 'Plan Limits',
            okText: 'Change Plan',
            onOk: () => navigate('/subscription'),
            content: (
              <p>
                You're using {effectiveUsed} of {allowed} of the users allowed on
                your plan. If you need more users, you can change your plan
                on the subscriptions page.
              </p>
            ),
          })
        } else {
          Modal.info({
            title: 'Plan Limits',
            content: (
              <p>
                You're using {effectiveUsed} of {allowed} of the users allowed on
                your plan. If you need more users, please contact an administrator,
                who can upgrade your account's subscription.
              </p>
            ),
          })
        }
      } else {
        callback(...args)
      }
    },
    [canUpdateSubscription, navigate, accountUsersQuota, pendingInvitations]
  )

  return [enforceUsersQuota, loading]
}

export default useEnforceUsersQuota
