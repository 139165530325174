import React, { FocusEventHandler, useCallback, useEffect, useState } from 'react'
import { DurationUnit } from '@vms/vmspro3-core/dist/types'
import { Duration, DurationUnitMetadata } from '@vms/vmspro3-core/dist/utils/qty'
import { Input, Radio, RadioChangeEvent } from 'antd'
import _get from 'lodash/get'

import { formatNumValue, parseNumValue } from '../../utils/inputUtils'

const defaultUnitOptions: DurationUnit[] = [
  'Years',
  'Quarters',
  'Months',
  'Weeks',
  'Days',
]

interface DurationInputWithUnitsProps {
  allowNull?: boolean,
  decimalPlaces?: number,
  onBlur?: FocusEventHandler<HTMLInputElement>,
  onChange?: (duration: Duration | null) => void,
  readOnly?: boolean,
  defaultUnit: DurationUnit,
  unitOptions?: DurationUnit[],
  value?: Partial<Duration> | null,
}

export const DurationInputWithUnits = React.forwardRef<Input, DurationInputWithUnitsProps>(({
  allowNull = true,
  decimalPlaces = 0,
  onBlur,
  onChange,
  readOnly,
  defaultUnit,
  unitOptions = defaultUnitOptions,
  value,
  ...props
}, ref) => {
  if(!value) value = {}

  const [editing, setEditing] = useState(false)
  const [displayValue, setDisplayValue] = useState(() => formatNumValue(value?.value, {
    allowNull,
    decimalPlaces,
  }))
  const [selectedUnit, setSelectedUnit] = useState(value.unit ?? defaultUnit)

  useEffect(
    () => {
      if(!editing) setDisplayValue(formatNumValue(value?.value, { allowNull, decimalPlaces }))
    },
    [allowNull, decimalPlaces, editing, value.value]
  )
  useEffect(
    () => {
      if(!editing) setDisplayValue(formatNumValue(displayValue, { allowNull, decimalPlaces }))
    },
    [allowNull, decimalPlaces, editing, displayValue]
  )
  useEffect(
    () => {
      if(!editing) setSelectedUnit(value?.unit ?? defaultUnit)
    },
    [defaultUnit, editing, value.unit]
  )

  const handleValueBlur: FocusEventHandler<HTMLInputElement> = event => {
    setEditing(false)
    if(onBlur) onBlur(event)
  }

  const handleOnChange = useCallback(
    (unit: DurationUnit, value: string) => {
      if(unit && onChange) {
        const parsedValue = parseNumValue(value, allowNull) ?? undefined
        const duration = Number.isFinite(parsedValue) ? new Duration(unit, parsedValue) : null
        onChange(duration)
      }
    },
    [allowNull, onChange]
  )

  const handleValueChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setEditing(true)
      const v = event.target.value.replaceAll(/[^\d,.-]/g, '')
      setDisplayValue(v)
      handleOnChange(selectedUnit, v)
    },
    [handleOnChange, selectedUnit]
  )

  const handleUnitsChange = useCallback(
    (event: RadioChangeEvent) => {
      setSelectedUnit(event.target.value)
      handleOnChange(event.target.value, displayValue)
    },
    [handleOnChange, displayValue]
  )

  if(readOnly) {
    const valueSuffix = _get(DurationUnitMetadata, [selectedUnit, 'label'], selectedUnit || '')
    return (
      <div
        ref={ref as React.LegacyRef<HTMLDivElement>}
        style={{
          minHeight: '32px',
          padding: '5px 12px 6px',
          textAlign: 'right',
          width: '100%',
        }}
      >
        {(typeof value.value === 'undefined' || value.value === null)
          ? null
          : <span>{displayValue}&nbsp;{valueSuffix}</span>
        }
      </div>
    )
  }

  return (
    <Input.Group style={{ display: 'flex', gap: '8px' }}>
      <Input
        ref={ref}
        {...props}
        onBlur={handleValueBlur}
        onChange={handleValueChange}
        value={displayValue}
        style={{ textAlign: 'right' }}
      />
      <Radio.Group
        onChange={handleUnitsChange}
        value={selectedUnit}
        style={{ whiteSpace: 'nowrap' }}
      >
        {unitOptions.map(unit => (
          <Radio.Button key={unit} style={{ padding: '0 6px' }} value={unit}>
            {DurationUnitMetadata[unit]?.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Input.Group>
  )
})
