import { useCallback, ReactElement } from 'react'
import { Form, Input, Modal } from 'antd'

import { Html } from '@vms/vmspro3-core/dist/types'

import { updateDecision } from '@vms/vmspro3-core/dist/actions/decision'
import { createHtmlObject } from '@vms/vmspro3-core/dist/utils'

import { useAppDispatch } from '../../../redux'
import { useDecisionData } from '../../../redux/hooks'
import { useInitialFocusRef } from '../../../hooks/useInitialFocusRef'

interface EditDecisionFormFields {
  name: string,
  description: Html,
}
interface DecisionEditModalProps {
  decisionId: string,
  hide: VoidFunction,
}
export function DecisionEditModal({
  decisionId,
  hide,
}: DecisionEditModalProps): ReactElement {
  const [form] = Form.useForm<EditDecisionFormFields>()

  const dispatch = useAppDispatch()
  const onOk = useCallback(
    () => form.validateFields()
      .then(({ name, description }) => {
        dispatch(updateDecision(decisionId, {
          name,
          description: createHtmlObject(description?.value || null),
        }))
        hide()
      }),
    [form, dispatch, decisionId, hide]
  )

  const decision = useDecisionData(decisionId)

  return (
    <Modal visible onOk={onOk} onCancel={hide}>
      <h3>Editing {decision.name}</h3>
      <Form form={form} layout="vertical" initialValues={decision}>
        <Form.Item label="Name" name="name">
          <Input ref={useInitialFocusRef()} />
        </Form.Item>
        <Form.Item label="Description" name={['description', 'value']}>
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  )
}
