import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Menu as AntMenu } from 'antd'

import { Module } from '@vms/vmspro3-core/dist/systemConsts'
import { actions } from '@vms/vmspro3-core/dist'
import { Account } from '@vms/vmspro3-core/dist/types'

import useAuthz from '../../../hooks/useAuthz'
import config from '../../../config.json'
import { useCanUpdateSubscription } from '../../../redux/hooks'

interface MenuProps {
  account?: Account,
  menuOpen: boolean,
  closeMenu: VoidFunction,
}

/**
 * Renders application menu. Menu is shown when state.app.menuOpen is true, nothing
 * when false. Component memoization is added on export to preserve component name
 * in the React devtools component explorer.
 */
export function Menu({ account, menuOpen, closeMenu }: MenuProps) {
  const location = useLocation()
  const canUpdateSubscription = useCanUpdateSubscription()

  const navigate = useNavigate()
  const onMenuClick = useCallback(
    ({ key }) => {
      navigate(key)
      closeMenu()
    },
    [navigate, closeMenu]
  )

  const authz = useAuthz()
  const showRiskManagement = useMemo(
    () => authz(actions.feature.useModule({}, { moduleKey: Module.PORTFOLIO_RISK })),
    [authz]
  )

  const helpdesk = config.instance?.helpdesk
  const showHelpdesk = !!helpdesk

  return (
    <>
      <div style={style.container(menuOpen)}>
        <AntMenu
          mode="inline"
          onClick={onMenuClick}
          selectedKeys={[location.pathname]}
          theme="dark"
        >
          {account &&
            <>
              <AntMenu.Item key={`/${account.commonId}`}>Home</AntMenu.Item>
              {showRiskManagement &&
                <AntMenu.Item key={`/${account.commonId}/port`}>Risk Management</AntMenu.Item>
              }
              {!!account.quotas.decisions &&
                <AntMenu.Item key={`/${account.commonId}/decision`}>Decision Support</AntMenu.Item>
              }
              <AntMenu.SubMenu key="admin" title="Admin">
                <AntMenu.Item key={`/${account.commonId}/users`}>User Management</AntMenu.Item>
                <AntMenu.Item key={`/${account.commonId}/roles`}>Roles</AntMenu.Item>
                {canUpdateSubscription &&
                  <AntMenu.Item key={`/${account.commonId}/subscription`}>Subscription</AntMenu.Item>
                }
                {authz(actions.account.readInvoices()) &&
                  <AntMenu.Item key={`/${account.commonId}/invoices`}>Invoices</AntMenu.Item>
                }
              </AntMenu.SubMenu>
            </>
          }
          {showHelpdesk &&
            <AntMenu.Item key={helpdesk.key} onClick={() => window.open(helpdesk.url, '_blank')}>
              {helpdesk.title}
            </AntMenu.Item>
          }
        </AntMenu>
        <div style={style.footer}>
          Version {process.env.REACT_APP_VMSPRO_VERSION} (build {process.env.REACT_APP_GIT_HEAD_COMMIT_ID})
          <br />
          &copy; {new Date().getFullYear()} VMS, Inc.
        </div>
      </div>
      <div onClick={closeMenu} style={menuOpen ? style.overlayBlock : style.overlayHidden} />
    </>
  )
}

const antDesignMenuDarkBackground = '#001529'

const style = {
  container: (menuOpen: boolean) => ({
    '--menu-open-width': '300px',
    width: menuOpen ? 'var(--menu-open-width)' : '0',
    height: '100%',
    zIndex: 10001,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: antDesignMenuDarkBackground,
    color: 'rgba(255, 255, 255, 0.65)',
    fontWeight: 300,
    boxShadow: '4px 0px 13px -2px rgba(0, 0, 0, .3)',
    transition: 'width 120ms ease-in-out',
    overflowX: 'hidden',
    position: 'absolute',
  }) as React.CSSProperties,
  footer: {
    marginBottom: '20px',
    textAlign: 'center',
    width: 'var(--menu-open-width)',
  } as React.CSSProperties,
  overlayHidden: {
    display: 'none',
  },
  overlayBlock: {
    display: 'block',
    zIndex: 10000,
    height: '100vh',
    width: '100vw',
    position: 'fixed',
  },
  sider: {
    fontWeight: '300',
    boxShadow: '4px 0px 13px -2px rgba(0, 0, 0, .3)',
    position: 'fixed',
    height: 'calc(100vh - 52px)',
    zIndex: 10001,
  },
}
