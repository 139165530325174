import { useCallback, useEffect, useState } from 'react'

import { getObject, setObject } from '../../../utils/localStorageUtils'

/**
 * Custom React hook to manage form draft state in localStorage and local component state. The consuming
 * component will be required to pass in a unique key for storing the draft state in localStorage and a
 * function to handle the draft state when the form is submitted.
 *
 * @param {string} storageKey - unique key for use with localStorage
 * @param {function} onSubmit - function to handle draft state when submitted
 * @returns {Array} -
 *    [
 *      draftState - object containing form fields draft state
 *      setDraftState - function to set draft state
 *      resetDraftState - function to clear draft state from localStorage and local state
 *      submit - function to trigger submitting draft data and clear draft state
 *    ]
 */
function useFormDraftState(storageKey, onSubmit) {
  const [draftState, setState] = useState(() => getObject(storageKey))

  useEffect(() => setState(getObject(storageKey)), [storageKey])

  const setDraftState = useCallback(payload => setState(state => {
    const nextState = { ...state, ...payload }
    setObject(storageKey, nextState)
    return nextState
  }), [storageKey])

  const resetDraftState = useCallback(() => {
    localStorage.removeItem(storageKey)
    setState(null)
  }, [storageKey])

  const submit = useCallback(() => {
    onSubmit(draftState, resetDraftState)
  }, [onSubmit, draftState, resetDraftState])

  return [draftState, setDraftState, resetDraftState, submit]
}

export default useFormDraftState
