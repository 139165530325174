import _keyBy from 'lodash/keyBy'
import _groupBy from 'lodash/groupBy'
import _filter from 'lodash/filter'
import _map from 'lodash/map'
import { ValidRating } from '../types'

import { ParticipationSession } from './participationSession'
import { Criterion, CriterionData, CriterionPriority, RatingScaleConfig } from './Criterion'
import { prioritizeContext, RatingToPrioritizationAlgorithm, recenterRatings } from './prioritization/quickPrioritization'
import { treeToString } from '../utils/str'
import { vectorMean } from '../valuemetrics/util'

export const defaultCriteriaPrioritizationScaleConfig: RatingScaleConfig = {
  maxRatingLabel: 'More Important',
  minRatingLabel: 'Less Important',
  abstainLabel: 'No Opinion',
  maxRating: 10,
  minRating: 0,
  ratingScale: [],
}

export const defaultOptionRatingScaleConfig: RatingScaleConfig = {
  maxRatingLabel: 'Higher Perf.',
  minRatingLabel: 'Lower Perf.',
  abstainLabel: 'No Opinion',
  maxRating: 10,
  minRating: 0,
  ratingScale: [
    { label: 'High Performance', maxValue: 10 },
    { label: 'Med. Performance', maxValue: 20 / 3 },
    { label: 'Low Performance', maxValue: 10 / 3 },
  ],
  ratingToScoreMap: [
    [ 0,  0],
    [10, 10],
  ],
}

/**
 * When aggregating prioritization data, we use different algorithms:
 *
 *  - AverageRatingsRecentered: for each criteria, average all participant ratings and "recenter"
 *    them (as in the RecenterAndNormalize rating-to-priority algorithm).
 *  - Space90: order all criteria by priority, and evenly space, taking up 90% of the scale.  Used
 *    for aggregating off-axis ratings.
 */
export type PrioritizationAggregationAlgorithm =
  | 'AverageRatingsRecentered'
  | 'AverageRatings'
  | 'Space90'


/**
 * Implementation of Criterion.  Note that Criterion instances are intended to be constructed
 * by the Criteria class, so this implementation is not exported, but it accessible (as
 * interface Criterion) through the Criteria properties.
 */
class CriterionInternal implements Criterion {
  _data: CriterionData

  get id() { return this._data.id }
  get name() { return this._data.name }
  get abbrev() { return this._data.abbrev }
  get description() { return this._data.description || { $type: 'html', value: '' } }
  get type() { return this._data.type }
  get color() { return this._data.color }
  get optionRatingScaleConfig() { return this._data.optionRatingScaleConfig }

  _localPri: number | null = null
  _localPriByParticipantId: Record<string, number> = {}
  _ratings: ValidRating[] = []
  /**
   * This should only be called from Criteria instance.
   */
  setParticipationData(
    localPri: number | null,
    participantPriorities: Record<string, number>,
    ratings: ValidRating[],
  ) {
    this._localPri = localPri
    this._localPriByParticipantId = participantPriorities
    this._ratings = ratings
  }

  get pri(): Readonly<CriterionPriority> {
    // if it doesn't have a parent, it's a root, with an implicit global priority of 1
    const parentGlobal = this.parent ? this.parent.pri.global : 1
    const local = this._localPri
    const global = parentGlobal !== null && local !== null ? parentGlobal * local : null
    return { local, global }
  }
  get localPriByParticipantId(): Readonly<Record<string, number>> {
    return this._localPriByParticipantId
  }

  get ratings() {
    return this._ratings
  }

  /**
   * The label for this criterion, which includes its ancestry (akin to a crumb trail).
   */
  label(options: { abbrev?: boolean, skipAncestors?: number, sep?: string } = {}): string {
    const {
      abbrev = false,
      sep = ' > '
    } = options
    let { skipAncestors = 0 } = options
    const criteria = [...this.ancestors, this]
    while(skipAncestors-- && criteria.length > 1) criteria.shift()
    return criteria.map(c => abbrev ? c.abbrev : c.name).join(sep)
  }

  parent: Criterion | null = null
  children: Criterion[] = []

  get ancestors(): Criterion[] {
    return this.parent ? [...this.parent.ancestors, this.parent] : []
  }
  get descendants(): Criterion[] {
    return [
      ...this.children,
      ...this.children.map(c => c.descendants).flat(),
    ]
  }
  get isRoot() { return !this.parent }
  get isLeaf() { return this.children.length === 0 }
  get isInternal() { return !this.isRoot && !this.isLeaf }

  get data() { return this._data }

  /**
   * Converts a rating against this criteria to a score.  Normally, this is an idempotent
   * mapping, but criteria can have rating-to-score maps attached to them that change
   * the response curve of people's ratings (or even invert it).  See the
   * `ratingToScoreMap` property in `RatingScaleConfig` for more information.
   */
  ratingToScore(rating: number | null): number | null {
    if(rating === null) return null
    const { ratingToScoreMap } = this.data.optionRatingScaleConfig
    if(!ratingToScoreMap) return rating
    for(let i = 0; i < ratingToScoreMap.length; i++) {
      const [rMin, sMin] = ratingToScoreMap[i]
      if(rating === rMin) return sMin
      if(i === ratingToScoreMap.length - 1) throw new Error(`rating out of range: ${rating}`)
      const [rMax, sMax] = ratingToScoreMap[i + 1]
      if(rating === rMax) return sMax
      if(rating > rMax) continue
      return sMin + (sMax - sMin) * (rating - rMin) / (rMax - rMin)
    }
    throw new Error(`rating out of range: ${rating}`)
  }

  /**
   * WIP: this is a stub; it provides real data, but it's not scaled/spaced properly yet.
   *
   * Gets aggregated ratings that reflect the priorities of children in this prioritization
   * context.  It essentially "reverse engineers" ratings from the aggregate of all participant
   * ratings.  Note that child criteria without any ratings will be assumed to be 0 priority.
   */
  getAggregateChildRatings(
    ratingAggregationAlgorithm: PrioritizationAggregationAlgorithm = 'AverageRatingsRecentered',
    offAxisAggregationAlgorithm: PrioritizationAggregationAlgorithm = 'Space90',
  ): ValidRating[] {
    if(!this.criteria.participationSession) throw new Error('Criteria must have a participation session')
    const participationSessionId = this.criteria.participationSession.id
    if(ratingAggregationAlgorithm !== 'AverageRatingsRecentered') {
      throw new Error(`rating algorithm not supported: ${ratingAggregationAlgorithm}`)
    }
    if(offAxisAggregationAlgorithm !== 'Space90') {
      throw new Error(`off-axis algorithm not supported: ${offAxisAggregationAlgorithm}`)
    }
    const aggregateRatings = recenterRatings(
      _map(
        _groupBy(
          _filter(
            this.criteria.participationSession.validRatings,
            { contextType: 'Criterion', contextId: this.id, subjectType: 'Criterion' }
          ),
          'subjectId'
        ),
        (ratings, subjectId) => ({
          participationSessionId,
          participantId: '*',
          contextType: 'Criterion',
          contextId: this.id,
          subjectType: 'Criterion',
          subjectId,
          ratingVector: vectorMean(ratings.map(r => r.ratingVector)),
          abstain: false,
          updated: {
            timestamp: Date.now(),
            location: '{}',
          }
        })
      )
    )
    // instead of using the average, we're evenly spacing the ratings out on the
    // x-axis, after sorting by rating
    return aggregateRatings
      .sort((a, b) => a.ratingVector[0] - b.ratingVector[0])
      .map((r, idx, l) => ({
        ...r,
        ratingVector: [r.ratingVector[0], idx/(l.length - 1)*9 + 0.5],
      }))
  }

  constructor(data: CriterionData, readonly criteria: Criteria) {
    this._data = data
  }

}

/**
 * Represents a coherent collection (or graph) of criteria (Criterion instances).
 * This class is primarily for constructing Criterion instances, and providing access
 * methods (indexing by ID, identifying root and performance roots, in particular).
 * You can also attach a participation session, which enables the Criterion to have
 * prioritization data.
 */
export class Criteria {

  private _all: CriterionInternal[]
  private _byId: Record<string, CriterionInternal>
  private _root: CriterionInternal
  private _perfRoot: CriterionInternal

  private _participationSession?: ParticipationSession

  get byId(): Record<string, Criterion> { return this._byId }
  get all(): Criterion[] { return this._all }

  /**
   * Convenience function to get all leaf criteria.  Equivalent to #all.filter(c => c.isLeaf).
   */
  get leafCriteria(): Criterion[] { return this.all.filter(c => c.isLeaf) }

  /**
   * Convenience function to get all context criteria.  Context criteria include the root
   * performance criterion, and all criteria of type 'Rated' that are not leaf criteria,
   * as well as children of IntrinsicRoot (type Intrinsic).
   */
  get contextCriteria(): Criterion[] {
    return this._all.filter(c =>
      c.type === 'IntrinsicRoot' ||
      c.type === 'Performance' ||
      c.type === 'Rated' && !c.isLeaf)
  }

  /**
   * The root criterion.
   */
  get root(): Criterion { return this._root }

  /**
   * The root performance criterion.
   */
  get perfRoot(): Criterion { return this._perfRoot }

  constructor(criteriaData: CriterionData[]) {
    this._all = criteriaData.map(cd => new CriterionInternal(cd, this))
    this._byId = _keyBy(this._all, 'id')
    let root: Criterion | undefined = undefined
    let perfRoot: Criterion | undefined = undefined
    this._all.forEach(c => {
      if(c._data.parentId) {
        const parent = this._byId[c._data.parentId]
        if(!parent) throw new Error(`parent ID refers to missing criterion: ${c._data.parentId}`)
        c.parent = parent
      } else {
        c.parent = null
        if(root) throw new Error(`multiple roots found`)
        root = c
      }
      if(c.type === 'Performance') {
        if(perfRoot) throw new Error(`multiple performance roots found`)
        perfRoot = c
      }
      c.children = this._all.filter(c2 => c2._data.parentId === c.id)
    })
    if(!root) throw new Error(`no root criterion found`)
    if(!perfRoot) throw new Error(`no root performance criteria found`)
    this._root = root
    this._perfRoot = perfRoot
  }

  get participationSession() { return this._participationSession }

  useParticipationSession(
    ps?: ParticipationSession,
    ratingToPrioritizationAlgorithm: RatingToPrioritizationAlgorithm = 'RecenterAndNormalize'
  ) {
    this._participationSession = ps
    // when a participation context is set, we can calculate priorities
    if(ps) {
      const byContext = _groupBy(ps.validRatings, 'contextId')
      this._root.setParticipationData(1, {}, [])
      this._perfRoot.setParticipationData(1, {}, [])
      this.contextCriteria.forEach(ctx => {
        const priByCri = prioritizeContext(ctx.children, byContext[ctx.id], ratingToPrioritizationAlgorithm)
        Object.entries(priByCri).forEach(([criterionId, { aggregate, byParticipantId }]) => {
          this._byId[criterionId].setParticipationData(
            aggregate,
            byParticipantId,
            (byContext[ctx.id] || []).filter(r => r.subjectId === criterionId),
          )
        })
      })
    } else {
      this._all.forEach(c => c.setParticipationData(null, {}, []))
    }
  }

  log() {
    console.group()
    console.log(
      treeToString(
        this.root,
        c => c.children,
        c => `${c.id} ${c.name} ${c.pri.local === null ? '-' : c.pri.local.toFixed(3)}`,
      )
    )
    console.groupEnd()
  }

}
