import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { BarChart, Bar, Cell, Tooltip, XAxis, YAxis } from 'recharts'
import _keyBy from 'lodash/keyBy'
import _sum from 'lodash/sum'
import { EntityType } from '@vms/vmspro3-core/dist/systemConsts'
import unflattenDeep from '@vms/vmspro3-core/dist/utils/unflattenDeep'
import { Col, List, Row } from 'antd'

import SeverityDisplay from './SeverityDisplay'
import useEffectiveRiskContext from '../hooks/useEffectiveRiskContext'
import { getRiskAggregateData, getTopRisks } from '../utils/riskDataAggregation'
import { formatNumValue } from '../../../utils/formatUtils'
import Server from '../../../server/VMSProServerAdapter'
import { useAccount } from '../../../context'

// TODO: working with these charts convinces me that we need to (re)ditch recharts.  either
// that or learn to use them better...the stacked bars in particular are extremely problematic.
// i'm not spending a lot of time with documentation, testing, or performance optimization
// because i think we need to make fundamental changes here.

/**
 * The format necessary for stacked bars in Recharts is extremely frustrating when
 * you're not dealing with unified series (as we are).  For more information, see:
 * https://medium.com/@kogy92/recharts-stack-order-bf22c245d0be
 *
 * @example
 *
 *   const { data, bars } = categoryCountsToRechartsStackedBarData(countsByValue, categories)
 *   return (
 *     <BarChart data={data}>
 *       {bars.map((dataKey, i) => (
 *         <Bar key={dataKey} dataKey={dataKey} stackId="_" fill={getBarColor()} />
 *       ))}
 *     </BarChart>
 *   )
 *
 * @param {object} countsByValue - Category counts by category value.  See getRiskAggregateData
 *   in src/utils/riskDataAggregation.js for more info.
 * @param {object[]} categories - Category data from risk context.
 *
 * @returns {object} Object containing "data" and "bars" properties for use with
 *   stacked bars in recharts.  See example.
 */
export const categoryCountsToRechartsStackedBarData = (countsByValue, categories) => {
  // filter out deleted categories...we're disregarding all of them
  categories = categories.filter(c => !c.isDeleted)
  const topLevelCategories = categories.filter(c => c.ancestry === '/')
  // we're using c0 to refer to top-level categories, c1 to refer to subcategories, etc.
  const data =  topLevelCategories.map(c0 => ({
    name: c0.label.short,
    // top-level count
    ['count:' + c0.value]: countsByValue[c0.value] || 0,
    // child counts (with summed descendents)
    ...categories.filter(c => c.ancestry === '/' + c0.value).reduce((o, c1) => Object.assign(o, {
      ['count:' + c1.value]: _sum([
        // level 1 count
        countsByValue[c1.value] || 0,
        // counts of descendents of level 1
        ...categories
          .filter(c => c.ancestry.startsWith(`${c1.ancestry}/${c1.value}`))
          .map(c => countsByValue[c.value] || 0),
      ]),
    }), {}),
  }))
  const bars = categories.map(c => `count:${c.value}`)
  return { data, bars }
}

const getBarColor = key => {
  switch(key) {
    // note: these are not in color consts, but came straight from Jeremy, need to resolve.
    case 'THREAT': return '#aa2211'
    case 'OPPORTUNITY': return '#426485'
    default: return '#1890ff' // antd's daybreak blue_6 likely want to add to Color
  }
}

const statDetailsByKey = {
  category: {
    label: 'Category',
    color: key => getBarColor(key),
  },
  phase: {
    label: 'Phase',
    color: key => getBarColor(key),
  },
  type: {
    label: 'Type',
    color: key => getBarColor(key),
  },
  probQual: {
    label: 'Qualitative Probablity',
    color: key => getBarColor(key),
  },
}

const detailsByKey = {
  topRisksByCostEv: {
    title: 'Top 5 Risks By Expected Cost Value',
    key: 'ev',
  },
  topRisksBySeverity: {
    title: 'Top 5 Risks By Total Severity',
    key: 'totalSeverity',
  },
}

const styles = {
  // this matches Recharts built-in tooltip style
  tooltipContainer: {
    backgroundColor: 'white',
    padding: '10px',
    border: '1px solid rgb(204, 204, 204)',
  },
}

const CategoryChartTooltip = ({ categoriesByValue, payload, label }) => (
  <div style={styles.tooltipContainer}>
    <h1>{label}</h1>
    <ul>
      {payload?.reverse().map(({ dataKey, value }) => {
        const categoryValue = dataKey.split(':')[1]
        return <li key={dataKey}>{categoriesByValue[categoryValue].label.long}: {value}</li>
      })}
    </ul>
  </div>
)

const CategoryChart = ({ detail }) => {
  const { countsByValue, categories, categoriesByValue } = detail.data

  const { data, bars } = categoryCountsToRechartsStackedBarData(countsByValue, categories)

  return (
    <BarChart width={400} height={300} data={data}>
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip content={<CategoryChartTooltip categoriesByValue={categoriesByValue} />} />
      {// there will be as many bars as there are level 0 and level 1 categories
        bars.map(dataKey => <Bar key={dataKey} dataKey={dataKey} stackId="_" fill={getBarColor()} />)
      }
    </BarChart>
  )
}

const GenericChart = ({ detail }) => (
  <BarChart width={400} height={300} data={detail.data}>
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
    <Bar dataKey="count">
      {detail.data.map(({ name }) => (
        <Cell fill={statDetailsByKey[detail.key].color(name)} key={name} />
      ))}
    </Bar>
  </BarChart>
)

// may not even need to pass in ancestry, it could be calculated in here from the enitity ID, but it seems like
// the parent will almost always be calculating it, so maybe it is just fine to pass it in.
const AggregateRiskDataDisplay  = ({ riskAncestry, parentId }) => {
  const effectiveRiskContext = useEffectiveRiskContext(parentId)
  const { accountCommonId } = useAccount()

  const [risks, setRisks] = useState([])
  // TODO: this needs some thought put into it; we need to figure out how we're going to manage
  // loading of this data since it's realtively expensive.  also, our aggregation is currently
  // using category & phase IDs, which need to mapped to labels
  // https://vms.atlassian.net/wiki/spaces/VMSPRO3/pages/657260612/Data+Loading+Access+for+Risk+Aggregation+Data
  useEffect(() => {
    const projection = [
      'category',
      'phase',
      'type',
      'prob.qual',
      'ancestry',
      'name',
      'id',
      'impact.cost.quant.ev',
      'managed.impact.cost.quant.ev',
      'severity.total',
      'managed.severity.total',
    ]
    Server.getItemsByAncestryBeginsWith(riskAncestry, EntityType.RISK, projection).then(({ entities: risks }) => {
      setRisks(unflattenDeep(risks))
    })
  }, [riskAncestry])

  const chartDetails = useMemo(() => {
    const riskTotals = getRiskAggregateData(risks, effectiveRiskContext)
    const categoriesByValue = _keyBy(effectiveRiskContext.types.category.values, 'value')
    return Object.entries(riskTotals || {}).map(([key, v]) => {
      if(key === 'category') {
        return {
          key,
          data: { countsByValue: v, categories: effectiveRiskContext.types.category.values, categoriesByValue },
        }
      }
      return {
        key,
        data: Object.entries(v).map(([innerKey, count]) => {
          const typeValues = effectiveRiskContext.types[key]?.values
          const name = typeValues
            ? typeValues.find(e => e.value === innerKey).label.short
            : innerKey
          return { name, count }
        }).filter(Boolean),
      }
    })
  }, [risks, effectiveRiskContext])

  const topRisks = useMemo(() => {
    const _topRisks = getTopRisks(accountCommonId, risks)
    return Object.entries(_topRisks || {}).map(([key, risks]) => ({ key, risks }))
  }, [accountCommonId, risks])

  if(!risks.length) return null

  return (
    <Row style={style.container} gutter={12}>
      {topRisks.map(({ key, risks }) => (
        <Col span={12} key={key}>
          <List
            bordered
            style={{ marginBottom: '24px' }}
            header={<div>{detailsByKey[key].title}</div>}
            dataSource={risks}
            renderItem={risk => {
              const value = risk[detailsByKey[key].key]
              return (
                <List.Item>
                  <Link to={risk.riskUrl}>{risk.name}</Link>
                  {key === 'topRisksByCostEv'
                    ? <span>
                      {Number.isFinite(value) && '$'} {formatNumValue(value, true, 0)}
                    </span>
                    : <SeverityDisplay severity={value} />
                  }
                </List.Item>
              )
            }}
          />
        </Col>
      ))}
      {chartDetails.map(detail => (
        <Col span={12} key={detail.key}>
          <span style={style.chartTitle}>Risks per {statDetailsByKey[detail.key].label}</span>
          {detail.key === 'category'
            ? <CategoryChart detail={detail} />
            : <GenericChart detail={detail} />
          }
        </Col>
      ))}
    </Row>
  )
}

const style = {
  chartTitle: {
    marginLeft: '50px',
  },
  container: {
    marginTop: '32px',
  },
}

export default AggregateRiskDataDisplay
