import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { gql } from 'graphql-tag'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { Row, Col, Card } from 'antd'

import { Subscription } from '@vms/vmspro3-core/dist/types'

import { SubscriptionInfo } from './SubscriptionInfo'
import { SubscriptionEditor } from './SubscriptionEditor'
import { Page } from '../../common/Page'

import Server from '../../../server/VMSProServerAdapter'
import { useAccount } from '../../../context'

const subscriptionQuery = gql`
  query AccountSubscription {
    subscription {
      id
      currentPeriodEnd
      currentMonthlyBill
      paymentMethod { last4, brand }
    }
  }
`

// TODO: clean up the architure involving updateSubscription, SubscriptionEditor and further descendants
export function SubscriptionPage() {
  const [subscription, setSubscription] = useState<Subscription | undefined>(undefined)

  useEffect(
    () => {
      Server.graphql({ query: subscriptionQuery }).then(response => {
        // TODO: pass generic params through server adapter to amplify proxy methods
        const res = response as GraphQLResult<{ subscription: Subscription }>
        setSubscription(res.data?.subscription)
      })
    },
    []
  )

  // TODO: this is a hacky way to get around the fact that this wasn't done in the Redux way;
  const updateSubscription = useCallback(
    (update: Partial<Pick<Subscription, 'paymentMethod' | 'currentMonthlyBill'>>) => {
      setSubscription(subscription => {
        if(!subscription) return

        return {
          ...subscription,
          ...update,
        }
      })
    },
    []
  )

  const { accountCommonId } = useAccount()

  return (
    <Page loading={!subscription} title="Subscription">
      {subscription && (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <Card bordered={false}>
                <SubscriptionInfo subscription={subscription} />
              </Card>
            </Col>
            <Col span={12}>
              <Card bordered={false}>
                <SubscriptionEditor onSubscriptionChange={updateSubscription} subscription={subscription} />
              </Card>
            </Col>
          </Row>
          <div css={style.invoices}>
            Looking for <Link to={`/${accountCommonId}/invoices`}>invoices</Link>?
          </div>
        </>
      )}
    </Page>
  )
}

const style = {
  container: {
    margin: '24px 30px',
    padding: '12px 24px',
  },
  invoices: {
    marginTop: '32px',
  },
}
