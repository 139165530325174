import { useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import _keyBy from 'lodash/keyBy'
import _groupBy from 'lodash/groupBy'
import _mapValues from 'lodash/mapValues'
import _omit from 'lodash/omit'
import _matches from 'lodash/matches'
import { Button, Modal } from 'antd'
import { Column, TableInstance } from 'react-table'
import htmlToFormattedText from 'html-to-formatted-text'
import { DeleteOutlined } from '@ant-design/icons'

import { ParticipationSessionType, Rating, RatingVector } from '@vms/vmspro3-core/dist/types'
import { vectorMean } from '@vms/vmspro3-core/dist/valuemetrics/util'
import { filterInvalidRatings } from '@vms/vmspro3-core/dist/valuemetrics/prioritization'
import * as decisionActions from '@vms/vmspro3-core/dist/actions/decision'
import { prioritization } from '@vms/vmspro3-core'
import { createTree, getLeafNodes, getNodeLabels } from '@vms/vmspro3-core/dist/utils/createTree'

import { Table, TableCellRenderer } from '../Table'
import { getNumberCellRenderer } from '../Table/TableCellRenderer/getNumberCellRenderer'
import {
  useCriteria,
  useDecisionData,
  useParticipant,
  useRatingNotes,
  useParticipationSessionRatings,
  useRootPerfCriterionId,
} from '../../../redux/hooks'

type RatingsTableData = {
  context: string,
  color: string,
  participantNotes: string,
  participantRating?: Rating,
  groupRating?: number,
  participantPri?: number,
  groupPri?: number,
  rating: Rating,
}

type useRatingsTableArgs = {
  decisionId: string,
  participationSessionType: ParticipationSessionType,
  pivotType: 'context' | 'subject',
  pivotId: string,
  subjectType: 'Criterion' | 'Option',
  participantId: string,
  showPriorities: boolean,
}
function useRatingsTable(args: useRatingsTableArgs) {

  const {
    decisionId,
    participationSessionType,
    pivotType,
    pivotId,
    subjectType,
    participantId,
    showPriorities = false,
  } = args
  const participant = useParticipant(participantId)
  const participantName = participant?.fullName
  const decision = useDecisionData(decisionId)

  const columns = useMemo<Column<RatingsTableData>[]>(
    () => {
      let labelCol: Column<RatingsTableData> | undefined = undefined
      const RatingRenderer = getNumberCellRenderer({
        formatter: Intl.NumberFormat(undefined, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }),
      })
      const PriorityRenderer = getNumberCellRenderer({
        formatter: Intl.NumberFormat(undefined, {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        }),
      })
      switch(pivotType) {
        case 'context':
          // if we're pivoting on the context, our subject can either be a criterion (as in child
          // criterion rating) or option rating
          switch(subjectType) {
            case 'Criterion':
              labelCol = {
                Header: 'Child Criterion',
                accessor: 'context',
                excel: { width: 65 },
                Cell: TableCellRenderer.EntityName,
              }
              break
            case 'Option':
              labelCol = {
                Header: 'Option',
                accessor: 'context',
                excel: { width: 65 },
                Cell: TableCellRenderer.EntityName,
              }
              break
            default: throw new Error(`unrecognized subject type: "${subjectType}"`)
          }
          break
        case 'subject':
          if(subjectType === 'Criterion') {
            // there's currently only one reasonable choice for pivot by subject (option
            // rating); throw if not option rating
            throw new Error(`not reasonable to pivot by subject with criteria rating`)
          }
          labelCol = {
            Header: 'Criterion',
            accessor: 'context',
            excel: { width: 65 },
            Cell: TableCellRenderer.EntityName,
          }
          break
        default: throw new Error(`unrecognized pivot type: "${pivotType}"`)
      }
      const ratingsAndPriorities: Column<RatingsTableData>[] = showPriorities
        ? [
          {
            Header: 'Ratings',
            columns: [
              {
                Header: participantName ?? '(ERROR)',
                accessor: 'participantRating',
                Cell: TableCellRenderer.Rating,
                align: 'right',
                sortType: 'rating',
                excel: {
                  width: 20,
                  style: { numFmt: '0.0' },
                },
              },
              {
                Header: `Group Avg.`,
                accessor: 'groupRating',
                Cell: RatingRenderer,
                align: 'right',
                sortType: 'numeric',
                excel: {
                  width: 20,
                  style: { numFmt: '0.0' },
                },
              },
            ],
          },
          {
            Header: 'Local Priorities',
            columns: [
              {
                Header: participantName ?? '(ERROR)',
                accessor: 'participantPri',
                Cell: PriorityRenderer,
                align: 'right',
                sortType: 'numeric',
                excel: {
                  width: 20,
                  style: { numFmt: '0.000' },
                },
              },
              {
                Header: `Group Avg.`,
                accessor: 'groupPri',
                Cell: PriorityRenderer,
                align: 'right',
                sortType: 'numeric',
                excel: {
                  width: 20,
                  style: { numFmt: '0.000' },
                },
              },
            ],
          },
        ]
        : [
          {
            Header: `${participantName ?? '(ERROR)'}'s Rating`,
            accessor: 'participantRating',
            Cell: TableCellRenderer.Rating,
            align: 'right',
            sortType: 'rating',
            excel: {
              width: 20,
              style: { numFmt: '0.0' },
            },
          },
          {
            Header: `Group Avg. Rating`,
            accessor: 'groupRating',
            Cell: RatingRenderer,
            align: 'right',
            sortType: 'numeric',
            excel: {
              width: 20,
              style: { numFmt: '0.0' },
            },
          },
        ]
      const participantNotesCol: Column<RatingsTableData> = {
        Header: `${participantName ?? '(ERROR)'}'s Notes`,
        accessor: 'participantNotes',
        excel: { width: 40 },
      }
      switch(pivotType) {
        case 'context':
          // if we're pivoting by context, we don't display the notes col because it will
          // be the same for all rows!
          return [labelCol, ...ratingsAndPriorities]
        case 'subject':
          return [labelCol, participantNotesCol, ...ratingsAndPriorities]
        default: throw new Error(`unrecognized pivot type: "${pivotType}"`)
      }
    },
    [participantName, pivotType, subjectType, showPriorities]
  )

  const criteriaPrioritizationRatings = useParticipationSessionRatings(decisionId, 'CriteriaPrioritization')
  const sessionRatings = useParticipationSessionRatings(decisionId, participationSessionType)
  const allRatingNotes = useRatingNotes(decisionId)
  const rootPerfCriterionId = useRootPerfCriterionId(decisionId)
  const criteria = useCriteria(decisionId)

  const data = useMemo<RatingsTableData[]>(() => {
    const criteriaTree = createTree(criteria)
    const rootNode = rootPerfCriterionId ? criteriaTree.byId[rootPerfCriterionId] : undefined
    const leafCriteria = rootNode && getLeafNodes(rootNode)
    const criteriaLabels = rootNode && getNodeLabels(rootNode)

    if(!leafCriteria || !criteriaLabels) return []

    let pivotAccessor = undefined
    switch(pivotType) {
      case 'context': pivotAccessor = 'contextId'; break
      case 'subject': pivotAccessor = 'subjectId'; break
      default: throw new Error(`unrecognized pivot type: "${pivotType}"`)
    }

    // get all group ratings for this participation session, context type, subject type, and subject
    const groupRatings = sessionRatings.filter(_matches({
      subjectType,
      [pivotAccessor]: pivotId,
    }))

    const criteriaIndex = prioritization.indexCriteria(criteria)

    const criteriaPrioritizationIndex = prioritization.indexCriteriaPrioritization(
      prioritization.createCriteriaPrioritization(
        criteriaIndex,
        filterInvalidRatings(criteriaPrioritizationRatings),
        // note that the default is 'RecenterAndNormalize', and all newly created decisions
        // will have this property set; only old decisions with default to 'Normalize'
        decision.ratingsToPrioritizationAlgorithm || 'Normalize'
      )
    )

    // pull out the participant ratings
    const participantRatings = groupRatings.filter(r => r.participantId === participantId)

    // we'll want to look up criteria by either context (in the case of option rating)
    // or subject (in the case of criteria prioritization)
    let criLookupAccessor = undefined
    switch(subjectType) {
      case 'Criterion': criLookupAccessor = 'subjectId'; break
      case 'Option': criLookupAccessor = 'contextId'; break
      default: throw new Error(`unrecognized subject type: "${subjectType}"`)
    }
    const partRatingsByCri = _keyBy(participantRatings, criLookupAccessor)
    // calculate the average group ratings
    const groupRatingVectorsByCri = _mapValues(
      _groupBy(groupRatings, criLookupAccessor),
      ratings => {
        const groupRatingVectors = ratings
          .filter((rating): rating is Rating & { ratingVector: NonNullable<RatingVector> } => (
            rating.ratingVector !== null && !rating.abstain
          ))
          .map(rating => rating.ratingVector)

        return vectorMean(groupRatingVectors)
      }
    )

    // get participant notes by context criterion
    const ratingNotes = allRatingNotes.filter(_matches({
      participantId,
      subjectType,
    }))
    const partNotesByCri = _keyBy(ratingNotes, 'contextId')

    switch(pivotType) {
      case 'context':
        switch(subjectType) {
          case 'Option':
            // TODO
            throw new Error('not yet implemented: pivot option ratings by context criterion')
          case 'Criterion': {
            const contextChildren = criteriaTree.byId[pivotId].children
            return contextChildren.map<RatingsTableData>(c => ({
              context: criteriaLabels[c.data.id],
              color: c.data.color,
              participantNotes: htmlToFormattedText(partNotesByCri[c.data.id]?.notes?.value || ''),
              participantRating: partRatingsByCri[c.data.id],
              groupRating: groupRatingVectorsByCri[c.data.id]?.[0],
              participantPri: criteriaPrioritizationIndex
                .byParticipantId[participantId]
                ?.localPriorityByCriteriaId?.[c.data.id],
              groupPri: criteriaPrioritizationIndex.globalPriorityByCriteriaId[c.data.id],
              rating: partRatingsByCri[c.data.id],  // this is needed for deletion by selected rows
            }))
          }
          default: throw new Error(`unrecognized subject type: "${subjectType}"`)
        }
      case 'subject': {
        // here we opt to display all leaf criteria, even if they don't have associated ratings -- this
        // allows the user to more easily spot missing data
        const data = leafCriteria.map<RatingsTableData>(lc => ({
          context: criteriaLabels[lc.data.id],
          color: lc.data.color,
          participantRating: partRatingsByCri[lc.data.id],
          participantNotes: htmlToFormattedText(partNotesByCri[lc.data.id]?.notes?.value || ''),
          groupRating: groupRatingVectorsByCri[lc.data.id]?.[0],
          rating: partRatingsByCri[lc.data.id],  // this is needed for deletion by selected rows
        }))
        return data
      }
      default: throw new Error(`unrecognized pivot type: "${pivotType}"`)
    }
  }, [
    decision.ratingsToPrioritizationAlgorithm,
    criteriaPrioritizationRatings,
    sessionRatings,
    allRatingNotes,
    participantId,
    pivotType,
    pivotId,
    subjectType,
    criteria,
    rootPerfCriterionId,
  ])

  return { decision, participantName, columns, data }
}

type RatingsTableProps = {
  decisionId: string
  participationSessionType: ParticipationSessionType,
  pivotType: 'subject' | 'context'
  pivotId: string
  subjectType: 'Option' | 'Criterion'
  participantId: string
}
/**
 * Display ratings for a single participant in a participation session.  There are
 * currently three possible configurations:
 *
 *  - Criteria ratings: pivot by context (parent criterion); rows are subjects (child criteria)
 *  - Option ratings: pivot by subject (option); rows are the context (criteria)
 *  - Option ratings: pivot by context (criterion); rows are the subject (option)
 *
 * Eventually, the ability to pivot by participant may also be added.
 */
export function RatingsTable(props: RatingsTableProps): JSX.Element {

  const {
    decisionId,
    participationSessionType,
    pivotType,
    pivotId,
    subjectType,
    participantId,
  } = props

  const { participantName, columns, data } = useRatingsTable({
    decisionId,
    participationSessionType,
    pivotType,
    pivotId,
    subjectType,
    showPriorities: subjectType === 'Criterion',
    participantId,
  })

  const dispatch = useDispatch()

  const onDeleteSelectedRatings = useCallback(
    (instance: TableInstance<RatingsTableData>) => {
      const ratingsToDelete = instance.selectedFlatRows
        .map(r => r.original.rating)
        .filter(Boolean)
        .map(r => _omit(r, 'ratingVector'))
      Modal.confirm({
        title: `Delete ${ratingsToDelete.length} Rating(s)`,
        content: <p>This will delete the selected {ratingsToDelete.length} rating(s). Note that
          rating notes may not be deleted, as they may be associated with other options.  Are you sure
          you want to proceed?</p>,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          ratingsToDelete.forEach(r => {
            const deleteAction = decisionActions.deleteRating(decisionId, r)
            dispatch(deleteAction)
          })
        },
      })
    },
    [dispatch, decisionId]
  )

  const onDeleteParticipant = useCallback(() => {
    const name = participantName || participantId
    Modal.confirm({
      title: `Delete All Data for ${name}`,
      content: <p>This will delete all ratings and notes for {name}.  Are you sure you want to proceed?</p>,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const deleteAction = decisionActions.deleteParticipant(decisionId, participantId)
        dispatch(deleteAction)
      },
    })
  }, [decisionId, participantId, participantName, dispatch])

  return (
    <>
      <Table<RatingsTableData>
        columns={columns}
        data={data}
        onDeleteSelected={onDeleteSelectedRatings}
        rowSelectPosition="last"
        disablePagination
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px' }}>
        <Button
          onClick={onDeleteParticipant}
          danger
          icon={<DeleteOutlined />}
        >
          Delete All Data for {participantName || participantId}
        </Button>
      </div>
    </>
  )
}
