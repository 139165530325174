/** @jsxImportSource @emotion/react */
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu } from 'antd'

import { Color } from '@vms/vmspro3-core/dist/systemConsts'

import { signOut } from '../../../services/authService'
import { UserPreferencesModal } from './UserPreferencesModal'
import { testId } from '../../../test-automation'
import { useAppSelector } from '../../../redux'
import { useUserAccounts } from '../../../context'
import { selectCurrentUserName } from '../../../redux/selectors'
import { useModalState } from '../../../hooks/useModalState'

function useDropdownVisible() {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const setDropdownHidden = useCallback(() => setDropdownVisible(false), [])

  return {
    dropdownVisible,
    setDropdownHidden,
    handleVisibleChange: setDropdownVisible,
  }
}

interface DropdownOverlayProps {
  accountCommonId?: string,
  setDropdownHidden: () => void,
}
function DropdownOverlay({
  accountCommonId,
  setDropdownHidden,
}: DropdownOverlayProps) {

  const { userAccounts } = useUserAccounts()

  const navigate = useNavigate()

  const navigateToUserAccounts = () => navigate('/')

  const { modal, hideModal, showModal } = useModalState()

  return (
    <>
      {modal}
      <Menu
        theme="dark"
        mode="inline"
        style={style.menu}
        selectedKeys={accountCommonId ? [accountCommonId] : undefined}
        onClick={setDropdownHidden}
      >
        <Menu.SubMenu key="select-account" title="Select Account">
          {userAccounts
            .filter(({ userRecord }) => !userRecord.disabled)
            .map(({ account }) => (
              <Menu.Item
                key={account.commonId}
                onClick={account.commonId !== accountCommonId ? () => navigate(`/${account.commonId}`) : undefined}
              >
                {account.name}
              </Menu.Item>
            ))
          }
          <Menu.Divider />
          <Menu.Item key="account-management" onClick={navigateToUserAccounts}>Account Management</Menu.Item>
        </Menu.SubMenu>
        <Menu.Item
          key="preferences"
          onClick={() => showModal(<UserPreferencesModal hideModal={hideModal} />)}
        >
          Preferences
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout" onClick={signOut}>Logout</Menu.Item>
      </Menu>
    </>
  )
}

export function UserMenu({ accountCommonId }: { accountCommonId?: string }) {
  const userName = useAppSelector(selectCurrentUserName)

  const {
    dropdownVisible,
    handleVisibleChange,
    setDropdownHidden,
  } = useDropdownVisible()

  return (
    <Dropdown
      overlay={
        <DropdownOverlay
          setDropdownHidden={setDropdownHidden}
          accountCommonId={accountCommonId}
        />
      }
      trigger={['click']}
      visible={dropdownVisible}
      onVisibleChange={handleVisibleChange}
    >
      <div css={style.icon}>
        <Avatar size='small' icon={<UserOutlined />} style={style.avatar} />
        <span {...testId('headerUsername')} style={style.avatarName}>{userName}</span>
      </div>
    </Dropdown>
  )
}

const style = {
  avatar: {
    background: Color.BLUE_LIGHT,
    color: 'rgba(0, 0, 0, 0.65)',
    fontSize: '16px',
  },
  avatarName: {
    fontSize: '14px',
  },
  icon: {
    height: '100%',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'color 0.3s',
    ':hover': {
      color: Color.BLUE_LINK,
    },
  },
  menu: {
    width: '228px',
  },
}
