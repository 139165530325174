import { ReactNode, useMemo } from 'react'
import { ApolloQueryResult, gql, useQuery } from '@apollo/client'

import { Account, UserAccountRecord_App } from '@vms/vmspro3-core/dist/types'

import { QueryResult } from '../components/common/QueryResult'

import { AccountFieldsFragment, AccountUserFieldsFragment } from '../graphql'
import { createTypedContext } from '../utils/createTypedContext'

interface UserAccountsContextValue {
  userAccounts: Array<{
    account: Account,
    userRecord: UserAccountRecord_App,
  }>,
  refetchUserAccounts: () => Promise<ApolloQueryResult<GetUserAccountsData>>
}
const [
  useUserAccounts,
  UserAccountsContextProvider,
] = createTypedContext<UserAccountsContextValue>('UserAccountsContext')

const GET_USER_ACCOUNTS = gql`
  query GetUserAccounts($userId: ID!) {
    userAccounts(userId: $userId) {
      account { ...AccountFields }
      userRecord { ...AccountUserFields }
    }
  }
  ${AccountFieldsFragment}
  ${AccountUserFieldsFragment}
`

interface GetUserAccountsData {
  userAccounts: Array<{
    account: Account,
    userRecord: Omit<UserAccountRecord_App, 'eulas'> & { eulasJSON: string }
  }>
}
interface GetUserAccountsVariables {
  userId: string,
}

interface UserAccountsProviderProps {
  authUserId: string,
  children: ReactNode,
}
export function UserAccountsProvider({
  authUserId,
  children,
}: UserAccountsProviderProps) {
  const {
    data,
    loading,
    error,
    refetch: refetchUserAccounts,
  } = useQuery<GetUserAccountsData, GetUserAccountsVariables>(
    GET_USER_ACCOUNTS,
    {
      variables: {
        userId: authUserId,
      },
      context: {
        headers: {
          'vms-accountid': '*',
          'vms-userid': authUserId,
        },
      },
    }
  )

  const userAccounts = useMemo(
    () => data?.userAccounts.map(userAccount => {
      const { eulasJSON, ...restUserRecord } = userAccount.userRecord

      return {
        ...userAccount,
        userRecord: {
          ...restUserRecord,
          eulas: JSON.parse(eulasJSON) as UserAccountRecord_App['eulas'],
        },
      }
    }),
    [data]
  )

  return (
    <QueryResult data={userAccounts} loading={loading} error={error}>
      {userAccounts => (
        <UserAccountsContextProvider value={{ userAccounts, refetchUserAccounts }}>
          {children}
        </UserAccountsContextProvider>
      )}
    </QueryResult>
  )
}

export { useUserAccounts }
