import React from 'react'
/** @jsxImportSource @emotion/react */
import Magnitude from './Magnitude'
import Title from './Title'
import Traits from './Traits'
import Close from './Close'

export default function FlexChartTooltip({
  accountCommonId,
  articleConfig,
  current,
  displayRange,
  pinned,
  pinTooltip,
  previous,
  statSelections,
  windowDimensions,
  TooltipContent,
  entityId,
}) {

  const article = pinned || current || previous || undefined

  const tooltipWidth = 500
  const tooltipHeight = 400

  const articleElement = article
    ? document.getElementById(article.id)
    : undefined
  const chunkPosition = articleElement
    ? articleElement.getBoundingClientRect()
    : undefined
  const [x, y] = chunkPosition
    ? [
      chunkPosition.left + 32,
      chunkPosition.top + 32 + window.scrollY,
    ]
    : [0, 0]
  const safetyLeft = x < tooltipWidth + 32
    ? tooltipWidth + 32 - x
    : 0
  const safetyTop = y < tooltipHeight + 32
    ? tooltipHeight + 32 - y
    : 0

  const tooltipPositioning = {
    bottom: y === null ? 0 : windowDimensions.height - y + 3 - safetyTop,
    right: x === null ? 0 : windowDimensions.width - x - safetyLeft,
    height: tooltipHeight,
    width: tooltipWidth,
  }

  const tooltipTransition = {
    opacity: current || pinned ? 1 : 0,
    position: 'absolute',
    transitionProperty: 'bottom right',
    transitionDuration: '.25s',
    transitionTimingFunction: 'ease-out',
  }

  return (
    <div>
      <div
        id="tooltip"
        css={{
          ...tooltipPositioning,
          ...tooltipTransition,
          background: '#ffffffee',
          display: 'grid',
          gridTemplate: `
            [row1-start] "magnitude   title       close       " 76px [row1-end]
            [row2-start] "magnitude   traits      traits      " 24px [row1-end]
            [row3-start] "left        right       right       " auto  [row2-end]
            /             120px       auto        45px
          `,
          padding: '10',
          pointerEvents: pinned ? 'auto' : 'none',
          zIndex: '2000',
        }}
      >
      {(current || pinned) &&
      <>
        <Magnitude
          article={article}
          displayRange={displayRange}
          statSelections={statSelections}
        />
        <Title
          accountCommonId={accountCommonId}
          article={article}
          entityId={entityId}
        />
        <Traits
          article={article}
        />
        <Close
          pinTooltip={pinTooltip}
        />
        <TooltipContent
          article={article}
          articleConfig={articleConfig}
          statSelections={statSelections}
        />
      </>
      }
      </div>
      <div
        id="tooltip-outline"
        css={{
          bottom: y === null ? 0 : tooltipPositioning.bottom + 2 + 'px',
          right: x === null ? 0 : tooltipPositioning.right + 2 + 'px',
          height: tooltipPositioning.height - 4 + 'px',
          width: tooltipPositioning.width - 4 + 'px',
          ...tooltipTransition,
          boxShadow: '0 0 0 4px #000000cc',
          pointerEvents: 'none',
          zIndex: 998,
        }}
      />
      <div
        id="tooltip-bg"
        onClick={() => pinTooltip(undefined)}
        css={{
          background: '#00000022',
          blendMode: 'multiply',
          height: `${document.body.scrollHeight}px`,
          left: '0px',
          opacity: pinned ? '100' : '0',
          pointerEvents: pinned ? 'auto' : 'none',
          position: 'absolute',
          top: '0px',
          width: `${document.body.clientWidth}px`,
          zIndex: '999',
        }}
      />
    </div>
  )
}
