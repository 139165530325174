/**
 * This Risk Tornado component is for the VMSPro Risk functionality. Risk has been unravelled from Studies,
 * and the Qualitative and Quantitative Risk views no longer rely on a study to be present. Data for the chart
 * is directly tied to the riskContext and it's linked risks.
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import _isEmpty from 'lodash/isEmpty'
import _cloneDeep from 'lodash/cloneDeep'
import numeral from 'numeral'
import { Col, Empty, Row, Spin } from 'antd'

import systemConsts from '@vms/vmspro3-core/dist/systemConsts'
import { getSeverityColor } from '@vms/vmspro3-core/dist/utils/risk'
import { CostUnitMetadata, DurationUnitMetadata } from '@vms/vmspro3-core/dist/utils/qty'

import TornadoChartDisplay from './TornadoChartDisplay'

import { numcomp } from '../../../utils/sort-utils'

const { RiskAnalysisStatus, RiskImpactType } = systemConsts

const impactMultiplier = {
  [RiskImpactType.THREAT]: 1,
  [RiskImpactType.NONE]: 0,
  [RiskImpactType.OPPORTUNITY]: -1,
}

const MANAGED = 'Managed'
const UNMANAGED = 'Unmanaged'
const PERF = 'perf'
const COST = 'cost'
const TIME = 'time'
const QUAL = 'QUAL'
const QUANT = 'QUANT'

// In the context of a risk, we think of 'Managed' as an overlay of 'Unmanaged'.
// For the sake of building the tornado chart we are using 'Unmanaged' as a valid overlay value as well.
const overlayKeys = [MANAGED, UNMANAGED]
const impactCategoryKeys = [COST, PERF, TIME]

const minDomain = 1

/**
 * Build a tornado chart to display the risks based on their Qualitative or Quantitative impacts.
 *
 * @param {Object} props
 *    @prop {string} [props.baseRoute=''] - the base route to the risk editor
 *    @prop {string} [props.chart='cost'] - the chart id to display - 'cost', 'time', or 'perf'
 *    @prop {string} [props.dataType='QUAL'] - the type of data. 'QUAL' or 'QUANT'
 *    @prop {number} [props.numRows=10] - number of rows to display in the tornado chart
 *    @prop {string} props.effectiveRiskContext
 *    @prop {object[]} props.risks - risks to plot
 *    @prop {string} [props.truncate=false] - truncate the risk names
 */
const RiskTornado = ({
  baseRoute,
  chart,
  dataType,
  numRows,
  risks,
  effectiveRiskContext,
  truncate,
}) => {
  const [tornadoData, setTornadoData] = useState([])
  const { defaultDurationUnit, defaultTimeUnit } = effectiveRiskContext

  const projectDurationUnit = effectiveRiskContext.attributes.time.value.unit || defaultTimeUnit
  const projectCostUnit = effectiveRiskContext.attributes.cost.value.unit || defaultDurationUnit

  let displayHeader = ''
  let impactLabel = ''
  switch(chart) {
    case COST:
      displayHeader = 'Cost'
      if(dataType === QUANT) impactLabel = CostUnitMetadata[projectCostUnit].symbol
      break
    case TIME:
      displayHeader = `Schedule`
      if(dataType === QUANT) {
        impactLabel = DurationUnitMetadata[projectDurationUnit].label
        displayHeader += ` (${impactLabel})`
      }
      break
    case PERF:
      displayHeader = 'Performance'
      break
    default: break
  }

  // Calculate the tornadoData from the given risks relative to the given dataType and chart
  // This is wrapped in a useEffect so we don't trigger the function on every render.
  useEffect(() => {
    const data = risks.filter(({ status }) => status === RiskAnalysisStatus.ACTIVE)
      .reduce((data, risk) => {
        const _risk = _cloneDeep(risk)
        const { id, impact, name, managed, severity } = _risk
        const unmanagedImpact = impact || {}
        const managedImpact = managed?.impact || {}

        impactCategoryKeys.forEach(impactKey => {
          if(!data[impactKey]) data[impactKey] = []
          data[impactKey].push({
            name,
            id,
            ...overlayKeys.reduce((overlay, key) => {
              const sev = key === MANAGED ? managed?.severity?.[impactKey] : severity?.[impactKey]
              const impacts = key === MANAGED ? managedImpact : unmanagedImpact
              const { quant, type } = impacts[impactKey] || {}
              overlay[`${key}Color`] = getSeverityColor(sev)
              if(dataType === QUAL) {
                // Qualitative Risk values - Qualitative values are the severity
                overlay[key] = sev
              } else if(quant) {
                // Quantitative Risk valuess
                const ev = quant.ev && typeof quant.ev === 'object' ? quant.ev.value : quant.ev
                if(Number.isFinite(ev)) {
                  overlay[key] = ev * impactMultiplier[type] * (impactKey === PERF ? 100 : 1)
                }
              }
              return overlay
            }, {}),
          })
        })
        return data
      }, {})
    if(!_isEmpty(data)) setTornadoData(data[chart])
  },
  [chart, dataType, risks])

  const formatFn = useCallback(val => {
    const format = '0,0.[00]'
    let prefix = ''
    let suffix = ''
    if(dataType === QUANT) {
      if(chart === COST) prefix = CostUnitMetadata[projectCostUnit].symbol
      else if(chart === TIME) suffix = DurationUnitMetadata[projectDurationUnit].abbrev
      else suffix = '%'
    }
    return numeral(val).format(prefix + format) + suffix
  }, [chart, dataType, projectCostUnit, projectDurationUnit])

  // Calculate the domain boundaries for the plotly chart.
  // We want the maximum absolute value for the tornadoData as both our negative and positive domain
  // barrier so the graph is centered.
  const domain = useMemo(() => {
    const absMax = tornadoData.map(d => Math.max(...overlayKeys.map(o => Math.abs(d[o]))))
      .filter(Number.isFinite)
    // Pad the domain by 15% to make room for the labels
    const dom = Math.ceil(Math.max(...absMax, minDomain))
    return [-1.15 * dom, 1.15 * dom]
  }, [tornadoData])

  // Build the plot data for the tornadoChartDisplay. This is the plotly readable data calculation.
  // Memoized so we only calculate when relevent data is changed.
  const plotData = useMemo(() => {
    // Sort the risks in descending order by their Unmanaged values. Risks that have not been evaluated will
    // have either 0 or NaN values and be filtered after risks which have been evaluated.
    const sortedData = [...tornadoData].sort((a, b) => numcomp(
      Math.max(...overlayKeys.map(o => Math.abs(b[o]))),
      Math.max(...overlayKeys.map(o => Math.abs(a[o])))
    ))

    // Plotly utilizes d3-formats to format data on hovertexts.
    // We can append the format template to our hovertemplate.
    const template = () => {
      switch(chart) {
        case COST: return `%{x:${impactLabel},.2f}`
        case TIME: return `%{x:.2f} ${impactLabel}`
        case PERF: return '%{x:.2f}%'
        default: return '%{x:.2f}'
      }
    }

    return overlayKeys.map(overlay => {
      // Since plotly.js builds the data for the tornado from the bottom up, we need to
      // reverse the order of our selected rows to show the highest affected risks at the top
      const dataProperties = (numRows === -1 ? sortedData : sortedData.slice(0, numRows))
        .reverse()
        .reduce((acc, obj) => {
          acc.names.push(obj.name)
          acc.values.push(obj[overlay])
          acc.texts.push(formatFn ? formatFn(obj[overlay]) : obj[overlay].toFixed(1))
          acc.hovertexts.push(overlay + ':' + formatFn ? formatFn(obj[overlay]) : obj[overlay].toFixed(1))
          acc.colors.push(obj[overlay + 'Color'])
          acc.routes.push(`${baseRoute}/${obj.id}`)
          return acc
        }, { names: [], values: [], texts: [], hovertexts: [], colors: [], routes: [] })
      return {
        name: overlay,
        y: dataProperties.names,
        x: dataProperties.values,
        hovertemplate: `<b>%{hovertext}</b><br><b>${overlay}</b><br>${template()}<extra></extra>`,
        text: dataProperties.texts,
        hovertext: dataProperties.names,
        textposition: 'outside',
        orientation: 'h',
        type: 'bar',
        marker: { color: dataProperties.colors },
        routes: dataProperties.routes,
      }
    })
  }, [chart, formatFn, impactLabel, numRows, baseRoute, tornadoData])

  if(!(risks && risks.length)) {
    return (
      <Row gutter={24} style={style.emptyContentArea}>
        <Col span={12}>
          <Empty description="Add a Risk to get started." />
        </Col>
      </Row>
    )
  }

  if(!risks) return <Spin />

  return (
    <Row style={style.contentArea}>
      <h3>{displayHeader}</h3>
      <TornadoChartDisplay data={plotData} domain={domain} truncate={truncate} />
    </Row>
  )
}
RiskTornado.defaultProps = {
  baseRoute: '',
  chart: COST,
  dataType: QUAL,
  numRows: 10,
  truncate: false,
}

const style = {
  emptyContentArea: {
    margin: '30px',
    backgroundColor: '#fff',
    padding: '24px',
  },
  contentArea: {
    backgroundColor: '#fff',
    padding: '24px',
  },
}

export default RiskTornado
