import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import systemConsts from '@vms/vmspro3-core/dist/systemConsts'
import { actions } from '@vms/vmspro3-core'

import { MainLandingPageView, CardConfig } from './MainLandingPageView'

import { useAccount } from '../../../context'

export function AccountHomePage() {
  const { account, accountCommonId } = useAccount()
  const accountDecisionsQuota = account.quotas.decisions

  const navigate = useNavigate()
  const cards = useMemo<CardConfig[]>(
    () => [
      {
        key: 'welcome',
        title: <span>Welcome to OptionLab&reg;</span>,
        description: (
          <div>
            <p>This is the OptionLab landing page, where you'll be able to access
              any OptionLab module you have access to.</p>
            <p>Available modules:</p>
            <ul>
              <li>Risk Management</li>
              <li>Decision</li>
            </ul>
          </div>
        ),
        actions: [
          actions.feature.useModule({}, { moduleKey: systemConsts.Module.PORTFOLIO_RISK }),
        ],
      },
      {
        key: 'risk-management',
        title: 'Risk Management',
        description: (
          <div>
            <p>Comprehensive Risk Management:</p>
            <ul>
              <li>Calibration</li>
              <li>Identification</li>
              <li>Analysis (with Palisade @RISK support)</li>
              <li>Response Planning</li>
              <li>Monitoring & Control</li>
            </ul>
          </div>
        ),
        button: {
          onClick: () => navigate(`/${accountCommonId}/port`),
          label: 'Go to Risk Management',
        },
        actions: [
          actions.feature.useModule({}, { moduleKey: systemConsts.Module.PORTFOLIO_RISK }),
        ],
      },
      {
        key: 'decision',
        title: 'Decision Support',
        description: (
          <div>
            <p>Decisions are at the heart of every organization. OptionLab Decision Support
              helps you make better decisions by:</p>
            <ul>
              <li>Fostering collaboration</li>
              <li>Soliciting multidisciplinary stakeholder input</li>
              <li>Mitigating cognitive bias</li>
              <li>Framing decisions</li>
            </ul>
          </div>
        ),
        button: {
          onClick: () => navigate(`/${accountCommonId}/decision`),
          label: 'Go to Decision Support',
        },
        actions: [
          actions.feature.useModule({}, { moduleKey: systemConsts.Module.PORTFOLIO_DECISION }),
        ],
        quota: 'decision',
      },
    ].filter(card => {
      if(!card.quota) return true
      switch(card.quota) {
        case 'decision': return accountDecisionsQuota ? accountDecisionsQuota.allowed > 0 : false
        default: throw new Error('unrecognized quota: ' + card.quota)
      }
    }),
    [navigate, accountCommonId, accountDecisionsQuota]
  )

  return (
    <MainLandingPageView cardsConfig={cards} />
  )
}

